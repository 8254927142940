<template>
    <section
        :id="slice.primary.anchorName || undefined"
        ref="section"
        class="slice slice-proofpoints"
        :class="{'slice-proofpoints--two-cols': slice.primary.nbColumn === '2'}"
        aria-label="proofpoints"
    >
        <div class="slice-content">
            <div class="proofpoints-header">
                <PrismicRichText v-if="slice.primary.title" :field="slice.primary.title" class="slice-title" />
                <PrismicRichText v-if="slice.primary.description" :field="slice.primary.description" class="slice-description" />
                <div v-if="ctaUrl || ctaLink.url" class="proofpoints-cta">
                    <VJoyButton :href="ctaUrl || ctaLink.url" data-source-component="proof-points">
                        {{ ctaLabel }}
                    </VJoyButton>
                </div>
                <PrismicImage v-if="slice.primary.image" :image="slice.primary.image" class="proofpoints-image" />
            </div>
            <div class="proofpoints-content">
                <div v-for="(item, i) in slice.items" :key="i" :item="item" class="proofpoint">
                    <div class="proofpoint-image">
                        <PrismicImage :image="item.image" />
                    </div>
                    <div>
                        <div class="proofpoint-title">{{ item.title }}</div>
                        <PrismicRichText :field="item.text" class="proofpoint-text" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {ref} from 'vue';
    import {VJoyButton} from '@maltjoy/core-vue';
    import type {ProofPointsSlice} from '@prismicio-types';
    import {getSliceComponentProps, PrismicRichText} from '@prismicio/vue';
    import type {FilledLinkToWebField} from '@prismicio/types';
    import PrismicImage from '~/components/PrismicImage.vue';
    import {useComponentTracking} from '#imports';

    const props = defineProps(getSliceComponentProps<ProofPointsSlice>(['slice', 'index', 'slices', 'context']));

    const section = ref(null);
    const ctaLink = ref(props.slice.primary.ctaLink as FilledLinkToWebField);
    const ctaUrl = ref(props.slice.primary.ctaUrl);
    const ctaLabel = ref(props.slice.primary.ctaLabel);

    useComponentTracking(section);
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .slice-proofpoints {
        .proofpoints {
            &-cta {
                margin-top: var(--joy-core-spacing-6);
            }
            &-header {
                margin: 0 auto var(--joy-core-spacing-6);
                img {
                    margin-top: var(--joy-core-spacing-4);
                }
            }
        }

        @include mq.screen_md(min) {
            .slice-content {
                text-align: left;
                display: flex;
                align-items: flex-start;
                gap: var(--joy-core-spacing-8);

                .proofpoints-header {
                    flex: none;
                    width: calc(50% - 80px);
                }
            }
        }

        .proofpoint {
            display: flex;
            text-align: left;
            gap: var(--joy-core-spacing-5);
            margin-bottom: var(--joy-core-spacing-6);

            &-image {
                flex: none;
                width: 40px;
                > img {
                    width: 100%;
                }
            }
            &-title {
                font-family: var(--joy-font-family-title);
                font-size: var(--joy-font-size-primary-600);
                font-weight: var(--joy-font-weight-bold);
                margin-bottom: var(--joy-core-spacing-2);
                @include mq.screen_md(min) {
                    font-size: var(--joy-font-size-primary-800);
                }
            }
            &-text {
                li {
                    list-style-type: none;
                    padding-left: var(--joy-core-spacing-6);
                    margin-bottom: var(--joy-core-spacing-3);
                    background: url(~/assets/svg/check.svg) no-repeat;
                    background-position: left 3px;
                }
            }
        }

        &--two-cols {
            .proofpoints-content {
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;
                gap: 0 var(--joy-core-spacing-8);
            }
            .proofpoint {
                @include mq.screen_md(min) {
                    flex: none;
                    width: calc(50% - var(--joy-core-spacing-4));
                }
            }
        }
    }
</style>
