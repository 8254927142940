<template>
    <section v-if="!(breakpoints && breakpoints.isMobile && slice.primary.hideInMobile)" class="slice slice-video">
        <div class="slice-content">
            <PrismicRichText :field="slice.primary.title" class="slice-title" />
            <PrismicRichText :field="slice.primary.subtitle" class="slice-description" />

            <ServerSideNavigationPrismicLink
                v-if="isLink(slice.primary.ctaLinkText, slice.primary.ctaLink)"
                :field="slice.primary.ctaLink"
                class="link slice-video__link"
                data-source-component="video"
            >
                {{ slice.primary.ctaLinkText }}
            </ServerSideNavigationPrismicLink>
            <div class="slice-video__grid" :class="{'slice-video__grid--multiple': slice.items.length > 1}">
                <MktVideoPlayer v-for="(video, i) in videoPlayers" v-bind="video" :key="i" />
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {onMounted, inject, ref} from 'vue';
    import {getSliceComponentProps, PrismicEmbed, PrismicRichText, PrismicImage, type PrismicText} from '@prismicio/vue';
    import ServerSideNavigationPrismicLink from '~/components/ServerSideNavigationPrismicLink.vue';
    import type {VideoSliceSlice} from '@prismicio-types';
    import {MEDIA_BREAKPOINTS} from '~/app/keys';
    import useErrorHelpers from '~/composables/useErrorHelpers';
    import {useComponentTracking, usePrismicFieldHelpers, usePrismicTypeGuards} from '#imports';
    import {MktVideoPlayer} from '#components';

    const {hasProperty} = usePrismicTypeGuards();
    const breakpoints = inject(MEDIA_BREAKPOINTS);
    const section = ref(null);
    const props = defineProps(getSliceComponentProps<VideoSliceSlice>());

    const videoPlayers = props.slice.items.map((item) => {
        const {html} = item.video;
        const src = html?.match(/src="([^"]*)"/)?.[1] || '';
        const title = item.title.map((node) => (hasProperty(node, 'text') ? node.text : '')).join('');
        const cover = item.cover?.url || '';
        const size = item.size;
        return {src, title, cover, size};
    });

    onMounted(() => {
        const {getPreviewErrorHandler, throwIfItemsCountIsOutOfBound, throwIfMissingItemsProperties, throwIfPrimaryPropertiesAreInvalid} =
            useErrorHelpers();
        const errorHandler = getPreviewErrorHandler();
        if (errorHandler.enabled) {
            const itemsCount = props.slice.items.length;
            throwIfItemsCountIsOutOfBound(errorHandler.toast, itemsCount, 'videos', 1, 3);
            throwIfMissingItemsProperties(errorHandler.toast, props.slice.items, [{name: 'video', type: 'video'}], 'videos');
            throwIfPrimaryPropertiesAreInvalid(errorHandler.toast, props.slice.primary, [{name: 'title', type: 'field'}], 'video slice');
        }
    });

    const {isLink} = usePrismicFieldHelpers();

    useComponentTracking(section);
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .slice-video {
        &__link {
            display: inline-block;
            margin-top: var(--joy-core-spacing-8);
            padding-bottom: 6px;
            border-bottom: 2px solid var(--joy-color-secondary-30);
            transition: padding var(--joy-transition-duration-default) var(--joy-transition-timing-function);

            &:hover {
                text-decoration: none;
                padding: 0 6px 6px;
            }
        }
        &__grid {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: var(--joy-core-spacing-8);
            margin: var(--joy-core-spacing-8) auto 0;

            @include mq.screen_md(min) {
                max-width: 1140px;
                flex-direction: row;

                &--multiple {
                    flex-wrap: wrap;

                    > div:nth-child(1),
                    > div:nth-child(2) {
                        width: calc(50% - var(--joy-core-spacing-4));
                    }
                }
            }
        }
    }
</style>
