<template>
    <section v-if="!(breakpoints?.isMobile && slice.primary.hideInMobile)" class="persona-slice" :aria-label="asText(slice.primary.title)">
        <PrismicRichText :field="slice.primary.title" class="title" />
        <div v-if="breakpoints?.isMobile" class="personae">
            <NuxtErrorBoundary>
                <Swiper :modules="modules" :slides-per-view="1" :pagination="{clickable: true}">
                    <SwiperSlide v-for="(persona, personaIndex) in slice.items" :key="`slice-item-${personaIndex}`">
                        <div class="persona-button">
                            <div class="persona-name-content">
                                <PrismicImage
                                    :key="`persona-icon-${personaIndex}`"
                                    :image="persona.icon"
                                    class="icon"
                                    width="28"
                                    height="28"
                                    sizes="small:28px"
                                />
                                <div>
                                    {{ persona.persona }}
                                </div>
                            </div>
                            <div class="mobile-border"></div>
                        </div>
                        <div class="benefits">
                            <div
                                v-for="benefit in [
                                    {
                                        title: persona.benefitTitle1,
                                        description: persona.benefit1,
                                        index: 1,
                                    },
                                    {
                                        title: persona.benefitTitle2,
                                        description: persona.benefit2,
                                        index: 2,
                                    },
                                    {
                                        title: persona.benefitTitle3,
                                        description: persona.benefit3,
                                        index: 3,
                                    },
                                    {
                                        title: persona.benefitTitle4,
                                        description: persona.benefit4,
                                        index: 4,
                                    },
                                ]"
                                :key="`benefit-${benefit.index}`"
                                class="benefit"
                            >
                                <PrismicRichText :field="benefit.title" class="benefit-title" />
                                <PrismicRichText
                                    :field="benefit.description"
                                    :class="{
                                        ellipsed: ellipsedBenefits[personaIndex][benefit.index - 1],
                                    }"
                                />
                                <button class="display-benefit-description" @click="setEllipsedBenefit(personaIndex, benefit.index - 1)">
                                    <div v-if="hasText(benefit.description[0])">
                                        <div v-if="ellipsedBenefits[personaIndex][benefit.index - 1]">
                                            {{ slice.primary.displayFullBenefitText }}
                                        </div>
                                        <div v-else>{{ slice.primary.collapseBenefitText }}</div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </NuxtErrorBoundary>
        </div>

        <div v-else class="personae">
            <div class="personae-names" role="tablist">
                <button
                    v-for="(persona, i) in slice.items"
                    :key="`persona-name-${i}`"
                    :class="{'persona-button': true, selected: isSelectedPersona(i)}"
                    role="tab"
                    :aria-selected="isSelectedPersona(i)"
                    @click="setSelectedPersona(i)"
                >
                    <div class="persona-name-content">
                        <PrismicImage :image="persona.icon" class="icon" width="28" height="28" sizes="small:28px" />
                        <div :class="{selected: isSelectedPersona(i)}">
                            {{ persona.persona }}
                        </div>
                    </div>
                    <div
                        :class="{
                            rectangle: isSelectedPersona(i),
                            border: !isSelectedPersona(i),
                        }"
                    ></div>
                </button>
            </div>
            <div class="benefits">
                <div
                    v-for="benefit in [
                        {
                            title: slice.items[selectedPersona].benefitTitle1,
                            description: slice.items[selectedPersona].benefit1,
                            index: 1,
                        },
                        {
                            title: slice.items[selectedPersona].benefitTitle2,
                            description: slice.items[selectedPersona].benefit2,
                            index: 2,
                        },
                        {
                            title: slice.items[selectedPersona].benefitTitle3,
                            description: slice.items[selectedPersona].benefit3,
                            index: 3,
                        },
                        {
                            title: slice.items[selectedPersona].benefitTitle4,
                            description: slice.items[selectedPersona].benefit4,
                            index: 4,
                        },
                    ]"
                    :key="`benefit-${benefit.index}`"
                    :class="{benefit: true, hidden: !isTitleFilled(benefit.title)}"
                >
                    <PrismicRichText :field="benefit.title" class="benefit-title" />
                    <PrismicRichText :field="benefit.description" />
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    // @ts-ignore
    import {Pagination, Lazy} from 'swiper';
    import {Swiper, SwiperSlide} from 'swiper/vue';
    import {getSliceComponentProps, PrismicRichText} from '@prismicio/vue';
    import type {RichTextField, TitleField} from '@prismicio/types';
    import type {Ref} from 'vue';
    import {inject, ref, onMounted} from 'vue';
    import {asText} from '@prismicio/helpers';
    import 'swiper/css';
    import 'swiper/scss/pagination';
    import {MEDIA_BREAKPOINTS} from '~/app/keys';
    import {usePrismicTypeGuards, usePrismicFieldHelpers} from '#imports';
    import PrismicImage from '~/components/PrismicImage.vue';
    import useErrorHelpers from '~/composables/useErrorHelpers';
    import type {PersonaContentSliceSlice} from '@prismicio-types';

    const breakpoints = inject(MEDIA_BREAKPOINTS);
    const props = defineProps(getSliceComponentProps<PersonaContentSliceSlice>());
    const {hasText} = usePrismicFieldHelpers();
    const {hasProperty, isFilled} = usePrismicTypeGuards();
    const modules = [Pagination, Lazy];
    const selectedPersona = ref(0);
    const ellipsedBenefits: Ref<boolean[][]> = ref([
        Array(4).fill(true),
        Array(4).fill(true),
        Array(4).fill(true),
        Array(4).fill(true),
        Array(4).fill(true),
    ]);

    onMounted(() => {
        const {getPreviewErrorHandler, throwIfItemsCountIsOutOfBound, throwIfMissingItemsProperties} = useErrorHelpers();
        const errorHandler = getPreviewErrorHandler();
        if (errorHandler.enabled) {
            const itemsCount = props.slice.items.length;
            throwIfItemsCountIsOutOfBound(errorHandler.toast, itemsCount, 'persona', 2, 5);
            throwIfMissingItemsProperties(
                errorHandler.toast,
                props.slice.items,
                [
                    {name: 'persona', type: 'field'},
                    {name: 'benefitTitle1', type: 'field'},
                    {name: 'benefit1', type: 'field'},
                    {name: 'benefitTitle2', type: 'field'},
                    {name: 'benefit2', type: 'field'},
                    {name: 'icon', type: 'image'},
                ],
                'persona',
            );
        }
    });
    function setSelectedPersona(personaIndex: number) {
        selectedPersona.value = personaIndex;
    }

    function isSelectedPersona(personaIndex: number) {
        return selectedPersona.value === personaIndex;
    }
    function isTitleFilled(title: TitleField | RichTextField) {
        const element = title[0];
        if (!hasProperty(element, 'text')) {
            return false;
        }
        return isFilled(element.text);
    }
    function setEllipsedBenefit(personaIndex: number, benefitIndex: number) {
        const newEllipsedBenefits = ellipsedBenefits.value;
        newEllipsedBenefits[personaIndex][benefitIndex] = !ellipsedBenefits.value[personaIndex][benefitIndex];
        ellipsedBenefits.value = [...newEllipsedBenefits];
    }
</script>

<style lang="scss">
    .persona-slice {
        font-family: $font-family;
        color: var(--joy-color-neutral-70);
        border-bottom-right-radius: 10px;
        @include flex-column-center;
        @include content-width;
        @media (max-width: $mobile-breakpoint) {
            align-items: flex-start;
        }

        .title h2 {
            @include secondary-title;
            margin-bottom: 48px;
            text-align: center;
            @media (max-width: $mobile-breakpoint) {
                text-align: left;
                margin-bottom: 32px;
            }
        }

        .swiper {
            overflow: hidden;
            position: relative;
            z-index: 0;
            max-width: calc(100vw - 2 * #{$margin-section-mobile});
            margin-bottom: 48px;
            .swiper-slide {
                width: 100%;
            }
            .swiper-slide .persona-button {
                color: var(--joy-color-neutral-30);
                .mobile-border {
                    @include border;
                }
                .icon {
                    filter: grayscale(100%) brightness(2.5);
                    margin-right: 20px;
                    width: 28px;
                    height: 28px;
                }
                margin-bottom: 35px;
            }

            .swiper-slide-active .persona-button {
                .persona-name-content {
                    color: var(--joy-color-secondary-30);
                }
                .mobile-border {
                    @include rectangle;
                }
                .icon {
                    filter: unset;
                    margin-right: 20px;
                    width: 28px;
                    height: 28px;
                }
            }
            @include swiper-pagination-bullets;
        }

        .personae-names {
            display: flex;
            width: 100%;
        }

        .persona-button {
            @include flex-column-align-center;
            justify-content: flex-end;
            padding: 0px;
            background-color: transparent;
            color: var(--joy-color-neutral-30);
            font-weight: var(--joy-font-weight-bold);
            border: 0px;
            font-size: var(--joy-font-size-primary-600);
            line-height: var(--joy-line-height-large);
            width: 100%;

            .icon {
                filter: grayscale(100%) brightness(2.5);
                margin-right: 20px;
                width: 28px;
                height: 28px;
            }

            &.selected {
                color: var(--joy-color-secondary-30);
                .icon {
                    filter: unset;
                }
            }

            .persona-name-content {
                @include flex-row-center;
                margin: 10px 20px;
            }

            .rectangle {
                @include rectangle;
            }

            .border {
                @include border;
            }
        }

        .personae {
            width: 100%;
            max-width: $container-max-width;
            @include flex-column-start;
            gap: 40px;
            line-height: $line-height-xl;
            @media (max-width: $mobile-breakpoint) {
                align-items: center;
                gap: 0px;
            }
        }

        .benefit-title h3 {
            font-weight: var(--joy-font-weight-bold);
            font-size: var(--joy-font-size-primary-600);
            line-height: $line-height-lg;
            color: var(--joy-color-neutral-50);
            margin-bottom: 20px;
            @media (max-width: $mobile-breakpoint) {
                font-size: var(--joy-font-size-primary-600);
                margin-bottom: 0px;
            }
        }

        .benefits {
            display: flex;
            gap: 64px;
            width: 100%;
            @media (max-width: $mobile-breakpoint) {
                flex-direction: column;
                gap: var(--joy-core-spacing-9);
                padding-bottom: var(--joy-core-spacing-6);
            }
        }

        .link {
            @include link;
        }

        .benefit {
            flex: 1 1 0;
            color: var(--joy-color-neutral-50);
            line-height: var(--joy-line-height-large);
            font-size: var(--joy-font-size-primary-400);
            @media (max-width: $mobile-breakpoint) {
                font-size: $font-size-base;
                margin-bottom: 10px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
                p {
                    overflow: visible;
                }
                .ellipsed {
                    p {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }
            }
        }

        .hidden {
            display: none;
        }

        .display-benefit-description {
            border: none;
            padding: 0px;
            @include link;
        }
    }
</style>
