<template>
    <section :id="slice.primary.anchorName || undefined" ref="section" class="slice slice-add-on" aria-label="columns">
        <div class="slice-content">
            <div class="add-on-panel">
                <div v-if="slice.primary.category" class="add-on-category">{{ slice.primary.category }}</div>
                <PrismicRichText v-if="slice.primary.title" :field="slice.primary.title" class="slice-title" />
                <PrismicRichText v-if="slice.primary.description" :field="slice.primary.description" class="slice-description" />

                <div class="slice-body add-on-items">
                    <div v-for="(item, i) in slice.items" :key="i" class="add-on">
                        <PrismicImage :image="item.image" class="add-on-image" />
                        <div class="add-on-content">
                            <div class="add-on-title">{{ item.title }}</div>
                            <PrismicRichText class="add-on-text" :field="item.text" />
                        </div>
                    </div>
                </div>

                <div v-if="ctaUrl || ctaLink.url" class="slice-footer">
                    <VJoyButton :href="ctaUrl || ctaLink.url" data-source-component="add-on">{{ ctaLabel }}</VJoyButton>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {ref} from 'vue';
    import {VJoyButton} from '@maltjoy/core-vue';
    import type {AddOnSlice} from '@prismicio-types';
    import {getSliceComponentProps, PrismicRichText} from '@prismicio/vue';
    import type {FilledLinkToWebField} from '@prismicio/types';
    import PrismicImage from '~/components/PrismicImage.vue';
    import {useComponentTracking} from '#imports';

    const props = defineProps(getSliceComponentProps<AddOnSlice>(['slice', 'index', 'slices', 'context']));

    const section = ref(null);
    const ctaLink = ref(props.slice.primary.ctaLink as FilledLinkToWebField);
    const ctaUrl = ref(props.slice.primary.ctaUrl);
    const ctaLabel = ref(props.slice.primary.ctaLabel);

    useComponentTracking(section);
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .slice-add-on {
        .add-on {
            &-panel {
                text-align: left;
                border-radius: var(--joy-core-radius-4);
                background-color: var(--joy-color-secondary-10);
                padding: var(--joy-core-spacing-8) var(--joy-core-spacing-5);

                @include mq.screen_md(min) {
                    padding: var(--joy-core-spacing-12) 80px;
                }
            }
            &-category {
                font-weight: var(--joy-font-weight-bold);
                color: var(--joy-color-secondary-30);
                margin-bottom: var(--joy-core-spacing-2);
            }
            &-items {
                @include mq.screen_md(min) {
                    display: grid;
                    column-gap: var(--joy-core-spacing-8);
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                }
            }
        }

        .add-on {
            display: flex;
            align-items: flex-start;
            gap: var(--joy-core-spacing-4);
            margin-bottom: var(--joy-core-spacing-8);

            &-image {
                width: 40px;
            }
            &-content {
                flex: 1 1 0%;
                width: 100%;
            }
            &-title {
                font-size: var(--joy-font-size-primary-600);
                font-weight: var(--joy-font-weight-bold);
            }
            &-text {
                margin-top: var(--joy-core-spacing-4);
            }
        }
        .slice-description {
            max-width: none;
        }
    }
</style>
