<template>
    <div class="thumbnail-consultant">
        <svg width="0" height="0">
            <defs>
                <clipPath id="thumb-svg"><path :d="svgPath" /></clipPath>
            </defs>
        </svg>
        <Component :is="tag" v-bind="attrs">
            <div class="thumbnail-consultant__image">
                <PrismicImage :image="consultant.image" />
            </div>
            <div class="thumbnail-consultant__info">
                <div class="thumbnail-consultant__name">{{ consultant.name }}</div>
                <p class="thumbnail-consultant__job">{{ consultant.job }}</p>
                <p class="thumbnail-consultant__location">{{ consultant.location }}</p>
            </div>
        </Component>
    </div>
</template>

<script setup lang="ts">
    import {ref} from 'vue';
    import PrismicImage from '~/components/PrismicImage.vue';
    import type {ConsultantProfilesSliceThumbnailsItem} from '@prismicio-types';

    const props = defineProps<{consultant: ConsultantProfilesSliceThumbnailsItem}>();
    const tag = ref(props.consultant.link ? 'a' : 'span');
    const attrs = ref(props.consultant.link ? {href: props.consultant.link} : {});
    const svgPath =
        'M 78.985 198 C 147.664 198 157.97 146.246 157.97 105.909 L 157.97 92.093 C 157.958 51.755 147.651 0 78.985 0 C 10.306 0 0 51.755 0 92.093 L 0 105.909 C 0 146.246 10.306 198 78.985 198 Z';
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    $image_width: 158px;
    $image_height: 198px;
    $transition_params: 0.4s ease-out;

    .thumbnail-consultant {
        text-align: center;
        margin-bottom: var(--joy-core-spacing-10);
        user-select: none;

        p {
            margin-top: var(--joy-core-spacing-1);
            font-size: var(--joy-font-size-primary-400);
            transition: color $transition_params;
            color: var(--joy-color-neutral-40);
        }
        &__image {
            overflow: hidden;
            width: $image_width;
            height: $image_height;
            clip-path: url(#thumb-svg);
            display: inline-block;
            margin: 0 auto var(--joy-core-spacing-4);
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform $transition_params;
        }
        &__name {
            font-family: var(--joy-font-family-title);
            color: var(--joy-color-neutral-50);
            font-size: var(--joy-font-size-secondary-200);
            line-height: 90%;
            transition: color $transition_params;
        }
        &__job {
            font-weight: var(--joy-font-weight-bold);
        }
        a:hover {
            img {
                transform: scale(1.05);
            }
            div,
            p {
                color: var(--joy-color-neutral-70);
            }
        }
    }
</style>
