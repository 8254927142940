<template>
    <section ref="section" class="slice slice-hero-with-shapes">
        <div class="background">
            <svg
                class="shape"
                :viewBox="`0 0 ${shapeData.width} ${shapeData.height}`"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
            >
                <path class="shape-xl" :d="shapeData.xl" />
                <path class="shape-s" :d="shapeData.s" />
                <path class="shape-m" :d="shapeData.m" />
                <path class="shape-l" :d="shapeData.l" />
            </svg>
        </div>
        <div class="slice-content">
            <PrismicRichText v-if="title" :field="title" class="slice-title" />
            <VJoyButton v-if="ctaUrl || ctaLink.url" :href="ctaUrl || ctaLink.url" variant="white" data-source-component="hero-with-shapes">
                {{ ctaLabel }}
            </VJoyButton>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {ref} from 'vue';
    import {VJoyButton} from '@maltjoy/core-vue';
    import {getSliceComponentProps, PrismicRichText} from '@prismicio/vue';
    import type {HeroWithShapesSlice} from '@prismicio-types';
    import type {FilledLinkToWebField} from '@prismicio/types';
    import {useComponentTracking} from '#imports';

    interface ShapeData {
        width?: number;
        height?: number;
        s?: string;
        m?: string;
        l?: string;
        xl?: string;
    }

    const props = defineProps(getSliceComponentProps<HeroWithShapesSlice>(['slice', 'index', 'slices', 'context']));

    const title = ref(props.slice.primary.title);
    const ctaLink = ref(props.slice.primary.ctaLink as FilledLinkToWebField);
    const ctaUrl = ref(props.slice.primary.ctaUrl);
    const ctaLabel = ref(props.slice.primary.ctaLabel);
    const section = ref(null);

    const shapeWidth = 1440;
    let shapeData: ShapeData = {};

    switch (props.slice.primary.shape) {
        case 'top-left':
            shapeData = {
                width: shapeWidth,
                height: 584,
                m: 'M454.721 470.08C451.076 394.715 385.662 381.977 335.282 384.41L97.2874 395.916L-140.706 407.423C-191.092 409.861 -255.163 424.886 -251.33 504.222C-247.493 583.551 -182.271 592.325 -131.89 589.891L106.104 578.385L344.102 566.873C394.483 564.44 458.361 545.449 454.721 470.08Z',
                xl: 'M1522.45 326.57C1551.7 83.4382 1349.29 7.17427 1186.76 -12.3717L418.983 -104.699L-348.808 -197.02C-511.346 -216.573 -724.514 -203.251 -755.29 52.6706C-786.068 308.598 -582.129 372.064 -419.591 391.61L348.2 483.936L1115.97 576.26C1278.51 595.808 1493.22 569.701 1522.45 326.57Z',
            };
            break;
        case 'bottom-left':
            shapeData = {
                width: shapeWidth,
                height: 593,
                s: 'M288.004 384.896C315.182 339.529 281.989 306.666 251.662 288.496L108.4 202.667L-34.862 116.84C-65.1931 98.67 -108.399 82.5198 -137.011 130.275C-165.619 178.029 -130.996 208.505 -100.67 226.676L42.5927 312.504L185.86 398.33C216.186 416.501 260.822 430.264 288.004 384.896Z',
                m: 'M608.013 572.626C633.766 473.022 553.748 429.963 487.165 412.743L172.625 331.411L-141.913 250.081C-208.505 232.865 -298.011 226.5 -325.126 331.351C-352.233 436.195 -270.861 474.013 -204.277 491.234L110.262 572.564L424.809 653.89C491.392 671.111 582.252 672.234 608.013 572.626Z',
                l: 'M1598.55 808.433C1607.22 606.224 1435.58 556.232 1300.4 550.446L661.857 523.117L23.2993 495.794C-111.881 490.003 -286.696 514.517 -295.806 727.363C-304.917 940.213 -132.831 979.562 2.34838 985.348L640.907 1012.67L1279.45 1040C1414.63 1045.79 1589.91 1010.64 1598.55 808.433Z',
                xl: 'M2648.93 270.67C2663.61 -372.148 2329.67 -557.208 2067.03 -595.969L826.367 -779.058L-414.319 -962.127C-676.968 -1000.91 -1016.28 -949.111 -1031.72 -272.477C-1047.15 404.171 -712.458 555.395 -449.811 594.156L790.877 777.24L2031.53 960.32C2294.18 999.085 2634.28 913.486 2648.93 270.67Z',
            };
            break;
        case 'right':
        default:
            shapeData = {
                width: shapeWidth,
                height: 582,
                s: 'M1544.19 566.306C1567.92 535.849 1546.8 509.076 1526.44 493.207L1430.26 418.245L1334.08 343.285C1313.72 327.415 1283.85 311.862 1258.86 343.923C1233.87 375.982 1256.25 401.152 1276.61 417.022L1372.78 491.983L1468.97 566.943C1489.33 582.813 1520.45 596.763 1544.19 566.306Z',
                m: 'M1681.54 633.665C1700.43 560.615 1641.74 529.035 1592.91 516.406L1362.22 456.757L1131.54 397.109C1082.7 384.482 1017.06 379.814 997.169 456.712C977.288 533.606 1036.97 561.342 1085.8 573.971L1316.48 633.62L1547.18 693.265C1596.01 705.894 1662.64 706.718 1681.54 633.665Z',
                l: 'M1648.97 809.115C1661.56 629.674 1510.28 581.103 1390.32 572.689L823.676 532.947L257.017 493.21C137.057 484.791 -18.8487 502.338 -32.0963 691.218C-45.345 880.102 106.59 919.228 226.549 927.642L793.208 967.383L1359.85 1007.12C1479.81 1015.54 1636.39 988.555 1648.97 809.115Z',
                xl: 'M2648.93 270.67C2663.61 -372.148 2329.67 -557.208 2067.03 -595.969L826.367 -779.058L-414.319 -962.127C-676.968 -1000.91 -1016.28 -949.111 -1031.72 -272.477C-1047.15 404.171 -712.458 555.395 -449.811 594.156L790.877 777.24L2031.53 960.32C2294.18 999.085 2634.28 913.486 2648.93 270.67Z',
            };
            break;
    }

    useComponentTracking(section);
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .slice-hero-with-shapes {
        overflow: hidden;

        .background {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            justify-content: center;
        }

        .shape {
            fill: none;
            overflow: visible;
            height: 100%;
            &-s {
                fill: var(--joy-color-neutral-20);
            }
            &-m {
                fill: var(--joy-color-tertiary-50);
            }
            &-l {
                fill: var(--joy-color-secondary-50);
            }
            &-xl {
                fill: var(--joy-color-secondary-30);
            }
        }
        .slice-title {
            margin-bottom: var(--joy-core-spacing-10);
            h1 {
                color: white;
                font-size: var(--joy-font-size-secondary-400);
                @include mq.screen_md(min) {
                    font-size: var(--joy-font-size-secondary-800);
                    line-height: 133.33%;
                }
            }
        }
        .slice-content {
            position: relative;
            padding-top: 75px;
            padding-bottom: 75px;
            max-width: 700px;
            @include mq.screen_md(min) {
                padding-top: 120px;
                padding-bottom: 120px;
            }
        }
    }
</style>
