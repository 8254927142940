<template>
    <section
        v-if="!(breakpoints?.isMobile && slice.primary.hideInMobile)"
        ref="section"
        class="slice slice-jobs-family"
        :aria-label="asText(slice.primary.title)"
    >
        <div class="slice-content">
            <div class="images-wrapper">
                <div ref="rellaxTopRef" data-rellax-speed="-1" class="image-top" data-rellax-zindex="1">
                    <PrismicImage :image="slice.primary.topImage" class="image" height="287" width="217" sizes="small:217px" />
                </div>
                <div ref="rellaxBottomRef" data-rellax-speed="1" class="image-bottom" data-rellax-zindex="2">
                    <PrismicImage :image="slice.primary.bottomImage" class="image" height="287" width="217" sizes="small:217px" />
                </div>
            </div>
            <div class="jobs-families-wrapper">
                <PrismicRichText :field="slice.primary.title" class="slice-title" />
                <!-- repeating v-if is a fix to a rendering issue with the swiper component. -->
                <div v-if="breakpoints?.isMobile" class="families">
                    <template v-if="breakpoints?.isMobile">
                        <NuxtErrorBoundary v-if="breakpoints?.isMobile">
                            <Swiper :modules="modules" :slides-per-view="1" :pagination="{clickable: true}">
                                <SwiperSlide v-for="(family, familyIndex) in notEmptyJobFamilyNames" :key="`family-${familyIndex}`">
                                    <button class="family">
                                        <div class="family-title">
                                            {{ family.name }}
                                        </div>
                                        <div class="mobile-border"></div>
                                    </button>
                                    <div class="benefits">
                                        <div class="jobs-families">
                                            <div class="jobs-family">
                                                <div
                                                    v-for="(job, jobIndex) in slice.items"
                                                    :key="`jobs-${jobIndex}`"
                                                    :class="{
                                                        hidden:
                                                            job.jobFamily === null ? true : job.jobFamily.slice(-1) !== (familyIndex + 1).toString(),
                                                    }"
                                                >
                                                    <ServerSideNavigationPrismicLink
                                                        class="job"
                                                        :field="job.jobSearchLink"
                                                        :rel="getJobSearchLinkRel(job.jobSearchLink)"
                                                        data-source-component="job-family"
                                                    >
                                                        <span class="job-title">{{ job.jobTitle }}</span>
                                                        <span class="link">{{ job.textToDisplayOnHover }}</span>
                                                    </ServerSideNavigationPrismicLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </NuxtErrorBoundary>
                    </template>
                </div>
                <div v-else>
                    <div role="tablist" class="families">
                        <button
                            v-for="(family, familyIndex) in notEmptyJobFamilyNames"
                            :key="`family-${familyIndex}`"
                            :aria-selected="selectedJobFamily === familyIndex + 1"
                            :aria-label="family.name || `show family ${familyIndex}`"
                            :class="{
                                family: true,
                                selected: selectedJobFamily === familyIndex + 1,
                            }"
                            role="tab"
                            @click="setSelectedJobFamily(familyIndex + 1)"
                        >
                            <div class="family-title">
                                {{ family.name }}
                            </div>
                            <div
                                :class="{
                                    rectangle: selectedJobFamily === familyIndex + 1,
                                    border: selectedJobFamily !== familyIndex + 1,
                                }"
                            ></div>
                        </button>
                    </div>
                    <div class="jobs-families">
                        <div
                            v-for="(family, familyIndex) in familySelectOptions"
                            :key="family"
                            :class="{
                                'jobs-family': true,
                                hidden: selectedJobFamily !== familyIndex + 1,
                            }"
                            :data-testid="`jobs-family-${familyIndex}`"
                        >
                            <ServerSideNavigationPrismicLink
                                v-for="(job, index) in jobsByJobFamily[family]"
                                :key="`${family}-${index}`"
                                class="job"
                                :field="job.jobSearchLink"
                                :rel="getJobSearchLinkRel(job.jobSearchLink)"
                                data-source-component="job-family"
                            >
                                <span class="job-title">{{ job.jobTitle }}</span>
                                <span class="link">{{ job.textToDisplayOnHover }}</span>
                            </ServerSideNavigationPrismicLink>
                        </div>
                    </div>
                </div>
                <VJoyButton
                    v-if="isFilledKeyText(slice.primary.linkText) && isFilledKeyText(slice.primary.link)"
                    variant="main"
                    :href="slice.primary.link"
                    data-source-component="job-family"
                >
                    {{ slice.primary.linkText }}
                </VJoyButton>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {getSliceComponentProps, PrismicRichText} from '@prismicio/vue';
    import {VJoyButton} from '@maltjoy/core-vue';
    import {inject, ref, onMounted} from 'vue';
    import {Swiper, SwiperSlide} from 'swiper/vue';
    // @ts-ignore
    import {Pagination} from 'swiper';
    import type {RellaxInstance} from 'rellax';
    // @ts-ignore
    // eslint-disable-next-line import/no-named-as-default
    import Rellax from 'rellax';
    import {asText} from '@prismicio/helpers';
    import type {JobsByJobFamily} from './jobsByJobFamilyHelper';
    import {getJobFamilyNamesNotEmpty, getJobsByJobFamily, isFamilyEmpty} from './jobsByJobFamilyHelper';
    import PrismicImage from '~/components/PrismicImage.vue';
    import ServerSideNavigationPrismicLink from '~/components/ServerSideNavigationPrismicLink.vue';
    import 'swiper/scss/pagination';
    import {MEDIA_BREAKPOINTS} from '~/app/keys';
    import {usePrismicTypeGuards, usePrismicFieldHelpers, useComponentTracking} from '#imports';
    import useErrorHelpers from '~/composables/useErrorHelpers';
    import type {JobsFamilySliceSlice} from '@prismicio-types';
    import type {Maybe} from '@malt/types-utils';
    import type {KeyTextField} from '@prismicio/types';

    const modules = [Pagination];
    const breakpoints = inject(MEDIA_BREAKPOINTS);
    const props = defineProps(getSliceComponentProps<JobsFamilySliceSlice>());

    const section = ref(null);
    const selectedJobFamily = ref(1);

    const rellaxTopRef = ref(null);
    const rellaxBottomRef = ref(null);
    const rellaxTop = ref<Maybe<RellaxInstance>>(null);
    const rellaxBottom = ref<Maybe<RellaxInstance>>(null);

    const jobsByJobFamily = getJobsByJobFamily(props.slice.items);
    const familySelectOptions: (keyof JobsByJobFamily)[] = ['family1', 'family2', 'family3', 'family4', 'family5'];
    const notEmptyJobFamilyNames = getJobFamilyNamesNotEmpty(props.slice.primary);

    const {hasProperty, isFilledKeyText} = usePrismicTypeGuards();
    const {hasLessThanTwoLabels} = usePrismicFieldHelpers();
    onMounted(() => {
        if (rellaxTopRef.value !== null) {
            rellaxTop.value = new Rellax(rellaxTopRef.value, {center: true});
        }
        if (rellaxBottomRef.value !== null) {
            rellaxBottom.value = new Rellax(rellaxBottomRef.value, {
                center: true,
            });
        }

        const {getPreviewErrorHandler, throwIfPrimaryPropertiesAreInvalid} = useErrorHelpers();
        const errorHandler = getPreviewErrorHandler();

        if (errorHandler.enabled) {
            if (jobsByJobFamily.family1.length > 8) {
                errorHandler.toast('The number of jobs in the first family is greater than 8');
            }
            if (jobsByJobFamily.family2.length > 8) {
                errorHandler.toast('The number of jobs in the second family is greater than 8');
            }
            if (jobsByJobFamily.family3.length > 8) {
                errorHandler.toast('The number of jobs in the third family is greater than 8');
            }
            if (jobsByJobFamily.family4.length > 8) {
                errorHandler.toast('The number of jobs in the fourth family is greater than 8');
            }
            if (jobsByJobFamily.family5.length > 8) {
                errorHandler.toast('The number of jobs in the fifth family is greater than 8');
            }
            if (
                isFamilyEmpty(props.slice.primary.jobFamily1, jobsByJobFamily.family1) ||
                isFamilyEmpty(props.slice.primary.jobFamily2, jobsByJobFamily.family2) ||
                isFamilyEmpty(props.slice.primary.jobFamily3, jobsByJobFamily.family3) ||
                isFamilyEmpty(props.slice.primary.jobFamily4, jobsByJobFamily.family4) ||
                isFamilyEmpty(props.slice.primary.jobFamily5, jobsByJobFamily.family5)
            ) {
                errorHandler.toast('A job family is defined with no jobs assigned to it');
            }
            if (
                hasLessThanTwoLabels([
                    props.slice.primary.jobFamily1,
                    props.slice.primary.jobFamily2,
                    props.slice.primary.jobFamily3,
                    props.slice.primary.jobFamily4,
                    props.slice.primary.jobFamily5,
                ])
            ) {
                errorHandler.toast('There are less than two job families defined');
            }
            if (props.slice.items.length === 0) {
                errorHandler.toast('The slice jobsFamily has no jobs');
            }
            throwIfPrimaryPropertiesAreInvalid(
                errorHandler.toast,
                props.slice.primary,
                [
                    {name: 'title', type: 'field'},
                    {name: 'topImage', type: 'image'},
                    {name: 'bottomImage', type: 'image'},
                ],
                'jobs family',
            );
        }
    });
    function setSelectedJobFamily(familyIndex: number) {
        selectedJobFamily.value = familyIndex;
    }
    function getJobSearchLinkRel(link: KeyTextField) {
        return typeof link === 'string' && link.startsWith('/s?') ? 'nofollow' : undefined;
    }

    useComponentTracking(section);
</script>

<style lang="scss">
    $jobs-width: 840px;
    $image-width: 400px;
    $image-hidden-breakpoint: calc(#{$jobs-width} + #{$image-width} + 2 * #{$margin-section-normal});

    .slice-jobs-family {
        .slice-content {
            @include flex-row-center;
        }

        .family-title {
            @include flex-row-center;
            min-height: 65px;
            text-transform: uppercase;
            width: 100%;
            margin-bottom: 24px;
        }

        .families {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            align-self: flex-start;
            width: 100%;
            margin-bottom: 48px;
            @media (max-width: $mobile-breakpoint) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 0;
            }

            > div {
                @media (min-width: $mobile-breakpoint) {
                    flex: 1 1 0;
                }
            }

            button {
                flex-direction: column;
                justify-content: center;
                flex: 1 1 0px;
                border: none;
                padding: 0px;
                color: var(--joy-color-neutral-40);
                font-size: var(--joy-font-size-primary-400);
                font-weight: var(--joy-font-weight-bold);
                line-height: var(--joy-line-height-large);

                user-select: none;
                transition: all var(--joy-transition-duration-default) var(--joy-transition-timing-function);

                &:hover {
                    color: var(--joy-color-neutral-50);
                }
                &.selected {
                    color: var(--joy-color-secondary-30);
                }
            }

            .rectangle {
                @include rectangle;
            }

            .border {
                @include border;
            }
        }

        .jobs-families-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 48px;
            width: $jobs-width;
            @media (max-width: $mobile-breakpoint) {
                align-items: center;
            }
        }

        .jobs-families {
            width: $jobs-width;
            min-height: 250px;
            @media (max-width: $mobile-breakpoint) {
                width: 100%;
                height: auto;
            }

            .jobs-family {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-template-rows: auto auto;
                gap: var(--joy-core-spacing-9);
                user-select: none;

                @media (max-width: $mobile-breakpoint) {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    gap: 24px;
                    &.hidden {
                        display: none;
                    }
                }

                .job {
                    @include flex-column-center;
                    box-sizing: border-box;
                    padding: 50px 30px;
                    color: var(--joy-color-neutral-50);
                    width: 180px;
                    height: 98px;
                    text-align: center;
                    border-radius: var(--joy-core-radius-4);
                    border: 1px solid var(--joy-color-neutral-20);
                    transition: background-color var(--joy-transition-duration-default) var(--joy-transition-timing-function);
                    @media (max-width: $mobile-breakpoint) {
                        width: 157px;
                        height: 98px;
                    }
                    &:hover {
                        background-color: var(--joy-color-secondary-30);
                        transition: background-color var(--joy-transition-duration-default) var(--joy-transition-timing-function);

                        .link {
                            display: inline;
                            color: white;
                        }

                        .job-title {
                            display: none;
                        }
                    }

                    .link {
                        display: none;
                    }
                }
            }

            .hidden {
                display: none;
            }
        }
        .images-wrapper {
            margin-right: 60px;
            flex-basis: 40%;
            padding-top: 120px;
            @media (max-width: $image-hidden-breakpoint) {
                display: none;
            }
        }
        .image {
            width: auto;
            height: 287px;
            margin: 0;
        }
        $image-offset-x: 180px;
        $image-offset-y: 140px;
        .image-top {
            margin-bottom: -$image-offset-x;
            margin-right: $image-offset-y;
        }
        .image-bottom {
            margin-top: -$image-offset-x;
            margin-left: $image-offset-y;
        }

        .swiper {
            overflow: hidden;
            position: relative;
            z-index: 0;
            width: 100%;
            max-width: calc(100vw - 2 * #{$margin-section-mobile});
            margin-bottom: 48px;
            .swiper-slide {
                width: 100%;
                .family {
                    width: 100%;
                    color: var(--joy-color-neutral-30);
                    margin-bottom: 48px;
                    .mobile-border {
                        @include border;
                    }
                }
            }

            .benefits {
                width: 100%;
            }

            .family-title {
                min-height: unset;
            }

            .swiper-slide-active .family {
                .family-title {
                    color: var(--joy-color-secondary-30);
                    font-size: $font-size-base;
                }
                .mobile-border {
                    @include rectangle;
                }
            }
        }

        @include swiper-pagination-bullets;

        .swiper-pagination {
            margin-top: var(--joy-core-spacing-8);
        }
    }
</style>
