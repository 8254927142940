<template>
    <div
        v-if="isVisible"
        ref="elem"
        class="widget-trustpilot"
        :data-locale="locale"
        :data-template-id="templateId"
        :data-businessunit-id="businessunitId"
        data-style-height="22px"
        data-style-width="100%"
        data-theme="light"
    >
        <NuxtLink :to="link" target="_blank" rel="noopener">Trustpilot</NuxtLink>
    </div>
</template>

<script setup lang="ts">
    import {ref, onMounted, computed} from 'vue';

    const props = defineProps<{locale?: string; templateId?: string; businessunitId?: string; link: string}>();

    const isVisible = computed(() => props.locale && props.templateId && props.businessunitId && props.link);
    const elem = ref<HTMLDivElement>();

    function initWidget() {
        if (elem.value) {
            // @ts-expect-error
            window.Trustpilot.loadFromElement(elem.value);
        }
    }

    onMounted(() => {
        if (!isVisible.value) return;
        // @ts-expect-error
        if (window.Trustpilot) {
            initWidget();
        } else {
            const script = document.createElement('script');
            script.src = 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
            document.head.appendChild(script);
            script.addEventListener('load', initWidget);
        }
    });
</script>

<style lang="scss">
    .widget-trustpilot {
        margin: 0 auto;
        user-select: none;
        height: 22px;
    }
</style>
