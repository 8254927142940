<template>
    <div
        class="video-player"
        :class="{'video-player--small': size === 'small', 'video-player--medium': size === 'medium', 'video-player--large': size === 'large'}"
    >
        <iframe ref="iframeRef" :src="computedSrc" frameborder="0" />
        <div v-if="cover && displayCover" ref="coverRef" class="video-player__overlay" @click="startVideo()">
            <MaltImg :src="cover" class="video-player__cover" width="900" :alt="title || 'Video'" />
            <div class="relative">
                <div v-if="title" class="video-player__title">{{ title }}</div>
                <VJoyIcon name="play" class="video-player__icon" size="large" color="white" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {computed, onMounted, ref} from 'vue';
    import {MaltImg} from '#components';
    import {VJoyIcon} from '@maltjoy/core-vue';

    const props = defineProps<{src: string; title?: string; cover?: string; size?: 'small' | 'medium' | 'large'}>();

    const iframeRef = ref<HTMLIFrameElement | null>(null);
    const coverRef = ref<HTMLElement | null>(null);
    const displayCover = ref(true);

    const provider = computed(() => {
        if (!props.src) return '';

        if (props.src.includes('vimeo.com')) {
            return 'vimeo';
        }
        if (props.src.includes('youtube.com')) {
            return 'youtube';
        }
        return '';
    });

    const computedSrc = computed(() => {
        if (provider.value === 'youtube') {
            return `${props.src}&enablejsapi=1`;
        }
        return props.src;
    });

    const providerPlayMessages: Record<string, object> = {
        youtube: {event: 'command', func: 'playVideo', args: []},
        vimeo: {method: 'play'},
    };
    const providerPauseMessages: Record<string, object> = {
        youtube: {event: 'command', func: 'pauseVideo', args: []},
        vimeo: {method: 'pause'},
    };

    function removeCover() {
        if (coverRef.value) {
            coverRef.value.style.opacity = '0';
            coverRef.value.addEventListener('transitionend', () => {
                displayCover.value = false;
            });
        }
    }

    function play() {
        if (iframeRef.value) {
            const message = JSON.stringify(providerPlayMessages[provider.value]);
            iframeRef.value.contentWindow?.postMessage(message, '*');
        }
    }

    function pause() {
        if (iframeRef.value) {
            const message = JSON.stringify(providerPauseMessages[provider.value]);
            iframeRef.value.contentWindow?.postMessage(message, '*');
        }
    }

    defineExpose({play, pause});

    function startVideo() {
        if (coverRef.value) {
            removeCover();
        }
        play();
    }

    onMounted(() => {});
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .video-player {
        position: relative;
        display: inline-block;
        margin: 0 auto;
        width: 100%;
        aspect-ratio: 16 / 9;
        border-radius: var(--joy-core-radius-4);
        overflow: hidden;
        iframe {
            width: 100%;
            height: 100%;
        }

        &__overlay,
        &__cover {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: opacity var(--joy-transition-duration-default) var(--joy-transition-timing-function);
        }
        &__overlay {
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(to bottom, #333430 0%, #333330 48%, #333330 80%);
            cursor: pointer;
        }
        &__cover {
            object-fit: cover;
            user-select: none;
            opacity: 0.7;
        }
        &__overlay:hover &__cover {
            opacity: 0.6;
        }
        &__title {
            position: relative;
            color: white;
            font-family: var(--joy-font-family-title);
            font-size: var(--joy-font-size-secondary-200);
            line-height: var(--joy-line-height-small);
            text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
            padding: 0 var(--joy-core-spacing-4);
            margin-bottom: var(--joy-core-spacing-4);
        }

        @include mq.screen_md(min) {
            &__title {
                padding: 0 var(--joy-core-spacing-8);
                margin-bottom: var(--joy-core-spacing-6);
            }

            /* small */
            &--small {
                max-width: 640px;
            }
            &--small &__title {
                font-size: var(--joy-font-size-secondary-200);
            }

            /* medium */
            &--medium &__title {
                font-size: var(--joy-font-size-secondary-400);
            }
            &--medium {
                max-width: 800px;
            }

            /* large */
            &--large &__title {
                font-size: var(--joy-font-size-secondary-400);
            }
            &--large {
                max-width: 1140px;
            }
        }
    }
</style>
