import {useABTestStore} from '~/stores/abtest.store';
import type {PrismicDocument} from '@prismicio/types';

export function useABTestSlices(prismicDocument: PrismicDocument) {
    const {getVariation} = useABTestStore();

    const slices = prismicDocument.data.slices.filter((slice: any) => {
        const {abTest} = slice.primary;
        if (abTest) {
            const [campaign, variation] = abTest.split('.');
            if (campaign && variation) {
                return getVariation(campaign) === variation;
            }
            return true;
        }
        return true;
    });

    return {slices};
}
