<template>
    <section class="slice slice-rich-content">
        <div class="slice-content article-content" :class="{'article-content--full-width': slice.primary.fullWidth}">
            <template v-for="(contentChunk, index) of contentChunks" :key="index">
                <div v-if="contentChunk.type === 'title'" :id="titleFieldToId(contentChunk.content)" class="title-anchor" />
                <PrismicRichText :field="contentChunk.content" />
            </template>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {useContentHelpers} from '#imports';
    import type {RichContentSlice} from '@prismicio-types';
    import {getSliceComponentProps, PrismicRichText} from '@prismicio/vue';
    import type {RichTextField, RTNode} from '@prismicio/types';

    type ContentChunk = {
        type: 'title' | 'content';
        content: RichTextField;
    };
    const props = defineProps(getSliceComponentProps<RichContentSlice>(['slice', 'index', 'slices', 'context']));

    const {titleFieldToId} = useContentHelpers();

    function isTitleRTNode(rtNode: RTNode) {
        return rtNode.type === 'heading2' || rtNode.type === 'heading3';
    }

    const contentChunks = (props.slice.primary.content as RTNode[]).reduce<ContentChunk[]>((acc: ContentChunk[], current: RTNode) => {
        if (isTitleRTNode(current)) {
            acc.push({type: 'title', content: [current]});
        } else {
            const lastChunk = acc[acc.length - 1];
            if (!lastChunk || lastChunk.type === 'title') {
                acc.push({type: isTitleRTNode(current) ? 'title' : 'content', content: [current]});
            } else {
                acc[acc.length - 1].content.push(current as never);
            }
        }
        return acc;
    }, []);
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;
    @use '~/assets/styles/variables' as *;

    $article-max-width: 840px;

    .slice-rich-content .article-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        max-width: $article-max-width;
        margin: 0 auto;

        &--full-width {
            max-width: none;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: var(--joy-font-family-title);
            line-height: var(--joy-line-height-small);
            color: var(--joy-color-neutral-60);
            margin: 0;
        }

        h1 {
            font-weight: 800;
            line-height: 97%;
            font-size: 40px;
            @include mq.screen_md(min) {
                font-size: 65px;
            }
        }
        h2 {
            font-weight: 800;
            font-size: 35px;
            @include mq.screen_md(min) {
                font-size: 40px;
            }
        }

        h3 {
            font-weight: 500;
            font-size: 28px;
            @include mq.screen_md(min) {
                font-size: 32px;
            }
        }

        h4 {
            font-weight: 500;
            font-size: 25px;
            @include mq.screen_md(min) {
                font-size: 28px;
            }
        }

        li,
        ol,
        p,
        ul {
            color: var(--joy-color-neutral-50);
        }

        /* margins */
        p,
        ol,
        ul,
        li {
            font-size: 18px;
        }

        h1,
        h2 {
            margin-bottom: var(--joy-core-spacing-8);
        }
        h3,
        h4,
        h5,
        h6,
        p,
        ul,
        ol {
            margin-bottom: var(--joy-core-spacing-7);
        }

        .block-img {
            display: flex;
            justify-content: center;
        }

        img {
            margin-bottom: var(--joy-core-spacing-14);
            max-width: 100%;
        }

        a {
            color: var(--joy-color-primary-50);
            text-decoration: underline var(--joy-color-primary-50);
            &:hover {
                color: var(--joy-color-primary-70);
                text-decoration: underline var(--joy-color-primary-70);
            }
        }

        div {
            width: 100%;
        }

        div[data-oembed-type='video'] {
            width: 100%;
            padding-top: 56.25%; // 16:9
            position: relative;
            iframe {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
            }
        }

        .title-anchor {
            position: relative;
            top: -92px;
            visibility: hidden;
            @media (max-width: $mobile-breakpoint) {
                top: -76px;
            }
        }
    }
</style>
