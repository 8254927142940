<template>
    <div v-if="displayCover" ref="coverRef" class="product-cover" @click="clickHandler">
        <PrismicImage :image="image" class="product-cover__image" />
        <div class="product-cover__overlay" />
        <div class="product-cover__content">
            <slot />
            <div v-if="title" class="product-cover__title">{{ title }}</div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {ref} from 'vue';
    import PrismicImage from '~/components/PrismicImage.vue';
    import type {ImageField} from '@prismicio/types';

    const emit = defineEmits(['click']);
    const displayCover = ref(true);
    const coverRef = ref<HTMLDivElement | null>(null);

    function clickHandler() {
        if (!coverRef.value) return;

        coverRef.value.style.opacity = '0';
        coverRef.value.addEventListener('transitionend', () => {
            console.log('transitionend');
            displayCover.value = false;
        });

        emit('click');
    }

    defineProps<{title: string; image: ImageField}>();
</script>
<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .product-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: opacity var(--ai-transition-duration-long) var(--ai-transition-timing-function);

        &__image,
        &__overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &__image {
            object-fit: cover;
        }
        &__overlay {
            background: var(--joy-color-ai-gradient-30);
            opacity: 0.6;
        }

        &__content {
            position: relative;
        }

        &__title {
            font-family: var(--joy-font-family-title);
            font-size: var(--joy-font-size-primary-600);
            color: #fff;

            p {
                color: #fff;
            }

            @include mq.screen_xs(min) {
                font-size: var(--joy-font-size-secondary-200);
            }
            @include mq.screen_sm(min) {
                font-size: var(--joy-font-size-secondary-400);
            }
            @include mq.screen_md(min) {
                font-size: var(--joy-font-size-secondary-600);
            }
        }
    }
</style>
