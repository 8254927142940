// Code generated by Slice Machine. DO NOT EDIT.
// @ts-nocheck

import {defineSliceZoneComponents} from '@prismicio/vue';

import HubspotSlice from './HubspotSlice/index.vue';

export const components = defineSliceZoneComponents({
    hubspot_slice: HubspotSlice,
});
