<template>
    <section :id="slice.primary.anchorName || undefined" class="slice" aria-label="job-categories">
        <div class="slice-content">
            <PrismicRichText v-if="title" :field="title" class="slice-title" />
            <PrismicRichText :field="description" class="slice-description" />

            <div class="slice-body job-categories">
                <Card v-for="(item, i) in items" :key="i" :item="item">
                    <template #image><PrismicImage :image="item.image" class="job-category__image" /></template>
                    <template #title>{{ item.title }}</template>
                    <PrismicRichText :field="item.text" />
                </Card>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {ref} from 'vue';
    import type {JobCategoriesSlice} from '@prismicio-types';
    import {getSliceComponentProps, PrismicRichText} from '@prismicio/vue';
    import PrismicImage from '~/components/PrismicImage.vue';
    import Card from '~/components/Card.vue';

    const props = defineProps(getSliceComponentProps<JobCategoriesSlice>(['slice', 'index', 'slices', 'context']));

    const title = ref(props.slice.primary.title);
    const description = ref(props.slice.primary.description);
    const items = ref(props.slice.items);
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .job-categories {
        max-width: 1130px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: var(--joy-core-spacing-6);

        @include mq.screen_sm(min) {
            gap: var(--joy-core-spacing-8);
        }

        .card {
            width: 100%;
            @include mq.screen_sm(min) {
                max-width: 350px;
            }
        }

        .card-image {
            margin: 0 auto;
            height: 50px;
            img {
                width: auto;
                height: 100%;
            }
        }
    }
</style>
