<template>
    <section
        v-if="!(breakpoints?.isMobile && slice.primary.hideInMobile)"
        :id="slice.primary.anchorName || undefined"
        class="slice hubspot-slice"
        :class="{'hubspot-slice--ai': theme === 'ai'}"
        aria-label="hubspot"
    >
        <div class="slice-content">
            <svg
                v-if="theme !== 'ai'"
                class="hubspot-bg-shape"
                :class="{'hubspot-bg-shape--ms': slice.primary.maltStrategyTheme}"
                width="1315"
                height="747"
                viewBox="0 0 1315 747"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path :d="pathS" class="hubspot-bg-shape__s" />
                <path :d="pathM" class="hubspot-bg-shape__m" />
                <path :d="pathL" class="hubspot-bg-shape__l" />
            </svg>
            <div class="form-container" :class="{'form-container--ready': formReady}">
                <div v-if="theme === 'ai'" class="hubspot-bg-gradient">
                    <div class="hubspot-bg-gradient__left" />
                    <div class="hubspot-bg-gradient__right" />
                </div>

                <div class="card">
                    <PrismicRichText :field="slice.primary.title" class="slice-title" />
                    <MktHubspotForm
                        :portal-id="slice.primary.hubspotPortalId + ''"
                        :form-id="slice.primary.hubspotFormId + ''"
                        :region="slice.primary.hubspotRegion + ''"
                        :salesforce-id="slice.primary.salesforceId + ''"
                        @submit="onFormSubmit"
                        @ready="formReady = true"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {ref, inject, onMounted} from 'vue';
    import {getSliceComponentProps, PrismicRichText} from '@prismicio/vue';
    import {useDataTracking, useBreakpoints} from '#imports';
    import {MktHubspotForm} from '#components';
    import type {HubspotSliceSlice} from '@prismicio-types';
    import {useMediaQuery} from '#malt/nuxt-utils-module';

    const breakpoints = useBreakpoints();

    const props = defineProps(getSliceComponentProps<HubspotSliceSlice>());
    const formId = props.slice.id?.replaceAll('$', '') || 'hubspotSlice';
    const {track, extractWebAttributionProperties} = useDataTracking();
    const pageCategory = inject('pageCategory');

    const formReady = ref(false);

    const theme = ref(props.slice.primary.theme || 'default');
    if (props.slice.primary.maltStrategyTheme) {
        theme.value = 'strategy';
    }

    function onFormSubmit(formElement: HTMLFormElement) {
        const h1 = document.querySelector('h1');
        const typeField = formElement.querySelector(`.hs-input[name=form_type]`) as HTMLInputElement;
        const eventProperties = {
            url: window.location.pathname,
            page_title_h1: h1?.innerText,
            page_category: pageCategory,
            form_type: typeField?.value || null,
            form_name: formElement.dataset.formId,
        };
        const userProperties = {
            ...extractWebAttributionProperties(),
            referrer: document.referrer || null,
        };
        track('website_form_submitted', eventProperties, userProperties);
    }

    const pathS =
        'M-63.8987 32.2517C-52.4161 -4.18935 -18.7951 -2.02382 5.52242 5.64618L235.274 78.085C259.595 85.7525 288.986 101.345 276.9 139.703C264.813 178.061 231.797 173.976 207.476 166.309L-22.2761 93.8686C-46.5935 86.2005 -75.3814 68.6909 -63.8987 32.2517Z';
    const pathM =
        'M-142.807 200.909C-138.632 124.969 -72.5995 112.559 -21.8227 115.354L457.904 141.709C508.685 144.498 573.181 160.068 568.787 240.003C564.393 319.939 498.58 328.349 447.798 325.559L-31.929 299.202C-82.7046 296.41 -146.983 276.845 -142.807 200.909Z';
    const pathL =
        'M-334.404 569.244C-353.603 393.393 -206.817 339.891 -89.2533 327.052L1021.33 205.724C1138.89 192.881 1292.76 204.169 1312.97 389.274C1333.18 574.38 1185.38 618.624 1067.82 631.466L-42.7695 752.791C-160.326 765.633 -315.204 745.091 -334.404 569.244Z';
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;
    // @import '../../scss/variables.scss';

    .hubspot-slice {
        @include flex-column-center;
        min-height: 747px;
        overflow: hidden;
        position: relative;
        .slice-title {
            margin-bottom: var(--joy-core-spacing-10);
        }
        .slice-content {
            text-align: left;
        }
        .card {
            @include flex-column-center;
            position: relative;
            background-color: white;
            width: 100%;
            max-width: 600px;
            box-sizing: border-box;
            padding: var(--joy-core-spacing-9);
            box-shadow: 0px 6px 24px rgba(24, 24, 24, 0.16);
            border-radius: var(--joy-core-radius-3);

            @include mq.screen_md(max) {
                width: 100%;
            }
        }
    }

    .hubspot-bg-shape {
        position: absolute;
        top: 0;
        left: 0;

        &__s {
            fill: var(--joy-color-primary-30);
        }
        &__m {
            fill: var(--joy-color-neutral-30);
        }
        &__l {
            fill: var(--joy-color-primary-50);
        }
    }

    /* AI Theme */
    .hubspot-slice--ai {
        .hubspot-bg-gradient {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            > div {
                position: absolute;
                top: 50%;
                width: 100%;
                aspect-ratio: 1 / 1;
            }
            &__left {
                left: -35%;
                background: radial-gradient(circle at center, rgba(#6d287b, 0.5) 0, rgba(#6d287b, 0) 60%);
                transform: translateY(-60%);
            }
            &__right {
                right: -35%;
                background: radial-gradient(circle at center, rgba(#fb2323, 0.5) 0, rgba(#fb2323, 0) 60%);
                transform: translateY(-40%);
            }
        }

        .hubspot-form {
            input[type='submit'] {
                background: var(--joy-color-ai-gradient-70);
                border: none !important;
            }
        }
    }

    /* Malt Strategy Theme */
    .form-container {
        position: relative;
        display: inline-block;
        transition: opacity var(--joy-transition-duration-default) var(--joy-transition-timing-function);
        opacity: 0;

        &--ready {
            opacity: 1;
        }
    }
    .hubspot-bg-shape--ms {
        .hubspot-bg-shape {
            &__s {
                fill: var(--joy-color-tertiary-70);
            }
            &__l {
                fill: var(--joy-color-secondary-30);
            }
        }
    }
</style>
