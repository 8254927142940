// const formatNumberWithSpaces = (num: number): string => num.toString().replace(/\B(?=(\d{3})+\b)/g, ' ');

import type {Ref} from 'vue';
import {ref} from 'vue';

export type CounterAnimation = {
    counter: Ref<string>;
    startAnimation: Function;
};

export type CounterAnimationOptions = {
    duration?: number;
    thousandsSeparator?: string;
    decimalSeparator?: string;
};

const easeOutCubic = (t: number): number => 1 - Math.pow(1 - t, 3);

export function useCounterAnimation(initialValue: number, targetValue: number, options?: CounterAnimationOptions): CounterAnimation {
    const counter = ref(`${initialValue}`);
    const duration = options?.duration || 1000;
    const isInt = targetValue % 1 === 0;
    let startTime = 0;

    const thousandsSeparator = options?.thousandsSeparator || '';
    const decimalSeparator = options?.decimalSeparator || '.';

    function format(n: number): string {
        let formattedNumber = '';
        const [wholePart, decimalPart] = n.toString().split('.');

        const pattern = /(\d+)(\d{3})/g;
        formattedNumber = wholePart.replace(pattern, `$1${thousandsSeparator}$2`);

        if (decimalPart) {
            formattedNumber += `${decimalSeparator}${decimalPart || ''}`;
        }

        return formattedNumber;
    }

    function play(timestamp: number) {
        if (startTime === 0) {
            startTime = timestamp;
        }
        const deltaTime = timestamp - startTime;
        const progress = Math.min(deltaTime / duration, 1);
        const easedProgress = easeOutCubic(progress);

        const rawValue = targetValue * easedProgress;
        let value;
        if (isInt) {
            value = Math.round(rawValue);
        } else {
            value = Math.round(rawValue * 10) / 10;
        }

        counter.value = format(value);

        if (progress < 1) {
            window.requestAnimationFrame(play);
        }
    }
    function startAnimation() {
        window.requestAnimationFrame(play);
    }

    return {counter, startAnimation};
}
