<template>
    <section v-if="!breakpoints?.isMobile" ref="sectionRef" class="slice slice-product-demo" :class="{animate}">
        <div class="slice-content">
            <div class="relative slide-in" :class="{animate}">
                <div>
                    <iframe ref="iframeRef" :src="demoLoadStarted ? frameUrl : ''" :title="frameTitle" />
                    <ProductCover :title="frameTitle" :image="slice.primary.cover">
                        <ProductSparkle class="slice-product-demo__sparkle animate" />
                    </ProductCover>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {inject, onMounted, ref} from 'vue';
    import {MEDIA_BREAKPOINTS} from '~/app/keys';
    import {getSliceComponentProps} from '@prismicio/vue';
    import type {ProductDemoSlice} from '@prismicio-types';
    import {useGsap} from '~/composables/useGsap';
    import ProductCover from '~/components/product/ProductCover.vue';
    import ProductSparkle from '~/components/product/ProductSparkle.vue';

    const breakpoints = inject(MEDIA_BREAKPOINTS);

    const props = defineProps(getSliceComponentProps<ProductDemoSlice>(['slice', 'index', 'slices', 'context']));

    const sectionRef = ref<HTMLElement>();
    const frameTitle = ref((props.slice.primary.title as string) || 'Demo');
    const frameUrl = ref(props.slice.primary.url as string);

    const animate = ref(false);
    const demoLoadStarted = ref(false);

    onMounted(() => {
        const {ScrollTrigger} = useGsap();

        ScrollTrigger.create({
            trigger: sectionRef.value,
            start: 'top bottom',
            onEnter: () => {
                animate.value = true;
                demoLoadStarted.value = true;
            },
            onLeaveBack: () => (animate.value = false),
        });
    });
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .slice-product-demo {
        iframe {
            border: 1px solid var(--joy-color-neutral-30);
            aspect-ratio: 16 / 7.5;
            width: 100%;

            @media screen and (max-height: 767px) {
                height: calc(100vh - 150px);
            }
        }

        &__sparkle {
            width: 78px;
            margin-bottom: var(--joy-core-spacing-8);

            img {
                filter: brightness(0) saturate(100%) invert(100%);
            }
        }
    }
</style>
