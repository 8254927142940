<template>
    <div ref="textRef" class="anim-text-wave">
        <span
            v-for="(letter, i) in letters"
            ref="letterRefs"
            :key="i"
            class="letter"
            :class="{dot: letter === '.'}"
            :style="{transitionDelay: `${i * 0.02}s`}"
        >
            {{ letter }}
        </span>
    </div>
</template>

<script lang="ts" setup>
    import {ref, onMounted} from 'vue';

    const props = defineProps<{text: string}>();

    const letters = ref<string[]>(props.text.replace(/ /g, '\u00A0').split(''));
    const textRef = ref<HTMLElement>();
    const letterRefs = ref<HTMLElement[]>([]);

    function animate() {
        textRef.value?.classList.add('animate');
    }
    function reset() {
        textRef.value?.classList.remove('animate');
    }

    defineExpose({animate, reset});
</script>

<style lang="scss">
    .anim-text-wave {
        text-align: center;
        position: relative;
        line-height: 1em;
        height: 1em;
        white-space: nowrap;
        overflow: hidden;
        user-select: none;

        &:not(.animate) {
            .letter {
                transform: translate3d(0, 101%, 0);
                transition: none !important;
            }
        }
        .letter {
            display: inline-block;
            position: relative;
            will-change: transform;
            backface-visibility: hidden;
        }
        .dot {
            margin-left: -0.125em;
        }
        &.animate {
            .letter {
                transition: transform var(--ai-transition-duration-short) var(--ai-transition-timing-function);
                transform: translate3d(0, 0, 0);
            }
        }
    }
</style>
