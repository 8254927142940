<template>
    <section :id="slice.primary.anchorName || undefined" ref="section" class="slice slice-hero-strategy">
        <div class="slice-content">
            <svg viewBox="0 0 1306 894" class="shape shape-desktop">
                <path :d="shapeData.s" class="shape-s" />
                <path :d="shapeData.m" class="shape-m" />
                <path :d="shapeData.l" class="shape-l" />

                <mask id="mask0_692_4414" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="552" y="-2013" width="1248" height="2783">
                    <path
                        d="M1530.52 -1996.56C1221.49 -2076.2 1110.25 -1836.34 1059.66 -1640.06L581.823 214.067C531.247 410.322 512.716 674.088 821.712 753.717C1130.74 833.365 1241.99 593.497 1292.57 397.229L1770.41 -1456.89C1820.98 -1653.15 1839.51 -1916.92 1530.52 -1996.55L1530.52 -1996.56Z"
                        fill="#D9D9D9"
                    />
                </mask>
                <g mask="url(#mask0_692_4414)">
                    <rect x="346.424" y="56.7693" width="1069.13" height="712.752" fill="url(#pattern0)" />
                    <rect x="213.965" y="-68.3303" width="621.293" height="843.108" fill="url(#paint0_linear_692_4414)" />
                </g>
                <defs>
                    <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                        <use xlink:href="#image0_692_4414" transform="scale(0.0004 0.0006)" />
                    </pattern>
                    <linearGradient
                        v-if="image.url"
                        id="paint0_linear_692_4414"
                        x1="430.559"
                        y1="507.637"
                        x2="835.258"
                        y2="507.637"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0.438273" stop-color="#CBCBCB" />
                        <stop offset="1" stop-color="#CBCBCB" stop-opacity="0" />
                    </linearGradient>
                    <image id="image0_692_4414" width="2500" height="1667" :href="(image.url as string)" />
                </defs>
            </svg>
            <svg viewBox="0 0 390 185" class="shape shape-mobile">
                <path
                    d="M-69.7704 -224.037C-37.7441 -262.812 -1.34752 -242.529 23.2799 -222.19L255.914 -30.0366C280.537 -9.69829 307.329 22.2171 275.302 60.9857C243.278 99.7599 206.881 79.4799 182.254 59.1386L-50.38 -133.014C-75.0031 -153.352 -101.794 -185.266 -69.7704 -224.037Z"
                    fill="#00C0DA"
                />
                <path
                    d="M151.394 -360.434C205.316 -382.653 231.507 -342.049 245.618 -307.801L378.918 15.7201C393.025 49.9629 403.038 97.2348 349.124 119.451C295.203 141.668 269.01 101.063 254.9 66.8158L121.601 -256.704C107.492 -290.947 97.4781 -338.22 151.394 -360.434Z"
                    fill="#F7F7F5"
                />
                <path
                    d="M513.65 -442.688C583.752 -428.651 582.825 -369.423 573.908 -324.898L489.668 95.6946C480.752 140.213 458.793 195.23 388.7 181.192C318.597 167.151 319.523 107.925 328.442 63.4019L412.68 -357.192C421.598 -401.712 443.556 -456.728 513.65 -442.688Z"
                    fill="#0F6378"
                />
            </svg>

            <div class="hero-content">
                <PrismicImage v-if="logo" :image="logo" class="hero-logo" />
                <PrismicRichText v-if="title" :field="title" class="slice-title" />
                <PrismicRichText v-if="description" :field="description" class="slice-description" />
                <div v-if="primaryCtaUrl || secondaryCtaUrl || primaryCtaLink.url || secondaryCtaLink.url" class="hero-buttons">
                    <VJoyButton
                        v-if="primaryCtaUrl || primaryCtaLink.url"
                        :href="primaryCtaUrl || primaryCtaLink.url"
                        data-source-component="hero-strategy"
                    >
                        {{ primaryCtaLabel }}
                    </VJoyButton>
                    <VJoyButton
                        v-if="secondaryCtaUrl || secondaryCtaLink.url"
                        :href="secondaryCtaUrl || secondaryCtaLink.url"
                        variant="secondary"
                        data-source-component="hero-strategy"
                    >
                        {{ secondaryCtaLabel }}
                    </VJoyButton>
                </div>
            </div>

            <div v-if="slice.primary.hubspotFormId" class="slice-hero-strategy__form" :class="{'slice-hero-strategy__form--visible': isFormReady}">
                <div class="slice-hero-strategy__form__card">
                    <div v-if="slice.primary.hubspotFormTitle" class="slice-hero-strategy__form__title">
                        {{ slice.primary.hubspotFormTitle }}
                    </div>
                    <HubspotForm
                        :region="slice.primary.hubspotRegion!"
                        :portal-id="slice.primary.hubspotPortalId!"
                        :form-id="slice.primary.hubspotFormId!"
                        :salesforce-id="slice.primary.salesforceId!"
                        @ready="isFormReady = true"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {ref} from 'vue';
    import {VJoyButton} from '@maltjoy/core-vue';
    import {getSliceComponentProps, PrismicRichText} from '@prismicio/vue';
    import type {HeroStrategySlice} from '@prismicio-types';
    import type {FilledLinkToWebField} from '@prismicio/types';
    import PrismicImage from '~/components/PrismicImage.vue';
    import HubspotForm from '~/components/HubspotForm.vue';
    import {useComponentTracking} from '#imports';

    const props = defineProps(getSliceComponentProps<HeroStrategySlice>(['slice', 'index', 'slices', 'context']));

    const title = ref(props.slice.primary.title);
    const description = ref(props.slice.primary.description);
    const logo = ref(props.slice.primary.logo);
    const image = ref(props.slice.primary.image);
    const primaryCtaLink = ref(props.slice.primary.primaryCtaLink as FilledLinkToWebField);
    const primaryCtaUrl = ref(props.slice.primary.primaryCtaUrl);
    const primaryCtaLabel = ref(props.slice.primary.primaryCtaLabel);
    const secondaryCtaLink = ref(props.slice.primary.secondaryCtaLink as FilledLinkToWebField);
    const secondaryCtaUrl = ref(props.slice.primary.secondaryCtaUrl);
    const secondaryCtaLabel = ref(props.slice.primary.secondaryCtaLabel);
    const section = ref(null);

    const shapeData = {
        s: 'M577.459 244.719C653.265 205.876 632.986 138.28 608.318 90.1309L375.268 -364.704C350.599 -412.848 307.575 -468.793 231.773 -429.954C155.967 -391.111 176.246 -323.515 200.914 -275.366L433.959 179.472C458.627 227.617 501.652 283.562 577.453 244.723L577.459 244.719Z',
        m: 'M647.768 338.081C765.708 330.376 777.116 233.118 772.223 158.21L725.984 -549.401C721.089 -624.3 697.114 -719.248 579.188 -711.54C461.248 -703.835 449.84 -606.577 454.734 -531.673L500.973 175.937C505.868 250.837 529.843 345.784 647.769 338.077L647.768 338.081Z',
        l: 'M1530.52 -1996.56C1221.49 -2076.2 1110.25 -1836.34 1059.66 -1640.06L581.823 214.067C531.247 410.322 512.716 674.088 821.712 753.717C1130.74 833.365 1241.99 593.497 1292.57 397.229L1770.41 -1456.89C1820.98 -1653.15 1839.51 -1916.92 1530.52 -1996.55L1530.52 -1996.56Z',
    };

    const isFormReady = ref(false);
    useComponentTracking(section);
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .slice-hero-strategy {
        overflow: hidden;

        @include mq.screen_md(min) {
            min-height: 58vw;
        }
        @include mq.screen_xlg(min) {
            min-height: auto;
        }
        .shape {
            overflow: visible;
            position: absolute;
            z-index: -1;

            &-mobile {
                right: 0;
                width: 60%;
                top: 0;
                @include mq.screen_md(min) {
                    display: none;
                }
            }
            &-desktop {
                right: -10%;
                width: 93%;
                top: -6vh;

                @include mq.screen_md(min) {
                    display: block;
                }

                display: none;
                @media screen and (min-width: 1400px) {
                    right: -1%;
                    top: -58px;
                }
            }
            &-s {
                fill: var(--joy-color-secondary-30);
            }
            &-m {
                fill: var(--joy-color-neutral-20);
            }
            &-l {
                fill: var(--joy-color-secondary-50);
            }
        }

        .slice {
            &-content {
                position: relative;
                @include mq.screen_md(min) {
                    display: flex;
                }
            }
            &-title {
                font-size: var(--joy-font-size-secondary-400);
                strong {
                    background-color: var(--joy-color-neutral-30);
                }
                @include mq.screen_md(min) {
                    font-size: var(--joy-font-size-secondary-800);
                }
                @include mq.screen_lg(min) {
                    font-size: 56px;
                }
            }
            &-description {
                margin: var(--joy-core-spacing-8) 0 0;
                font-size: var(--joy-font-size-primary-400);
                line-height: var(--joy-line-height-small);
                padding: 0;
                max-width: 600px;

                @include mq.screen_sm(min) {
                    padding: 0;
                }
                @include mq.screen_md(min) {
                    width: auto;
                }
            }
        }

        .hero {
            &-content {
                text-align: left;
                padding: var(--joy-core-spacing-8) 0 0;

                @include mq.screen_xs(min) {
                    padding-right: 20%;
                }
                @include mq.screen_md(min) {
                    width: 560px;
                    padding-right: 0;
                }
                @media screen and (min-width: 1400px) {
                    padding: 120px var(--joy-core-spacing-8);
                }
            }
            &-logo {
                width: 150px;
                margin-bottom: var(--joy-core-spacing-4);
                @include mq.screen_md(min) {
                    width: 250px;
                    margin-bottom: var(--joy-core-spacing-8);
                }
            }
            &-buttons {
                margin-top: var(--joy-core-spacing-8);
                > a {
                    width: 100%;
                    margin-bottom: var(--joy-core-spacing-4);
                }
                @include mq.screen_sm(min) {
                    display: flex;
                    gap: var(--joy-core-spacing-6);
                    > a {
                        width: auto;
                    }
                }
                @include mq.screen_md(min) {
                    width: 125%;
                }
            }
        }

        &__form {
            display: block;
            margin-top: var(--joy-core-spacing-12);

            @include mq.screen_md(min) {
                text-align: center;
                top: 0;
                right: 0;
                margin: O auto;
                height: auto;
                padding: 0 var(--joy-core-spacing-9);
                width: calc(100% - 560px);
                opacity: 0;
                transition: opacity var(--joy-transition-duration-default) var(--joy-transition-timing-function);
            }

            &__title {
                font-size: var(--joy-font-size-primary-800);
                font-weight: var(--joy-font-weight-bold);
                margin-bottom: var(--joy-core-spacing-2);
            }
            &__card {
                display: inline-block;
                background: white;
                width: 100%;
                min-width: 40px;
                max-width: 550px;
                border-radius: var(--joy-core-radius-4);
                padding: var(--joy-core-spacing-6) var(--joy-core-spacing-5);
                box-shadow: 0px var(--joy-core-spacing-1) var(--joy-core-spacing-5) 0px rgba(51, 51, 48, 0.2);
                padding: var(--joy-core-spacing-8) var(--joy-core-spacing-6);
            }
            &--visible {
                opacity: 1;
            }
        }
    }
</style>
