<template>
    <section v-if="!(breakpoints?.isMobile && slice.primary.hideInMobile)" ref="section" class="slice slice-hero hero-section" aria-label="hero">
        <div v-if="slice.variation === 'heroWithShapes'" class="hero-with-shapes-wrapper">
            <HeroWithShapes
                :title="slice.primary.title"
                :cta-optional-link="slice.primary.CTAOptionalLink"
                :cta-optional-link-text="slice.primary.CTAOptionalLinkText"
                :background-shapes="slice.primary.backgroundShapes"
            />
        </div>

        <div
            v-else
            :class="{
                'hero-without-shapes': true,
                'hero-with-search': slice.variation === 'heroWithSearch',
            }"
        >
            <div class="slice-content text-left hero-wrapper">
                <div class="hero-without-search" :class="{'hero-with-form': slice.variation === 'heroWithForm'}">
                    <div class="hero-content">
                        <Breadscrumbs v-if="breadcrumbElements.length > 0" :breadcrumbs="breadcrumbElements" />
                        <PrismicImage v-if="slice.primary.logo" :image="slice.primary.logo" class="hero-logo" />
                        <PrismicRichText :field="slice.primary.title" class="title" />
                        <div class="hero-details">
                            <PrismicRichText :field="slice.primary.description" class="description" />
                            <div v-if="slice.variation !== 'heroWithSearch'" class="ctas">
                                <VJoyButton
                                    v-if="isFilledKeyText(slice.primary.CTARequiredLinkText) && isFilledKeyText(slice.primary.CTARequiredLink)"
                                    :href="slice.primary.CTARequiredLink"
                                    variant="primary"
                                    data-source-component="hero"
                                >
                                    {{ slice.primary.CTARequiredLinkText }}
                                </VJoyButton>
                                <VJoyButton
                                    v-if="isFilledKeyText(slice.primary.CTAOptionalLinkText) && isFilledKeyText(slice.primary.CTAOptionalLink)"
                                    :href="slice.primary.CTAOptionalLink"
                                    variant="secondary"
                                    data-source-component="hero"
                                >
                                    {{ slice.primary.CTAOptionalLinkText }}
                                </VJoyButton>
                            </div>
                        </div>
                    </div>
                    <div v-if="slice.variation !== 'heroWithForm' && !breakpoints?.isMobile" class="image">
                        <Diaporama
                            v-if="[slice.primary.image, slice.primary.image2, slice.primary.image3].filter((image) => image && image.url).length > 1"
                            :interval="7000"
                            :images="[slice.primary.image, slice.primary.image2, slice.primary.image3].filter((image) => image && image.url)"
                            :sizes="imageSizes"
                        />
                        <PrismicImage v-else :image="slice.primary.image" :sizes="imageSizes" />
                    </div>
                    <div v-if="slice.variation === 'heroWithForm'" class="card-form">
                        <HubspotForm
                            :region="slice.primary.hubspotRegion!"
                            :portal-id="slice.primary.hubspotPortalId!"
                            :form-id="slice.primary.hubspotFormId!"
                            :salesforce-id="slice.primary.salesforceId!"
                        />
                    </div>
                </div>
                <div v-if="slice.variation === 'heroWithSearch'" class="search">
                    <SearchPanel
                        v-if="cmsSearchPanelV2"
                        :search-tab="slice.primary.searchLinkText"
                        :search-field-category="slice.primary.categoryLabel"
                        :search-field-location="slice.primary.locationLabel"
                        :search-location-remote-country-label="slice.primary.remoteInCountryLabel"
                        :search-submit="slice.primary.searchLinkText"
                        :search-submit-link="slice.primary.searchLink"
                        :ai-search-tab="slice.primary.aiSearchLinkText"
                        :ai-search-tab-link="slice.primary.aiSearchLink"
                        :project-tab="slice.primary.submitProjectLinkText"
                        :project-tab-link="slice.primary.submitProjectLink"
                        :reassurance-items="slice.items"
                        brief-origin="home_unlogged"
                    />
                    <FreelanceSearch
                        v-else
                        :category-label="slice.primary.categoryLabel"
                        :location-label="slice.primary.locationLabel"
                        :search-link-text="slice.primary.searchLinkText"
                        :search-link="slice.primary.searchLink"
                        :submit-project-link-text="slice.primary.submitProjectLinkText"
                        :submit-project-link="slice.primary.submitProjectLink"
                        :ai-search-link-text="slice.primary.aiSearchLinkText"
                        :ai-search-link="slice.primary.aiSearchLink"
                        :reassurance-items="slice.items"
                        :between-ctas-text="slice.primary.betweenCTAsText"
                        :missing-category-error-text="slice.primary.missingCategoryErrorText"
                        :trustpilot-data-locale="slice.primary.trustpilotDataLocale"
                        :trustpilot-data-template-id="slice.primary.trustpilotDataTemplateId"
                        :trustpilot-data-businessunit-id="slice.primary.trustpilotDataBusinessunitId"
                        :trustpilot-link="slice.primary.trustpilotLink"
                        :hide-trustpilot="slice.primary.hideTrustpilot"
                        :remote-in-country-label="slice.primary.remoteInCountryLabel"
                        heap-prefix="hero-with-search"
                        brief-origin="home_unlogged"
                    />
                </div>
            </div>
        </div>
        <div class="slice-content reinsurance-wrapper">
            <ReinsurancePanel
                v-if="slice.variation === 'heroWithSearch' && cmsSearchPanelV2"
                :items="slice.items"
                :trustpilot-data-locale="`${slice.primary.trustpilotDataLocale}`"
                :trustpilot-data-template-id="`${slice.primary.trustpilotDataTemplateId}`"
                :trustpilot-data-businessunit-id="`${slice.primary.trustpilotDataBusinessunitId}`"
                :trustpilot-link="`${slice.primary.trustpilotLink}`"
                :hide-trustpilot="slice.primary.hideTrustpilot"
            />
        </div>
    </section>
</template>

<script setup lang="ts">
    import {inject, onMounted, ref} from 'vue';
    import {VJoyButton} from '@maltjoy/core-vue';
    import {getSliceComponentProps, PrismicRichText} from '@prismicio/vue';
    import {MEDIA_BREAKPOINTS} from '~/app/keys';
    import Breadscrumbs from '~/components/Breadscrumbs.vue';
    import Diaporama from '~/components/Diaporama.vue';
    import FreelanceSearch from '~/components/FreelanceSearch.vue';
    import SearchPanel from '@search/components/SearchPanel.vue';
    import ReinsurancePanel from '~/components/ReinsurancePanel.vue';
    import PrismicImage from '~/components/PrismicImage.vue';
    import HeroWithShapes from '~/components/HeroWithShapes.vue';
    import HubspotForm from '~/components/HubspotForm.vue';
    import useErrorHelpers from '~/composables/useErrorHelpers';
    import {usePrismicTypeGuards, usePrismicFieldHelpers, useComponentTracking} from '#imports';
    import {useFeatureFlagsStore} from '~/stores/feature-flags.store';
    import type {HeroSliceSlice, HeroSliceSliceDefaultItem} from '@prismicio-types';

    const {features} = useFeatureFlagsStore();
    const cmsSearchPanelV2 = features['cms-search-panel-v2'];

    const breakpoints = inject(MEDIA_BREAKPOINTS);

    const props = defineProps(getSliceComponentProps<HeroSliceSlice>());
    const {isLink} = usePrismicFieldHelpers();
    const {isFilledKeyText} = usePrismicTypeGuards();
    const section = ref(null);

    const breadcrumbElements =
        props.slice.variation !== 'default'
            ? []
            : props.slice.items.reduce((cleanedLinks: HeroSliceSliceDefaultItem[], link: HeroSliceSliceDefaultItem) => {
                  if (isLink(link.breadscrumbText, link.breadscrumbLink)) {
                      return [...cleanedLinks, link];
                  }
                  return cleanedLinks;
              }, []);

    const imageSizes = 'mobile:536px tablet:636px';

    onMounted(() => {
        const {getPreviewErrorHandler, throwIfPrimaryPropertiesAreInvalid} = useErrorHelpers();
        const errorHandler = getPreviewErrorHandler();
        if (errorHandler.enabled) {
            if (props.slice.variation === 'heroWithSearch') {
                throwIfPrimaryPropertiesAreInvalid(
                    errorHandler.toast,
                    props.slice.primary,
                    [
                        {name: 'title', type: 'field'},
                        {name: 'description', type: 'field'},
                    ],
                    'hero search',
                );

                return;
            }

            if (props.slice.variation === 'default') {
                throwIfPrimaryPropertiesAreInvalid(
                    errorHandler.toast,
                    props.slice.primary,
                    [
                        {name: 'title', type: 'field'},
                        {name: 'description', type: 'field'},
                        {name: 'CTARequiredLink', type: 'rawText'},
                        {name: 'CTARequiredLinkText', type: 'rawText'},
                    ],
                    'hero',
                );
            }
        }
    });

    useComponentTracking(section);
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    $flex-gap: 48px;

    .hero-section {
        .hero-without-shapes {
            background-image: url('~/assets/svg/home-page-hero-shapes.svg');
            background-repeat: no-repeat;
            background-position: top right;
            background-size: contain;
            padding-top: 16px;
            @include flex-column-justify-center;
            justify-content: flex-start;
            align-items: center;
            @include mq.screen_sm(max) {
                background-image: url('~/assets/svg/home-page-hero-mobile-shapes.svg');
                padding-top: 21px;
            }
            position: relative;

            .hero-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
            }
            .hero-without-search {
                width: 100%;
                display: flex;
                align-items: center;
                gap: $flex-gap;
            }

            .hero-content {
                width: 50%;
                @include mq.screen_sm(max) {
                    width: 100%;
                }
            }

            .image {
                width: 50%;
                height: auto;
                img {
                    width: 100%;
                    height: auto;
                    border-radius: var(--joy-core-radius-4);
                    vertical-align: middle;
                }
                @include mq.screen_sm(max) {
                    display: none;
                }
            }

            .hero-with-form {
                flex-direction: column;
                gap: 44px;

                @include mq.screen_md(min) {
                    flex-direction: row;
                    gap: $flex-gap;
                }

                .card-form {
                    flex: none;
                    background: white;
                    width: 100%;
                    min-width: 40px;
                    max-width: 550px;
                    margin: O auto;
                    border-radius: var(--joy-core-radius-4);
                    padding: var(--joy-core-spacing-6) var(--joy-core-spacing-5);
                    box-shadow: 0px var(--joy-core-spacing-1) var(--joy-core-spacing-5) 0px rgba(51, 51, 48, 0.2);
                    height: auto;

                    @include mq.screen_md(min) {
                        width: calc(50% - $flex-gap);
                        padding: var(--joy-core-spacing-8) var(--joy-core-spacing-6);
                    }
                }
            }

            .search {
                margin-top: 48px;
                width: 100%;
                .search-card {
                    width: 100%;
                }
            }

            .title h1 {
                @include primary-title;
                margin-bottom: 30px;
                @include mq.screen_sm(max) {
                    margin-bottom: 24px;
                }
            }
            .description {
                font-weight: var(--joy-font-weight-normal);
                font-size: var(--joy-font-size-primary-600);
                line-height: var(--joy-line-height-large);
                color: var(--joy-color-neutral-50);

                a:hover {
                    text-decoration: underline;
                }

                a {
                    color: var(--joy-color-secondary-30);
                    @include mq.screen_sm() {
                        color: inherit;
                        text-decoration: underline;
                    }
                }
            }

            .ctas {
                display: flex;
                gap: 20px;
                flex-wrap: wrap;
                margin-top: 48px;
                .joy-button {
                    width: 100%;
                    @include mq.screen_sm(min) {
                        width: auto;
                    }
                }
            }
        }
        .hero-with-search {
            @include mq.screen_sm(max) {
                background-image: url('~/assets/svg/hero-with-search-shapes.svg');
                background-position: bottom center;
                background-size: cover;
                color: white;
                .title h1,
                .hero-details * {
                    color: white;
                }
            }
        }

        .hero-logo {
            max-width: 150px;
            height: auto;
            margin-bottom: var(--joy-core-spacing-4);
            @include mq.screen_md(min) {
                max-width: 250px;
                margin-bottom: var(--joy-core-spacing-8);
            }
        }
        .reinsurance-wrapper {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
</style>
