<template>
    <section class="slice">
        <div class="slice-content">
            <WidgetTrustpilot v-bind="widgetProps" />
        </div>
    </section>
</template>

<script setup lang="ts">
    import {ref} from 'vue';
    import WidgetTrustpilot from '~/components/widget/Trustpilot.vue';
    import {getSliceComponentProps} from '@prismicio/vue';
    import type {TrustpilotSlice} from '@prismicio-types';

    const props = defineProps(getSliceComponentProps<TrustpilotSlice>(['slice', 'index', 'slices', 'context']));

    const widgetProps = ref({
        locale: props.slice.primary.locale as string,
        templateId: props.slice.primary.templateId as string,
        businessunitId: props.slice.primary.businessunitId as string,
        link: props.slice.primary.link as string,
    });
</script>
