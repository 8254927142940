<template>
    <a v-if="shouldShow()" :href="href()"><slot></slot></a>
</template>

<script setup lang="ts">
    import type {KeyTextField, LinkField} from '@prismicio/types';
    import {linkResolver, usePrismicTypeGuards, usePrismicFieldHelpers} from '#imports';

    const {isLink} = usePrismicFieldHelpers();

    const {isFilledWebLink, isFilledKeyText, isFilledContentRelationshipField} = usePrismicTypeGuards();

    const props = defineProps<{
        field: LinkField | KeyTextField;
    }>();

    function shouldShow(): boolean {
        return isFilledKeyText(props.field) || isLink('_', props.field);
    }

    function href(): string {
        const field = props.field;
        if (isFilledKeyText(field)) {
            return field;
        }
        if (field && isFilledWebLink(field)) {
            return field.url;
        }
        if (field && isFilledContentRelationshipField(field)) {
            return linkResolver(field);
        }
        return '';
    }
</script>
