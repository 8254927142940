<template>
    <div class="card">
        <div class="card-image">
            <slot name="image" />
        </div>
        <div class="card-title">
            <slot name="title" />
        </div>
        <div class="card-body">
            <slot />
        </div>
        <div class="card-footer">
            <slot name="footer" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .card {
        display: flex;
        flex-direction: column;
        max-width: 450px;
        background: white;
        border-radius: var(--joy-core-radius-4);
        padding: var(--joy-core-spacing-6) var(--joy-core-spacing-5);
        box-shadow: 0px var(--joy-core-spacing-1) var(--joy-core-spacing-5) 0px rgba(51, 51, 48, 0.2);
        @include mq.screen_lg(min) {
            padding: var(--joy-core-spacing-8) var(--joy-core-spacing-6);
        }
        &-image {
            img {
                height: 55px;
                @include mq.screen_lg(min) {
                    height: 78px;
                }
            }
        }
        &-title {
            font-weight: var(--joy-font-weight-bold);
            color: var(--joy-color-secondary-30);
            margin-top: var(--joy-core-spacing-3);
            font-size: var(--joy-font-size-primary-600);
            @include mq.screen_lg(min) {
                margin-top: var(--joy-core-spacing-4);
                font-size: var(--joy-font-size-primary-800);
            }
        }
        &-body {
            margin-top: var(--joy-core-spacing-4);
        }
        &-footer {
            margin-top: auto;
        }
    }
</style>
