import {defu} from 'defu';
import type {ComponentPublicInstance, Ref} from 'vue';
import {onBeforeUnmount, onMounted, readonly, ref} from 'vue';

interface UseIntersectionObserverOptions {
    /** IntersectionObserver options */
    options?: IntersectionObserverInit;
    /**
     * Start observing at mount
     * @default true
     */
    immediate?: boolean;
}

/**
 * Composable that wrap the intersection observer with reactive properties
 *
 * @param witnessRef - The element to observe
 * @param callback - Callback called when the element is observed
 * @param options - composable options {@link UseIntersectionObserverOptions}
 *
 * @usages
 *
 * ```vue
 *  <template>
 *      <div class='my-long-list'>
 *         <div ref='target'>Visible: {{isIntersecting}}</div>
 *      </div>
 *  </template>
 *
 * <script setup lang="ts">
 *   const target = ref<HtmlDivElement>();
 *   const {isIntersecting} = useIntersectionObserver(target);
 *
 *  // Or with options
 *
 *  const {startObserving, stopObserving} = useIntersectionObserver(target, (entries) => {
 *      if (entries[0].isIntersecting) {
 *         // do stuff
 *      }
 *  },
 *  {immediate: false});
 * </script>
 * ```
 */
export function useIntersectionObserver(
    witnessRef: Ref<HTMLElement | ComponentPublicInstance<any, any, any, any, any, any, any> | undefined | null>,
    callback?: IntersectionObserverCallback,
    options?: UseIntersectionObserverOptions,
) {
    const resolvedOptions = defu(options, {
        options: {
            rootMargin: '0px',
            threshold: 0,
        },
        immediate: true,
    });

    let intersectionObserver: IntersectionObserver;
    const target = ref<HTMLElement>();
    const isIntersecting = ref(false);

    function startObserving() {
        if (witnessRef.value) {
            if (witnessRef?.value) {
                if (witnessRef.value instanceof HTMLElement) {
                    target.value = witnessRef.value;
                } else if (!Array.isArray(witnessRef.value.$el)) {
                    target.value = witnessRef.value.$el as HTMLElement;
                }
            }
            if (target.value) {
                intersectionObserver.observe(target.value);
            }
        } else {
            /* eslint-disable no-console */
            console.warn('[useIntersectionObserver] No target to observe');
        }
    }

    function stopObserving() {
        if (target.value) {
            intersectionObserver.unobserve(target.value);
        }
    }

    if (import.meta.client) {
        intersectionObserver = new IntersectionObserver((entries, observer) => {
            isIntersecting.value = entries.some((e) => e.intersectionRatio > 0);
            callback?.(entries, observer);
        }, resolvedOptions.options);

        onMounted(() => {
            if (resolvedOptions.immediate) {
                startObserving();
            }
        });

        onBeforeUnmount(() => {
            if (witnessRef.value) {
                stopObserving();
                intersectionObserver?.disconnect();
            }
        });
    }

    return {
        startObserving,
        stopObserving,
        isIntersecting: readonly(isIntersecting),
    };
}
