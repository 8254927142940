<template>
    <div :id="elemId" class="hubspot-form" />
</template>

<script lang="ts" setup>
    import {inject, onMounted} from 'vue';
    import {useDataTracking} from '#imports';

    const props = defineProps<{
        portalId: string;
        formId: string;
        region: string;
        salesforceId: string;
    }>();

    const {track, extractWebAttributionProperties} = useDataTracking();

    const pageCategory = inject('pageCategory');
    const elemId = `hubspot-form-${props.formId}`;

    const emit = defineEmits(['submit', 'ready']);

    function onFormReady() {
        emit('ready');
    }

    function initForm() {
        // @ts-expect-error
        window.hbspt.forms.create({
            region: props.region,
            portalId: props.portalId,
            formId: props.formId,
            css: '',
            sfdcCampaignId: props.salesforceId,
            target: `#${elemId}`,
            onFormReady,
            onFormSubmit: function ($form: HTMLFormElement) {
                const h1 = document.querySelector('h1');
                const typeField = $form.querySelector(`.hs-input[name=form_type]`) as HTMLInputElement;
                const eventProperties = {
                    url: window.location.pathname,
                    page_title_h1: h1?.innerText,
                    page_category: pageCategory,
                    form_type: typeField.value,
                    form_name: $form.dataset.formId,
                };
                const userProperties = {
                    ...extractWebAttributionProperties(),
                    referrer: document.referrer || null,
                };
                track('website_form_submitted', eventProperties, userProperties);
            },
        });
    }

    onMounted(() => {
        // @ts-expect-error
        if (window.hbspt) {
            initForm();
        } else {
            const script = document.createElement('script');
            script.src = 'https://js.hsforms.net/forms/v2.js';
            document.head.appendChild(script);
            script.addEventListener('load', () => {
                initForm();
            });
        }
    });
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .hubspot-form {
        min-height: 500px;
        overflow: hidden;
        position: relative;

        form {
            font-family: var(--joy-font-family);
            margin: 0 -10px;
            font-size: var(--joy-font-size-primary-400);
            line-height: var(--joy-line-height-large);
            ul {
                list-style: none;
            }
            fieldset {
                max-width: unset;
            }
            ul[role='checkbox'] {
                margin-top: var(--joy-core-spacing-3);
                > li > label {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    input {
                        width: auto;
                    }
                }
            }
            .hs-form-field {
                //form input fields
                display: flex;
                flex-direction: column;
                padding: 0 10px;
                margin: 0px 0 24px;
                legend {
                    display: none !important;
                }
                .hs-error-msgs {
                    .hs-error-msg {
                        color: var(--joy-color-primary-50);
                        margin-top: var(--joy-core-spacing-3);
                        margin-bottom: 0;
                    }
                    label:not(.hs-error-msg) {
                        * {
                            color: var(--joy-color-secondary-30);
                        }
                    }
                }
                label {
                    color: var(--joy-color-neutral-50);
                    font-size: var(--joy-font-size-primary-400);
                    margin-bottom: var(--joy-core-spacing-3);
                }
                .input {
                    margin: 0;
                }
            }

            [class^='form-columns-'] {
                @include mq.screen_md {
                    //force input to be full width on mobile
                    .hs-form-field {
                        width: 100% !important;
                    }
                }
            }
            input:not([type='submit']),
            select,
            textarea {
                box-sizing: border-box;

                &:not([type='checkbox']) {
                    border-radius: 50px;
                    padding: 12px 20px;
                    border: 2px solid var(--joy-color-neutral-30);
                    color: var(--joy-color-neutral-60);

                    &:focus {
                        outline: none;
                        border: 2px solid var(--joy-color-secondary-50);
                    }

                    &:hover {
                        outline: none;
                        border: 2px solid var(--joy-color-state-hover);
                    }

                    &::placeholder {
                        color: var(--joy-color-neutral-50);
                        overflow: visible;
                    }
                }

                width: 100% !important; //even in raw html form input are limited to 95%
                background-color: transparent;
                &[type='text'] {
                    padding: var(--joy-core-spacing-3) 20px;
                }
                &[type='radio'] {
                    width: 15px !important;
                    height: 15px;
                    position: relative;
                    margin-right: var(--joy-core-spacing-3);
                    &::before {
                        position: absolute;
                        content: '';
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        border-radius: 15px;
                        border: 2px solid var(--joy-color-neutral-30);
                        color: white;
                        background-color: white;
                        transition: background-color var(--joy-transition-duration-default) var(--joy-transition-timing-function);
                        @include flex-row-center;
                    }
                    &:checked {
                        &::before {
                            content: '';
                            background-color: var(--joy-color-secondary-30);
                        }
                    }
                }
                &[type='checkbox'] {
                    height: 22px;
                    width: 22px !important; //even in raw html width is forced to auto
                    border-radius: var(--joy-core-radius-1);
                    position: relative;
                    flex-shrink: 0;
                    cursor: pointer;
                    &::before {
                        position: absolute;
                        content: '';
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        border-radius: var(--joy-core-radius-1);
                        border: 2px solid var(--joy-color-neutral-30);
                        color: white;
                        background-color: white;
                        transition: background-color var(--joy-transition-duration-default) var(--joy-transition-timing-function);
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                    }
                    &:checked {
                        &::before {
                            border: 2px solid var(--joy-color-secondary-30);

                            content: '\2714';
                            background-color: var(--joy-color-secondary-30);
                        }
                    }
                }
            }
            input:not([type='submit']),
            select {
                height: 48px;
            }
            textarea {
                padding: var(--joy-core-spacing-3) 20px;
                border-radius: var(--joy-core-radius-3) !important;
            }
            select {
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                background-image: url('~/assets/svg/chevron-down-grey.svg');
                --background-position: center right 20px;
                background-position: var(--background-position);
                background-repeat: no-repeat;
                padding-right: 60px !important;
                &:focus {
                    background-image: url('~/assets/svg/chevron-down.svg');
                }
                * {
                    padding: var(--joy-core-spacing-0) 0px;
                    font-family: var(--joy-font-family);
                }
            }
            .legal-consent-container {
                //legal checkboxes and disclaimers
                margin-bottom: 24px;
                padding: 0 10px;
                font-size: var(--joy-font-size-primary-300);
                label {
                    display: flex;
                    align-items: center;
                    color: var(--joy-color-neutral-60);
                    font-size: var(--joy-font-size-primary-300);

                    a {
                        color: var(--joy-color-neutral-60);
                        text-decoration: underline var(--joy-color-neutral-60);
                    }
                    > span {
                        margin-left: 10px;
                    }
                }
                .hs-form-field {
                    padding: 0;
                }
            }
            .hs_error_rollup {
                padding: 0 10px;
                color: var(--joy-color-primary-50);
            }
            .actions {
                @include flex-row-center;
                margin-top: 24px;
                padding: 0 10px;
            }
            input[type='submit'] {
                padding: 14px 30px;
                border: 2px solid black;
                font-weight: 400;
                font-size: var(--joy-font-size-primary-400);
                border-radius: 30px;
                line-height: var(--joy-line-height-large);
                text-align: center;
                background-color: var(--joy-color-secondary-30);
                border: 2px solid var(--joy-color-secondary-30);
                transition: all var(--joy-transition-duration-default) var(--joy-transition-timing-function);

                &:hover {
                    background-color: var(--joy-color-secondary-50);
                    border: 2px solid var(--joy-color-secondary-50);
                }

                color: white;

                cursor: pointer;
                @include mq.screen_md {
                    width: 100%;
                }
            }
        }
    }

    .hubspot-bg-shape {
        position: absolute;
        top: 0;
        left: 0;

        &__s {
            fill: var(--joy-color-primary-30);
        }
        &__m {
            fill: var(--joy-color-neutral-30);
        }
        &__l {
            fill: var(--joy-color-primary-50);
        }
    }

    /* Malt Strategy Theme */
    .hubspot-bg-shape--ms {
        .hubspot-bg-shape {
            &__s {
                fill: var(--joy-color-tertiary-70);
            }
            &__l {
                fill: var(--joy-color-secondary-30);
            }
        }
    }
</style>
