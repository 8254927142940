<template>
    <section class="slice slice-demo-popup">
        <div class="slice-content text-left">
            <div class="slice-demo-popup__inner" :class="{'slice-demo-popup__inner--reverse': slice.primary.reverse}">
                <div class="slice-demo-popup__text">
                    <div>
                        <div class="slice-demo-popup__tag">{{ slice.primary.tag }}</div>
                        <PrismicRichText v-if="slice.primary.title" :field="slice.primary.title" class="slice-title" />
                        <PrismicRichText v-if="slice.primary.description" :field="slice.primary.description" />
                        <div class="slice-demo-popup__cta">
                            <!-- {{ slice.primary.ctaLink }} -->
                            <NuxtLink :to="`${slice.primary.ctaLink}`">
                                {{ slice.primary.ctaLabel }}
                            </NuxtLink>
                        </div>
                    </div>
                </div>
                <div class="slice-demo-popup__media" prevent-body-scroll @click="showDialog()">
                    <PrismicImage class="slice-demo-popup__image" :image="slice.primary.coverImage" :imgx-params="{w: 1200}" />
                    <div class="slice-demo-popup__cover">
                        <div class="slice-demo-popup__overlay" :class="overlayClass" />
                        <div class="slice-demo-popup__cover__content">
                            <MaltImg width="80" :src="`/cms-front/demo-click.svg?vh=dc6686`" :alt="`${slice.primary.coverText}`" />
                            <p>{{ slice.primary.coverText }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <VJoyDialog ref="dialog" class="slice-demo-popup__dialog">
        <template #dialog-body>
            <iframe :src="`${slice.primary.demoUrl}`" frameborder="0" class="slice-demo-popup__iframe" :onload="onDemoLoaded" />
            <div v-if="!isDemoLoaded" class="slice-demo-popup__dialog__loader">
                <VJoySpinner />
            </div>
        </template>
    </VJoyDialog>
</template>

<script setup lang="ts">
    import {ref, computed} from 'vue';
    import {VJoyDialog, VJoySpinner} from '@maltjoy/core-vue';
    import {MaltImg} from '#components';
    import {getSliceComponentProps, PrismicRichText} from '@prismicio/vue';
    import type {DemoPopupSlice} from '@prismicio-types';
    import PrismicImage from '~/components/PrismicImage.vue';

    const {slice} = defineProps(getSliceComponentProps<DemoPopupSlice>(['slice', 'index', 'slices', 'context']));

    const dialog = ref<InstanceType<typeof VJoyDialog>>();
    const overlayClass = computed(() => {
        const coverColor = slice.primary.coverColor;
        const color = coverColor === 'gradient' ? 'ai-gradient' : coverColor;
        return `slice-demo-popup__overlay--${color}`;
    });
    const isDemoLoaded = ref(false);

    function onDemoLoaded() {
        setTimeout(() => (isDemoLoaded.value = true), 1000);
    }

    async function showDialog() {
        isDemoLoaded.value = false;
        await dialog.value?.show();
    }
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .slice-demo-popup {
        .slice-content {
            @include mq.screen_lg(min) {
                padding-top: 120px;
                padding-bottom: 120px;
            }
        }
        .slice-title {
            line-height: var(--joy-line-height-small);
            margin-bottom: var(--joy-core-spacing-3);
        }

        &__inner {
            display: flex;
            flex-direction: column-reverse;
            position: relative;
            text-align: left;
            gap: var(--joy-core-spacing-8);
            @include mq.screen_sm(min) {
                flex-direction: row;
                align-items: center;
                gap: 0;

                &--reverse {
                    flex-direction: row-reverse;
                }

                > div {
                    width: 50%;
                }
            }
        }

        &__cta {
            margin-top: var(--joy-core-spacing-4);
            a {
                color: var(--joy-color-primary-50);
                font-weight: var(--joy-font-weight-bold);

                &:hover {
                    text-decoration: underline;
                    text-underline-offset: 2px;
                }
            }
        }

        /* TEXT */
        &__text {
            padding-left: 0;
            padding-right: var(--joy-core-spacing-8);

            @include mq.screen_sm(min) {
                .slice-demo-popup__inner--reverse & {
                    padding-left: var(--joy-core-spacing-8);
                    padding-right: 0;
                }
            }
            @include mq.screen_lg(min) {
                padding-right: var(--joy-core-spacing-12);

                .slice-demo-popup__inner--reverse & {
                    padding-left: var(--joy-core-spacing-12);
                }
            }
        }

        /* MEDIA */
        &__media {
            position: relative;
            border-radius: var(--joy-core-radius-4);
            overflow: hidden;
            box-shadow: var(--joy-core-elevation-3);
        }

        /* COVER */
        &__cover,
        &__overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        &__cover {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            &__content {
                pointer-events: none;
                position: relative;
                color: white;
                max-width: 400px;
                font-weight: bold;
                font-size: var(--joy-font-size-primary-800);
                p {
                    color: white;
                    font-family: var(--joy-font-family-title);
                    margin-top: var(--joy-core-spacing-4);
                }
            }
        }
        &__overlay {
            cursor: pointer;
            transition: opacity var(--joy-transition-duration-default) var(--joy-transition-timing-function);
            opacity: 0.85;

            &--red {
                background-color: var(--joy-color-primary-50);
            }
            &--ai-gradient {
                background-image: var(--joy-color-ai-gradient-30);
            }
            &--blue {
                background-color: var(--joy-color-brand-secondary-30);
            }

            &:hover {
                opacity: 0.9;
            }
        }
        &__image {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: blur(3px);
        }

        /* CONTENT */
        &__tag {
            font-size: var(--joy-font-size-primary-200);
            font-weight: var(--joy-font-weight-bold);
            color: var(--joy-color-neutral-40);
            font-family: var(--joy-font-family);
            letter-spacing: -0.2px;
            text-transform: uppercase;
            user-select: none;
            margin-bottom: var(--joy-core-spacing-5);
        }

        /* DIALOG */
        &__dialog {
            &__loader {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                .joy-spinner {
                    --spinner-size: 56px;
                }
            }
            .joy-dialog__overlay {
                background-color: rgba(0, 0, 0, 0.8);
            }
            .joy-dialog__inner {
                width: auto;
                padding: 0;
            }
            .joy-dialog--header {
                margin-bottom: var(--joy-core-spacing-9);
                @include mq.screen_sm(min) {
                    display: none;
                }
            }
            .joy-dialog--body {
                max-width: none;
                max-height: none;
            }
            iframe {
                display: block;
                width: calc(100vw - 40px);
                max-width: 1400px;
                max-height: calc(100vh - 40px);
                aspect-ratio: 16 / 9;
            }
        }
    }
</style>
