<template>
    <section
        v-if="!(breakpoints?.isMobile && slice.primary.hideInMobile)"
        ref="section"
        :class="{
            'benefits-slice-section': true,
            'no-background-variation': (slice.variation === 'noBackground' || breakpoints?.isWide) && !breakpoints?.isMobile,
        }"
        aria-label="benefits"
    >
        <div class="headline">
            <PrismicRichText :field="slice.primary.title" class="title" />
            <PrismicRichText :field="slice.primary.subtitle" class="subtitle" />
        </div>
        <!-- repeating v-if is a fix to a rendering issue with the swiper component. -->
        <div v-if="breakpoints?.isMobile">
            <template v-if="hasObjectField(slice.primary.image)">
                <div class="illustration">
                    <PrismicImage :image="slice.primary.image" sizes="mobile:324px wide:660px" class="main-image" />
                    <div class="icons">
                        <div
                            v-for="(benefit, benefitIndex) in slice.items"
                            :key="`slice-icon-${benefitIndex}`"
                            :class="{'icon-container': true, selected: selectedBenefit === benefitIndex}"
                        >
                            <PrismicImage :image="benefit.icon" class="icon" height="70" width="70" />
                        </div>
                    </div>
                </div>
                <NuxtErrorBoundary>
                    <Swiper :modules="modules" :slides-per-view="1" :loop="slice.items.length > 1" :space-between="20" @slide-change="onSlideChange">
                        <SwiperSlide v-for="(item, i) in slice.items" :key="`slice-item-${i}`">
                            <PrismicRichText :field="item.benefitTitle" class="benefit-title" />
                            <PrismicRichText :field="item.benefit" class="benefit-text" />
                        </SwiperSlide>
                        <div class="link-container">
                            <ServerSideNavigationPrismicLink
                                v-if="isFilledKeyText(slice.primary.linkText) && isFilledKeyText(slice.primary.link)"
                                :field="slice.primary.link"
                                class="link"
                                data-source-component="benefits"
                            >
                                {{ slice.primary.linkText }}
                            </ServerSideNavigationPrismicLink>
                        </div>
                        <SliderControls />
                    </Swiper>
                </NuxtErrorBoundary>
            </template>
            <NuxtErrorBoundary v-else>
                <Swiper
                    :modules="modules"
                    :lazy="true"
                    :preload-images="false"
                    :slides-per-view="1"
                    :loop="slice.items.length > 1"
                    :space-between="20"
                >
                    <SwiperSlide v-for="(item, i) in slice.items" :key="`slice-item-${i}`">
                        <PrismicImage :key="`slice-illustration-${i}`" :image="item.illustration" sizes="small:324" />
                        <PrismicRichText :field="item.benefitTitle" class="benefit-title" />
                        <PrismicRichText :field="item.benefit" class="benefit-text" />
                    </SwiperSlide>
                    <div class="link-container">
                        <ServerSideNavigationPrismicLink
                            v-if="isFilledKeyText(slice.primary.linkText) && isFilledKeyText(slice.primary.link)"
                            :field="slice.primary.link"
                            class="link"
                            data-source-component="benefits"
                        >
                            {{ slice.primary.linkText }}
                        </ServerSideNavigationPrismicLink>
                    </div>
                    <SliderControls />
                </Swiper>
            </NuxtErrorBoundary>
        </div>

        <div v-else class="slice-content">
            <div class="benefits">
                <div class="benefits-container">
                    <button
                        v-for="(item, index) in slice.items"
                        :key="`slice-item-${index}`"
                        :class="{benefit: true, selected: isSelected(index)}"
                        :aria-label="item.benefitButtonLabel || `show benefit ${index + 1}`"
                        @mouseenter="setSelectedBenefit(index)"
                    >
                        <div class="benefit-container">
                            <PrismicRichText :field="item.benefitTitle" class="benefit-title" />
                            <PrismicRichText :field="item.benefit" />
                        </div>
                    </button>
                </div>
                <ServerSideNavigationPrismicLink
                    v-if="isFilledKeyText(slice.primary.linkText) && isFilledKeyText(slice.primary.link)"
                    :field="slice.primary.link"
                    class="link"
                    data-source-component="benefits"
                >
                    {{ slice.primary.linkText }}
                </ServerSideNavigationPrismicLink>
            </div>
            <div v-if="hasObjectField(slice.primary.image)" class="illustration">
                <PrismicImage :image="slice.primary.image" sizes="mobile:324px wide:660px" class="main-image" />
                <div class="icons">
                    <div
                        v-for="(benefit, benefitIndex) in slice.items"
                        :key="`slice-icon-${benefitIndex}`"
                        :class="{'icon-container': true, selected: selectedBenefit === benefitIndex}"
                    >
                        <PrismicImage :image="benefit.icon" class="icon" height="70" width="70" />
                    </div>
                </div>
            </div>
            <div v-else class="illustrations">
                <PrismicImage
                    v-for="(benefit, benefitIndex) in slice.items"
                    :key="`slice-illustration-${benefitIndex}`"
                    :image="benefit.illustration"
                    :class="{'current-img': selectedBenefit === benefitIndex}"
                    sizes="mobile:324px wide:660px"
                />
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {getSliceComponentProps, PrismicRichText} from '@prismicio/vue';
    import {inject, ref, onMounted} from 'vue';
    // @ts-ignore
    import type SwiperType from 'swiper';
    // @ts-ignore
    import {Lazy} from 'swiper';
    import {Swiper, SwiperSlide} from 'swiper/vue';
    import PrismicImage from '~/components/PrismicImage.vue';
    import ServerSideNavigationPrismicLink from '~/components/ServerSideNavigationPrismicLink.vue';
    import {MEDIA_BREAKPOINTS} from '~/app/keys';
    import SliderControls from '~/components/SliderControls.vue';
    import {usePrismicTypeGuards, usePrismicFieldHelpers, useComponentTracking} from '#imports';
    import useErrorHelpers from '~/composables/useErrorHelpers';
    import type {BenefitsSliceSlice} from '@prismicio-types';
    const breakpoints = inject(MEDIA_BREAKPOINTS);
    const props = defineProps(getSliceComponentProps<BenefitsSliceSlice>());

    const selectedBenefit = ref(0);
    const {isFilledKeyText} = usePrismicTypeGuards();
    const {hasObjectField} = usePrismicFieldHelpers();
    const modules = [Lazy];
    const section = ref(null);

    onMounted(() => {
        const {throwIfItemsCountIsOutOfBound, throwIfMissingItemsProperties, getPreviewErrorHandler} = useErrorHelpers();
        const errorHandler = getPreviewErrorHandler();
        if (errorHandler.enabled) {
            const itemsCount = props.slice.items.length;
            throwIfItemsCountIsOutOfBound(errorHandler.toast, itemsCount, 'benefits', 2, 5);
            throwIfMissingItemsProperties(errorHandler.toast, props.slice.items, [{name: 'illustration', type: 'image'}], 'benefit');
        }
    });
    function setSelectedBenefit(benefitIndex: number) {
        selectedBenefit.value = benefitIndex;
    }
    function isSelected(benefitIndex: number) {
        return selectedBenefit.value === benefitIndex;
    }
    function onSlideChange(swipe: SwiperType) {
        selectedBenefit.value = swipe.realIndex;
    }

    useComponentTracking(section);
</script>

<style lang="scss">
    .benefits-slice-section {
        @include flex-column-justify-center;
        background-image: url('~/assets/svg/benefits-background.svg');
        background-position: left;
        background-repeat: no-repeat;
        background-size: auto 100%;
        --background-position: center right calc(100vw - 1290px);
        background-position: var(--background-position);
        min-height: 830px;
        font-family: $font-family;

        //we don't want the rounded part to be cut off, if the image change adjust this breakpoint to the new image width
        @media (max-width: 1290px) {
            background-position: right;
        }

        padding: 88px 0;
        @media (max-width: $mobile-breakpoint) {
            min-height: unset;
            padding: $margin-section-mobile;
            background-image: none;
        }
        .title h2 {
            @include secondary-title;
            margin-bottom: 24px;
        }
        .subtitle {
            margin-bottom: 24px;
            max-width: calc(50% - 90px);
            line-height: var(--joy-line-height-large);
            font-size: var(--joy-font-size-primary-600);
            @media (max-width: $mobile-breakpoint) {
                max-width: 100%;
            }
        }
        .headline {
            margin-bottom: 36px;
            @include content-width;

            @media (max-width: $mobile-breakpoint) {
                margin-bottom: var(--joy-core-spacing-3);
            }
        }
        .display-image-on-the-left {
            flex-direction: row-reverse;
        }
        .slice-content {
            @include content-width;
            @include flex-row-align-center;
            justify-content: space-between;
        }
        .link-container {
            display: flex;
        }
        .link {
            @include link;
            margin-top: 60px;
            @media (max-width: $mobile-breakpoint) {
                margin-top: 0;
                margin-bottom: 32px;
            }
        }
        .benefits {
            text-align: left;
            font-family: $font-family;
            width: calc(50% - 20px);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        .benefits-container {
            display: flex;
            flex-direction: column;
            gap: 24px;
            padding-right: 70px;
            width: 100%;
        }
        .benefit {
            color: var(--joy-color-neutral-50);
        }

        .benefit.selected {
            background-color: $color-white;
            color: var(--joy-color-neutral-60);

            .benefit-title h3 {
                color: var(--joy-color-neutral-60);
            }

            .benefit.selected .benefit-title h3 strong {
                color: var(--joy-color-neutral-60);
            }
        }

        .illustrations {
            align-self: stretch;
            @include flex-column-justify-center;
            align-items: flex-end;
            width: calc(min(calc(50% + 20px), 660px));
            position: relative;

            img {
                position: absolute;
                right: 0;
                height: auto;
                width: 100%;
                transition: opacity var(--joy-transition-duration-default) var(--joy-transition-timing-function);
                object-fit: contain;
                object-position: center;
                border-radius: var(--joy-core-radius-4);
                opacity: 0;
                &.current-img {
                    opacity: 1;
                }
            }
        }

        .illustration {
            align-self: stretch;
            @include flex-column-justify-center;
            align-items: flex-end;
            width: calc(min(calc(50% + 20px), 660px));
            @media (max-width: $mobile-breakpoint) {
                width: calc(100% - var(--joy-core-spacing-4));
                margin-bottom: var(--joy-core-spacing-4);
            }
            .main-image {
                width: calc(100% - var(--joy-core-radius-4));
                margin-left: var(--joy-core-spacing-4);
                border-radius: var(--joy-core-radius-3);
                height: auto;
            }
            .icons {
                display: flex;
                align-items: center;
                width: 100%;
                gap: var(--joy-core-spacing-8);
                transform: translateY(-50%);
                .icon-container {
                    height: 54px;
                    width: 54px;
                    background-color: var(--joy-color-neutral-10);
                    border-radius: var(--joy-core-radius-2);
                    box-shadow: var(--joy-core-elevation-1);
                    transition: all var(--joy-transition-duration-default) var(--joy-transition-timing-function);
                    opacity: 0.7;
                    &.selected {
                        transform: scale(1.66);
                        opacity: 1;
                    }
                    .icon {
                        margin: 6px;
                        width: 42px;
                        height: 42px;
                        object-fit: contain;
                    }
                }
            }
        }

        .benefit {
            border-width: 0;
            border-radius: var(--joy-core-radius-4);
            background-color: unset;
            padding: 20px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 22px;
            p {
                font-size: var(--joy-font-size-primary-400);
                line-height: var(--joy-line-height-large);
            }
        }

        .benefit-container {
            text-align: left;
            @include flex-column-start;
            font-weight: $font-weight-regular;
            font-size: var(--joy-font-size-primary-400);
        }

        .section.display-image-on-the-left img {
            left: $out-of-screen-width;
            float: left;
        }

        .benefit-title h3 {
            @include tertiary-title;
            font-size: var(--joy-font-size-primary-800);
            font-weight: var(--joy-font-weight-bold);
            margin-bottom: 20px;
            @media (max-width: $mobile-breakpoint) {
                margin-bottom: 10px;
            }
        }

        .benefit-title h3 strong {
            font-weight: var(--joy-font-weight-bold);
        }

        .icon {
            width: 27px;
            height: auto;
        }

        &.no-background-variation {
            background-image: none;
            .benefits-container {
                margin-left: 70px;
            }
            .slice-content {
                flex-direction: row-reverse;
            }
            .benefits-container {
                margin-right: 0;
            }
            .benefit.selected {
                background-color: var(--joy-color-secondary-10);
                .benefit-title h3 {
                    color: var(--joy-color-secondary-30);
                }
            }
            .link {
                margin-left: 70px;
                @media (max-width: $mobile-breakpoint) {
                    margin-left: 0;
                }
            }
            img {
                background-position: left center;
            }
            .subtitle {
                max-width: 100%;
            }
        }
        .swiper {
            @include swiper;
            .swiper-slide {
                display: flex;
                flex-direction: column;
                img {
                    width: 100%;
                    object-fit: contain;
                    margin-bottom: 32px;
                    max-height: 214px;
                    object-position: center;
                    border-radius: var(--joy-core-radius-4);
                }
                .benefit-text {
                    font-size: var(--joy-font-size-primary-400);
                    color: var(--joy-color-neutral-50);
                    line-height: var(--joy-line-height-large);
                }
                margin-bottom: 32px;
            }
        }
        .arrows {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 16px;
        }
    }
</style>
