<template>
    <section ref="sectionRef" class="slice slice-product-hero" :class="{animate}">
        <div class="slice-content slice-product-hero__content">
            <h1 class="slice-product-hero__title">
                <span class="slide-in">
                    <span>{{ title1 }}</span>
                </span>
                <span v-if="title2" class="slide-in">
                    <span>{{ title2 }}</span>
                </span>
                <strong v-if="subtitle" class="slide-in">
                    <span>
                        {{ subtitle }}
                        <ProductSparkle :class="{animate: sparkleAnimate}" />
                    </span>
                </strong>
            </h1>
            <PrismicRichText :field="slice.primary.description" class="slice-product-hero__description slide-in" />
        </div>
    </section>
</template>

<script setup lang="ts">
    import {ref, onMounted} from 'vue';
    import {getSliceComponentProps, PrismicRichText} from '@prismicio/vue';
    import type {ProductHeroSlice} from '@prismicio-types';
    import ProductSparkle from '~/components/product/ProductSparkle.vue';

    const props = defineProps(getSliceComponentProps<ProductHeroSlice>(['slice', 'index', 'slices', 'context']));

    const animate = ref(false);
    const sparkleAnimate = ref(false);
    const sectionRef = ref<HTMLElement>();

    const splitTitle = ref(props.slice.primary.title?.split('\n', 2) || []);
    const title1 = ref(splitTitle.value[0] || '');
    const title2 = ref(splitTitle.value[1] || '');
    const subtitle = ref(props.slice.primary.gradientText);

    function playAnimation() {
        const baseDelay = 0.5;
        const staggerDelay = 0.125;

        const items = sectionRef.value?.querySelectorAll('.slide-in');
        items?.forEach((el, i) => {
            (el.firstChild as HTMLElement).style.transitionDelay = baseDelay + i * staggerDelay + 's';
        });
        animate.value = true;

        setTimeout(() => {
            sparkleAnimate.value = true;
        }, 1000);
    }

    onMounted(() => {
        playAnimation();
    });
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    $ref-width: 1920;

    $sparkle-x: calc(10 / $ref-width) * 100vw;
    $sparkle-y: calc(28 / $ref-width) * 100vw;
    $sparkle-width-value: 70;
    $sparkle-width-max: $sparkle-width-value * 1px;
    $sparkle-width: calc($sparkle-width-value / $ref-width) * 100vw;

    $title-y-value: 313;
    $title-y: calc($title-y-value / $ref-width) * 100vw;

    $title-font-size-value: 130;
    $title-font-size-max: $title-font-size-value * 1px;
    $title-font-size: calc($title-font-size-value / $ref-width) * 100vw;

    $subtitle-font-size-value: 100;
    $subtitle-font-size-max: $subtitle-font-size-value * 1px;
    $subtitle-font-size: calc($subtitle-font-size-value / $ref-width) * 100vw;

    $subtitle-padding-left-value: 75;
    $subtitle-padding-left-max: $subtitle-padding-left-value * 1px;
    $subtitle-padding-left: calc($subtitle-padding-left-value / $ref-width) * 100vw + $sparkle-x;

    .slice-product-hero {
        text-align: center;
        -webkit-font-smoothing: antialiased; /* For WebKit browsers (Chrome, Safari) */
        -moz-osx-font-smoothing: grayscale;
        padding-top: clamp(110px, $title-y, $title-y);

        &__content {
            position: relative;
            text-align: center;
            padding-top: 0;
            padding-bottom: calc(48px - 40px);

            @include mq.screen_lg(min) {
                padding-bottom: calc(75px - 64px);
            }
        }

        &__title {
            font-family: var(--joy-font-family-title);
            font-size: clamp(36px, $title-font-size, $title-font-size-max);
            color: var(--joy-color-text-title);
            text-align: center;
            margin-bottom: 0;
            user-select: none;

            > span {
                line-height: 1;

                > span {
                    display: inline-block;
                }
            }
            strong {
                line-height: 1;
                font-family: var(--joy-font-family-title);
                font-size: clamp(26px, $subtitle-font-size, $subtitle-font-size-max);

                > span {
                    display: inline-block;
                    background-image: var(--joy-color-ai-gradient-30);
                    background-clip: text;
                    color: transparent;
                }
            }
        }

        &__description {
            display: inline-flex;
            font-size: 14px;
            line-height: 1.15;
            text-align: center;
            margin: 0 auto;
            margin-top: 48px;
            max-width: 550px;

            @include mq.screen_sm(min) {
                font-size: 24px;
                line-height: 28px;
                max-width: 940px;
                padding: 0 60px;
            }

            @include mq.screen_md(min) {
                max-width: 1080px;
                padding: 0 100px;
                margin-top: 75px;
                font-size: 28px;
                line-height: 38px;
            }

            @include mq.screen_xlg(min) {
                font-size: 32px;
                line-height: 44px;
            }
        }

        .product-sparkle {
            position: absolute;
            bottom: 0;
            margin-left: clamp(4px, $sparkle-x, 10px);
            width: clamp(10px, $sparkle-width, $sparkle-width-max);
            height: auto;
        }
    }
</style>
