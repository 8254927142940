<template>
    <section v-if="comparisonTable.title1" :id="slice.primary.anchorName || undefined" class="slice slice-comparison">
        <div class="slice-content">
            <PrismicRichText v-if="slice.primary.title" :field="slice.primary.title" class="slice-title" />
            <div class="comparison-table">
                <div class="comparison-row">
                    <NuxtErrorBoundary>
                        <Swiper
                            :navigation="true"
                            :pagination="{
                                clickable: true,
                            }"
                            :loop="false"
                            :slides-per-view="1"
                            :modules="[Pagination]"
                            :breakpoints="swiperBreakpoints"
                            class="swiper comparison-swiper"
                            @slide-change="onSwipe"
                        >
                            <SwiperSlide>
                                <div class="comparison-header">
                                    <div class="comparison-title">{{ comparisonTable.title1 }}</div>
                                    <PrismicRichText :field="comparisonTable.description1" class="comparison-description" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div class="comparison-header">
                                    <div class="comparison-title">{{ comparisonTable.title2 }}</div>
                                    <PrismicRichText :field="comparisonTable.description2" class="comparison-description" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div class="comparison-header">
                                    <div class="comparison-title">{{ comparisonTable.title3 }}</div>
                                    <PrismicRichText :field="comparisonTable.description3" class="comparison-description" />
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </NuxtErrorBoundary>
                </div>
                <div v-for="(section, i) in comparisonTable.slices" :key="i" class="comparison-section" :aria-expanded="i === 0">
                    <div class="comparison-section-header" @click="toggle">
                        {{ section.primary.title }}
                        <VJoyIcon name="chevron-down" color="white" />
                    </div>
                    <div class="comparison-section-body" :style="`height: ${i === 0 ? 'auto' : '0'}`">
                        <div
                            v-for="(row, i) in section.items"
                            :key="i"
                            class="comparison-row"
                            :style="{transform: `translateX(-${currentIndex * 100}%)`}"
                        >
                            <PrismicRichText :field="row.feature" class="comparison-feature" />
                            <div
                                v-for="(value, i) in [row.plan1, row.plan2, row.plan3]"
                                :key="i"
                                class="comparison-value"
                                :data-label="row.feature[0] && row.feature[0].text"
                            >
                                <template v-if="value">
                                    <VJoyIcon v-if="value.includes('[v]')" name="check" class="comparison-icon comparison-icon-check" />
                                    <VJoyIcon v-else-if="value.includes('[x]')" name="cross" class="comparison-icon comparison-icon-cross" />
                                    <div>{{ sanitize(value) }}</div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PrismicRichText :field="slice.primary.footerText" class="footer-text" />
        </div>
    </section>
</template>

<script setup lang="ts">
    import {useAsyncData} from 'nuxt/app';
    import {VJoyIcon} from '@maltjoy/core-vue';
    import {ref} from 'vue';
    import type {ComparisonSlice} from '@prismicio-types';
    import {Swiper, SwiperSlide} from 'swiper/vue';
    // @ts-ignore
    import type SwiperType from 'swiper';
    // @ts-ignore
    import {Pagination} from 'swiper';
    import {getSliceComponentProps, PrismicRichText} from '@prismicio/vue';
    import {usePrismicDocument} from '~/composables/content/usePrismicDocument';
    import {usePrismicTypeGuards} from '#imports';
    import type {PrismicDocument} from '@prismicio/types';
    import 'swiper/css';

    const props = defineProps(getSliceComponentProps<ComparisonSlice>(['slice', 'index', 'slices', 'context']));

    const {isFilledContentRelationshipField} = usePrismicTypeGuards();
    const comparisonTable = ref();
    const currentIndex = ref(0);
    const swiperBreakpoints = {
        0: {slidesPerView: 1},
        1200: {slidesPerView: 3},
    };

    if (isFilledContentRelationshipField(props.slice.primary.comparisonTable)) {
        const {fetchDocument} = usePrismicDocument('comparisonTable', props.slice.primary.comparisonTable.uid as string);
        const {data} = await useAsyncData<PrismicDocument>('comparison-table', () => fetchDocument());
        comparisonTable.value = data.value!.data;
    }

    function sanitize(content: string): string {
        return content ? content.replaceAll(/\[(v|x)\]/g, '') : '';
    }
    function toggle(e: Event) {
        const el = (e.target as HTMLElement).nextSibling as HTMLElement;
        el.removeEventListener('transitionend', afterExpand);
        const ariaExpanded = el.parentElement?.ariaExpanded === 'true';
        ariaExpanded ? collapse(el) : expand(el);
    }
    function expand(el: HTMLElement) {
        el.addEventListener('transitionend', afterExpand);
        el.style.height = `${el.scrollHeight}px`;
        el.parentElement!.ariaExpanded = 'true';
    }
    function collapse(el: HTMLElement) {
        el.style.height = `${el.scrollHeight}px`;
        setTimeout(() => {
            el.style.height = '0px';
            el.parentElement!.ariaExpanded = 'false';
        }, 1);
    }
    const afterExpand = (e: TransitionEvent) => {
        const el = e.target as HTMLElement;
        el.style.height = 'auto';
        el.removeEventListener('transitionend', afterExpand);
    };
    function onSwipe(swiper: SwiperType) {
        currentIndex.value = swiper.activeIndex;
    }
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .slice-comparison {
        .slice-title {
            margin-bottom: var(--joy-core-spacing-8);
            @include mq.screen_lg(min) {
                margin-bottom: var(--joy-core-spacing-10);
            }
        }
        .footer-text {
            text-align: left;
            margin-top: var(--joy-core-spacing-8);
        }
        .comparison {
            &-table {
                text-align: left;
                padding: 0 var(--joy-core-spacing-4);
            }
            &-row {
                display: flex;
                align-items: stretch;
                transition: all 0.3s ease;
                &:nth-child(even) {
                    background-color: var(--joy-color-secondary-10);
                }
            }
            &-header {
                border: 1px solid var(--joy-color-secondary-30);
                border-bottom: 0;
                border-radius: var(--joy-core-radius-3) var(--joy-core-radius-3) 0 0;
                padding: var(--joy-core-spacing-5) var(--joy-core-spacing-8) var(--joy-core-spacing-8);
                @include mq.screen_lg(min) {
                    padding: var(--joy-core-spacing-5) var(--joy-core-spacing-8);
                }
            }
            &-title {
                font-family: var(--joy-font-family-title);
                font-size: var(--joy-font-size-secondary-200);
                color: var(--joy-color-secondary-30);
            }
            &-description {
                strong {
                    font-family: var(--joy-font-family-title);
                    font-size: var(--joy-font-size-secondary-400);
                    font-weight: var(--joy-font-weight-bold);
                }
            }
            &-feature {
                flex: 1 1 0%;
                display: none;
                @include mq.screen_lg(min) {
                    display: block;
                    padding: var(--joy-core-spacing-4);
                    border-right: 1px solid var(--joy-color-secondary-30);
                }
            }
            &-value {
                padding: var(--joy-core-spacing-4);
                width: 100%;
                flex: none;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: var(--joy-core-spacing-3);

                @include mq.screen_lg(min) {
                    justify-content: center;
                    width: 300px;
                    border-right: 1px solid var(--joy-color-secondary-30);
                }
                &:last-child {
                    border-right: 0;
                }
                &:after {
                    content: attr(data-label);
                    position: relative;
                    font-size: var(--joy-font-size-primary-400);
                    @include mq.screen_lg(min) {
                        content: none;
                    }
                }
                > div {
                    order: 3;
                    font-weight: var(--joy-font-weight-bold);
                    @include mq.screen_lg(min) {
                        font-weight: var(--joy-font-weight-normal);
                    }
                }
            }
            &-icon {
                flex: none;
                width: 16px;
                height: 16px;
                background-repeat: no-repeat;
                display: inline-block;

                &-cross {
                    color: var(--joy-color-neutral-50);
                    opacity: 0.4;
                }
            }
        }

        /* section */
        .comparison-section {
            &-header {
                user-select: none;
                cursor: pointer;
                display: flex;
                align-items: baseline;
                justify-content: space-between;
                margin: 0 calc(-1 * var(--joy-core-spacing-4));
                border-radius: var(--joy-core-radius-4);
                background-color: var(--joy-color-secondary-30);
                font-weight: var(--joy-font-weight-bold);
                color: #fff;
                font-size: var(--joy-font-size-primary-600);
                padding: var(--joy-core-spacing-4) var(--joy-core-spacing-5);

                @include mq.screen_lg(min) {
                    padding: var(--joy-core-spacing-5) var(--joy-core-spacing-8);
                    font-size: var(--joy-font-size-primary-800);
                    justify-content: flex-start;
                    gap: var(--joy-core-spacing-8);
                }

                .joy-icon {
                    position: relative;
                    top: 3px;
                    display: inline-block;
                    vertical-align: baseline;
                    pointer-events: none;
                    transition: transform 0.3s ease;
                }
            }
            &[aria-expanded='true'] .comparison-section-header .joy-icon {
                transform: rotateX(-180deg);
            }
            &-body {
                cursor: pointer;
                border: 1px solid var(--joy-color-secondary-30);
                border-bottom-color: white;
                border-top: 0;
                overflow: hidden;
                transition: height 0.3s ease;
                will-change: height;
                backface-visibility: hidden;
            }

            &:last-child .comparison-section-body {
                position: relative;
                top: -1px;
                border-bottom-color: var(--joy-color-secondary-30);
            }
        }
    }

    /* swiper */
    .comparison-swiper {
        width: 100%;
        @include mq.screen_lg(min) {
            width: auto;
            margin-right: 0 !important;
        }
        .swiper-wrapper {
            display: flex;
        }
        .swiper-slide {
            width: 100% !important;
            @include mq.screen_lg(min) {
                width: 300px !important;
                &:not(:first-child) .comparison-header {
                    border-left: 0;
                }
            }
        }
        .swiper-pagination-bullet-active {
            background-color: var(--joy-color-secondary-30);
        }
    }
</style>
