import {cookie} from '@prismicio/client';
import {useCookie} from '#imports';

function isPreview() {
    return !!useCookie(cookie.preview).value;
}

export default function usePreviewHelper() {
    return {
        isPreview,
    };
}
