<template>
    <section :id="slice.primary.anchorName || undefined" ref="section" class="slice slice-pricing">
        <div class="slice-content">
            <PrismicRichText v-if="slice.primary.title" :field="slice.primary.title" class="slice-title" />
            <div class="slice-body">
                <NuxtErrorBoundary>
                    <Swiper class="swiper-pricing" :breakpoints="swiperBreakpoints">
                        <SwiperSlide v-for="(pricingPlan, i) in slice.items" :key="i">
                            <PricingCard :pricing-plan="pricingPlan" />
                        </SwiperSlide>
                    </Swiper>
                </NuxtErrorBoundary>
            </div>
            <div v-if="slice.primary.footerLinkUrl" class="footer-link">
                <NuxtLink :to="slice.primary.footerLinkUrl" class="slice-footer__link">
                    {{ slice.primary.footerLinkLabel }}
                </NuxtLink>
            </div>
            <div v-if="slice.primary.footerText" class="slice-footer">
                <PrismicRichText :field="slice.primary.footerText" />
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {ref} from 'vue';
    import type {PricingSlice} from '@prismicio-types';
    import {Swiper, SwiperSlide} from 'swiper/vue';
    import PricingCard from './PricingCard.vue';
    import {getSliceComponentProps, PrismicRichText} from '@prismicio/vue';
    import {useComponentTracking} from '#imports';

    defineProps(getSliceComponentProps<PricingSlice>(['slice', 'index', 'slices', 'context']));

    const section = ref(null);
    const swiperBreakpoints = {
        0: {slidesPerView: 1.15},
        768: {slidesPerView: 2.25},
        1024: {slidesPerView: 3},
    };

    useComponentTracking(section);
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;
    .slice-pricing {
        .swiper-pricing {
            margin: 0 calc(-1 * var(--joy-core-spacing-4));
            .swiper-wrapper {
                align-items: stretch;
            }
            .swiper-slide {
                min-width: 350px;
                width: 450px;
                height: auto;
                padding: var(--joy-core-spacing-4);
            }
            @include mq.screen_lg(min) {
                .swiper-wrapper {
                    flex-wrap: wrap;
                    justify-content: center;
                }
            }
        }
        .pricing-plan {
            max-width: none;
            height: 100%;
        }
        .footer-link {
            margin-top: var(--joy-core-spacing-8);
            a,
            a:visited {
                color: var(--joy-color-secondary-30);
                text-decoration: underline;
                font-weight: var(--joy-font-weight-bold);
            }
        }
        .slice-footer {
            text-align: left;
        }
    }
</style>
