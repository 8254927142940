<template>
    <section class="slice slice-profiles">
        <div class="slice-content">
            <PrismicRichText :field="slice.primary.title" class="slice-title" />
            <PrismicRichText :field="slice.primary.description" class="slice-description" />
            <div class="slice-body">
                <ProfilesCarousel v-if="profiles?.length" :items="profiles" />
            </div>
            <div v-if="slice.primary.cta?.url" class="text-center joy-mt-8">
                <VJoyButton :href="slice.primary.cta.url" :target="slice.primary.cta.target" variant="main">{{ slice.primary.cta.text }}</VJoyButton>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {ref} from 'vue';
    import ProfilesCarousel from '~/components/ProfilesCarousel.vue';
    import {ProfileSearchApi, Configuration} from '@api';
    import type {ProfilesSlice} from '@prismicio-types';
    import {getSliceComponentProps, PrismicRichText} from '@prismicio/vue';
    import {useAsyncData} from '#imports';
    import {VJoyButton} from '@maltjoy/core-vue';

    const searchApi = new ProfileSearchApi(new Configuration({basePath: ''}));

    const props = defineProps(getSliceComponentProps<ProfilesSlice>(['slice', 'index', 'slices', 'context']));

    const aliases = ref(props.slice.items.map((item) => `/profile/${item.profileAlias}`));
    const {data: profiles} = await useAsyncData(`slice-profiles-${props.slice.id}`, () =>
        searchApi.searchProfiles({profileIdOrSeoUrl: aliases.value, hiddenProfiles: true}),
    );
</script>
