<template>
    <section
        :id="slice.primary.anchorName || undefined"
        ref="section"
        class="slice-highlight"
        :class="{
            'theme-malt': theme === 'malt',
            'theme-strategy': theme === 'strategy',
            'theme-ai': theme === 'ai',
        }"
    >
        <div class="slice-content">
            <div class="highlight-panel">
                <div class="highlight-media">
                    <CardHand v-if="cardHandEffect" :ms-theme="theme === 'strategy'">
                        <PrismicImage :image="slice.primary.image" />
                    </CardHand>
                    <PrismicImage v-else :image="slice.primary.image" class="highlight-image" width="240" height="237" />
                </div>
                <div class="highlight-content">
                    <div class="highlight-category">{{ slice.primary.category }}</div>
                    <PrismicRichText :field="slice.primary.title" class="slice-title" />
                    <PrismicRichText v-if="slice.primary.description" :field="slice.primary.description" class="slice-description" />
                    <div v-if="ctaLink.url" class="highlight-cta">
                        <VJoyButton :href="ctaLink.url" :variant="ctaVariant" data-source-component="highlight" :size="ctaSize">
                            {{ ctaLabel }}
                        </VJoyButton>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {ref} from 'vue';
    import {VJoyButton} from '@maltjoy/core-vue';
    import type {HighlightSlice} from '@prismicio-types';
    import {getSliceComponentProps, PrismicRichText} from '@prismicio/vue';
    import type {FilledLinkToWebField} from '@prismicio/types';
    import PrismicImage from '~/components/PrismicImage.vue';
    import CardHand from '~/components/CardHand.vue';
    import {useComponentTracking} from '#imports';

    const props = defineProps(getSliceComponentProps<HighlightSlice>(['slice', 'index', 'slices', 'context']));

    const theme = ref(props.slice.primary.theme ?? 'strategy');
    const cardHandEffect = ref(props.slice.primary.cardHandEffect ?? true);

    const ctaLink = ref(props.slice.primary.ctaLink as FilledLinkToWebField);
    const ctaLabel = ref(props.slice.primary.ctaLabel as string);
    const ctaVariant = ref({malt: 'main', strategy: 'primary', ai: 'main-ai'}[theme.value] as any);
    const ctaSize = ref((ctaLabel.value?.length > 24 ? 'small' : 'medium') as 'small' | 'medium');
    const section = ref(null);

    useComponentTracking(section);
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .slice-highlight {
        .slice {
            &-title {
                @include mq.screen_md(min) {
                    font-size: var(--joy-font-size-secondary-400);
                }
            }
            &-description {
                max-width: 450px;
                margin: var(--joy-core-spacing-6) auto 0;
                @include mq.screen_md(min) {
                    max-width: none;
                    text-align: left;
                    display: flex;
                }
            }
        }
        .highlight {
            &-panel {
                border-radius: var(--joy-core-radius-4);
                background-color: var(--joy-color-secondary-10);
                padding: var(--joy-core-spacing-8) var(--joy-core-spacing-5);

                @include mq.screen_md(min) {
                    text-align: left;
                    display: flex;
                    padding: var(--joy-core-spacing-12) 80px;
                }
            }
            &-content {
                @include mq.screen_md(min) {
                    flex: 1 1 0%;
                    margin-left: 80px;
                }
            }
            &-media {
                margin-bottom: var(--joy-core-spacing-6);
            }
            &-image {
                flex: none;
                width: 170px;
                @include mq.screen_sm(min) {
                    width: 240px;
                }
            }
            &-category {
                font-weight: var(--joy-font-weight-bold);
                color: var(--joy-color-secondary-90);
                margin-bottom: var(--joy-core-spacing-2);
            }
            &-cta {
                margin-top: var(--joy-core-spacing-6);
                .joy-button--slot {
                    white-space: wrap;
                }
            }
        }

        &.theme-malt {
            .highlight {
                &-panel {
                    background-color: var(--joy-color-primary-10);
                }
                &-category {
                    color: var(--joy-color-primary-90);
                }
            }
        }

        &.theme-ai {
            .highlight {
                &-panel {
                    background-color: var(--joy-color-neutral-20);
                    flex-direction: row-reverse;
                }
                &-category {
                    color: var(--joy-color-ai-primary-30);
                }
                &-content {
                    @include mq.screen_md(min) {
                        margin-left: 0;
                        padding-right: 160px;
                    }
                }
                &-content,
                &-media {
                    flex: 1;
                }

                &-media {
                    @include mq.screen_md(min) {
                        flex: none;
                        width: 50%;
                    }
                }

                &-image {
                    width: 100%;
                    height: auto;
                    max-width: 550px;
                    @include mq.screen_md(min) {
                        max-width: none;
                    }
                }
            }
        }
    }
</style>
