<template>
    <section :id="slice.primary.anchorName || undefined" class="slice slice-columns" aria-label="columns">
        <div class="slice-content">
            <PrismicRichText v-if="title" :field="title" class="slice-title" />
            <PrismicRichText v-if="description.length" :field="description" class="slice-description" />
            <div class="slice-body slice-columns__items">
                <div
                    v-for="(item, i) in items"
                    :key="i"
                    class="slice-columns__item"
                    :class="{'slice-columns__item--border': slice.primary.withBorders !== false}"
                >
                    <PrismicImage :image="item.image" class="slice-columns__item__image" />
                    <div class="slice-columns__item__title">{{ item.title }}</div>
                    <PrismicRichText :field="item.text" class="joy-mt-4" />
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {ref} from 'vue';
    import type {ColumnsSlice} from '@prismicio-types';
    import {getSliceComponentProps, PrismicRichText} from '@prismicio/vue';
    import PrismicImage from '~/components/PrismicImage.vue';
    import Card from '~/components/Card.vue';

    const props = defineProps(getSliceComponentProps<ColumnsSlice>(['slice', 'index', 'slices', 'context']));

    const title = ref(props.slice.primary.title);
    const description = ref(props.slice.primary.description);
    const items = ref(props.slice.items);
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .slice-columns {
        &__items {
            margin-left: auto;
            margin-right: auto;
            display: flex;
            justify-content: center;
            gap: var(--joy-core-spacing-8);
            flex-wrap: wrap;

            @include mq.screen_lg(min) {
                flex-wrap: nowrap;
            }
        }

        &__item {
            width: 100%;
            max-width: 320px;

            &__image {
                width: 100px;
                height: 100px;
                object-fit: contain;
            }

            &__title {
                font-weight: var(--joy-font-weight-bold);
                color: var(--joy-color-secondary-30);
                margin-top: var(--joy-core-spacing-3);
                font-size: var(--joy-font-size-primary-600);

                @include mq.screen_lg(min) {
                    margin-top: var(--joy-core-spacing-4);
                    font-size: var(--joy-font-size-primary-800);
                }
            }

            &--border {
                border-radius: var(--joy-core-radius-4);
                border: 1px solid var(--joy-color-secondary-30);
                padding: var(--joy-core-radius-4);
            }
        }
    }
</style>
