<template>
    <section v-if="!(breakpoints?.isMobile && slice.primary.hideInMobile)" ref="section" class="testimonials" aria-label="testimonials">
        <div class="slice-container">
            <PrismicRichText :field="slice.primary.title" class="title" />

            <NuxtErrorBoundary>
                <Swiper
                    :modules="[Controller]"
                    :loop="slice.items.length > 1"
                    :slides-per-view="breakpoints?.isMobile ? 1 : 'auto'"
                    @swiper="setController"
                >
                    <SwiperSlide v-for="(item, i) in slice.items" :key="`slice-item-${i}`">
                        <div class="testimonial">
                            <div class="card">
                                <div class="testimonial-illustration">
                                    <PrismicImage
                                        :image="item.testimonialIllustration"
                                        sizes="small:400px"
                                        :alt="`Testimonial from ${item.intervieweeName}, ${item.intervieweeJobTitle}`"
                                    />
                                </div>
                                <div class="testimonial-section">
                                    <div class="testimonial-content">
                                        <PrismicRichText :field="item.testimonialTitle" class="testimonial-title" />
                                        <PrismicRichText :field="item.testimonialDescription" class="testimonial-description" />
                                    </div>
                                    <div class="testimonial-info">
                                        <span class="interviewee-name">{{ item.intervieweeName }}</span>
                                        <span class="interviewee-job-title">{{ item.intervieweeJobTitle }}</span>
                                        <ServerSideNavigationPrismicLink
                                            v-if="isLink(item.linkText, item.link)"
                                            :field="item.link"
                                            class="link"
                                            data-source-component="testimonials"
                                        >
                                            {{ item.linkText }}
                                        </ServerSideNavigationPrismicLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SliderControls v-if="breakpoints?.isMobile && slice.items.length > 1" />
                </Swiper>
            </NuxtErrorBoundary>
            <SliderControls v-if="!breakpoints?.isMobile && slice.items.length > 1" class="desktop-controls" :swiper="swiperRef" :controlled="true" />
        </div>
    </section>
</template>

<script setup lang="ts">
    import {inject, onMounted, ref} from 'vue';
    import {Swiper, SwiperSlide} from 'swiper/vue';
    // @ts-ignore
    import type {Swiper as SwiperClass} from 'swiper';
    // @ts-ignore
    import {Controller} from 'swiper';
    import {getSliceComponentProps, PrismicRichText} from '@prismicio/vue';
    import {MEDIA_BREAKPOINTS} from '~/app/keys';
    import type {TestimonialsSliceSlice} from '@prismicio-types';
    import useErrorHelpers from '~/composables/useErrorHelpers';
    import {useComponentTracking, usePrismicFieldHelpers} from '#imports';
    import ServerSideNavigationPrismicLink from '~/components/ServerSideNavigationPrismicLink.vue';
    import PrismicImage from '~/components/PrismicImage.vue';
    import SliderControls from '~/components/SliderControls.vue';

    const breakpoints = inject(MEDIA_BREAKPOINTS);
    const props = defineProps(getSliceComponentProps<TestimonialsSliceSlice>());

    const {getPreviewErrorHandler, throwIfMissingItemsProperties} = useErrorHelpers();

    const {isLink} = usePrismicFieldHelpers();
    const swiperRef = ref<SwiperClass>();
    const section = ref(null);

    const setController = (swiper: SwiperClass) => {
        swiperRef.value = swiper;
    };
    onMounted(() => {
        const errorHandler = getPreviewErrorHandler();

        throwIfMissingItemsProperties(
            errorHandler.toast,
            props.slice.items,
            [
                {name: 'testimonialIllustration', type: 'image'},
                {name: 'testimonialTitle', type: 'field'},
                {name: 'testimonialDescription', type: 'field'},
                {name: 'intervieweeName', type: 'rawText'},
                {name: 'intervieweeJobTitle', type: 'rawText'},
                {name: 'link', type: 'link'},
                {name: 'linkText', type: 'rawText'},
            ],
            'testimonial',
        );
    });

    useComponentTracking(section);
</script>

<style lang="scss">
    .testimonials {
        font-family: $font-family;
        position: relative;
        overflow: visible;
        @include content-width;
        @media (max-width: $wide-breakpoint) {
            max-width: unset;
            width: calc(50% + #{$container-max-width}/ 2);
            margin-left: calc(50% - #{$container-max-width}/ 2);
            margin-right: 0;
        }

        @media (max-width: $container-max-width + $margin-section-normal) {
            margin-left: $margin-section-normal;
            margin-right: 0;
            width: calc(100% - #{$margin-section-normal});
        }

        @media (max-width: $mobile-breakpoint) {
            margin-left: $margin-section-mobile;
            margin-right: $margin-section-mobile;
            width: calc(100% - 2 * #{$margin-section-mobile});
        }

        .slice-container {
            display: grid;
            grid-template-columns: 0.3 * $container-max-width auto;
            grid-template-rows: auto;
            gap: 48px;
            overflow: hidden;

            @media (max-width: $container-max-width + $margin-section-normal) {
                grid-template-columns: 1fr 2fr;
            }
            @media (max-width: $mobile-breakpoint) {
                grid-template-columns: 1fr;
                justify-items: center;
            }
        }

        .title {
            width: 100%;
            max-width: 0.3 * $container-max-width;
            margin-bottom: 48px;
            @media (max-width: $mobile-breakpoint) {
                width: unset;
                margin: 0px $margin-section-mobile 0px $margin-section-mobile;
                text-align: center;
            }
            h2 {
                @include secondary-title;
                line-height: 105%;
            }
        }

        .swiper {
            @include swiper;
            grid-column-start: 2;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 3;
            max-width: 100%;
            @media (max-width: $mobile-breakpoint) {
                grid-area: auto;
                max-width: calc(100vw - 2 * $margin-section-mobile);
                margin: auto 0;
            }
        }
        .desktop-controls {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 2;
            grid-row-end: 3;
        }

        .swiper-wrapper {
            align-items: stretch;
        }

        .swiper-slide {
            width: 100%;
            @media (max-width: $wide-breakpoint) {
                max-width: 780px;
            }
            @media (max-width: $mobile-breakpoint) {
                width: calc(100vw);
                max-width: unset;
            }
            height: unset;
        }

        .testimonial-section {
            flex-basis: 60%;
            padding-left: 48px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: var(--joy-color-neutral-60);
            line-height: 110%;
            @media (max-width: $mobile-breakpoint) {
                padding-left: 0px;
                margin-bottom: 8px;
            }
        }

        .testimonial {
            height: 100%;
            box-sizing: border-box;
            padding: 25px;
            margin-bottom: 23px;
            max-width: 780px;
            margin-left: 0;
            @media (max-width: $mobile-breakpoint) {
                max-width: unset;
            }
        }

        .card {
            @include flex-row-justify-center;
            @include card;

            height: 100%;
            padding: 32px;
            box-sizing: border-box;
            border: none;
            box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
            margin: 0 auto;
            @media (max-width: $wide-breakpoint) {
                margin: unset;
            }
            @media (max-width: $mobile-breakpoint) {
                display: flex;
                flex-direction: column;
                padding: 24px 20px;
                height: auto;
            }
        }

        .testimonial-illustration {
            flex-basis: 40%;
            height: 100%;
            position: relative;
            max-width: 400px;
            @media (max-width: $mobile-breakpoint) {
                width: 100%;
                align-self: center;
                aspect-ratio: 0.9;
                margin-bottom: 30px;
            }

            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: calc(#{var(--joy-core-radius-4)} / 2);
            }
        }

        .testimonial-title {
            margin-bottom: 14px;
            h3 {
                font-size: var(--joy-font-size-primary-600);
            }
        }

        .testimonial-description {
            @include description-base;
            margin-bottom: 24px;
        }

        .link {
            @include link;
        }

        .testimonials button {
            @include flex-row-justify-center;
            align-items: center;
            background-color: $color-white;
            border: $border var(--joy-color-secondary-30);
            border-radius: 50%;
            padding: 22px;
            width: 63px;
            height: 62px;
            margin-right: 10px;
        }

        .testimonial-info {
            @include flex-column-start;
            gap: 6px;
        }

        .interviewee-name {
            font-weight: var(--joy-font-weight-bold);
            text-transform: uppercase;
            color: var(--joy-color-neutral-60);
        }

        .interviewee-job-title {
            font-size: var(--joy-font-size-primary-300);
            margin-bottom: 24px;
        }

        .arrows {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            align-self: start;
            @media (max-width: $mobile-breakpoint) {
                margin: 0px $margin-section-mobile 0px $margin-section-mobile;
            }
        }
    }
</style>
