<template>
    <section class="freelance-search">
        <div class="search-card">
            <form class="search-form" data-testid="search-block-form" @submit.prevent="onSubmit">
                <div class="inputs">
                    <div class="category">
                        <input
                            v-model="query"
                            class="category-input"
                            name="category-input"
                            type="text"
                            :maxlength="SEARCH_QUERY_MAX_LENGTH"
                            data-testid="search-block-query-field"
                            :aria-label="categoryLabel || 'category'"
                            :label="categoryLabel"
                            :placeholder="categoryLabel || 'category'"
                            autocomplete="off"
                            list="query-list"
                            debounce="500"
                            @input="onQueryInputChange"
                            @focus="
                                () => {
                                    isQuerySearchActive = true;
                                }
                            "
                            @blur="onQueryBlur"
                        />
                        <button
                            :class="{'clear-button': true, visible: query.length > 0}"
                            type="button"
                            data-testid="search-block-query-clear"
                            aria-label="clear-query"
                            @click="clearQuery"
                        >
                            <CrossIcon />
                        </button>
                        <span v-if="categoryIsInError" class="category-error">{{ missingCategoryErrorText }}</span>
                        <div
                            :class="{
                                suggestions: true,
                                visible: isQuerySearchActive && querySuggestions.length > 0,
                            }"
                            data-testid="search-block-query-suggestions"
                        >
                            <ul>
                                <li
                                    v-for="(querySuggestion, i) in querySuggestions"
                                    :key="i"
                                    @mousedown="(event) => onQuerySuggestionClick(event, querySuggestion)"
                                >
                                    {{ querySuggestion }}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="location">
                        <input
                            v-model="locationInput"
                            class="location-input"
                            name="location-input"
                            type="text"
                            data-testid="search-block-location-field"
                            :aria-label="locationLabel || 'location'"
                            :label="locationLabel"
                            :placeholder="locationLabel || 'location'"
                            autocomplete="off"
                            @input="onLocationInputChange"
                            @focus="
                                () => {
                                    isLocationSearchActive = true;
                                }
                            "
                            @blur="onLocationBlur"
                        />
                        <button
                            :class="{
                                'clear-button': true,
                                visible: locationInput.length > 0,
                            }"
                            type="button"
                            data-testid="search-block-location-clear"
                            aria-label="clear-location"
                            @click="clearLocation"
                        >
                            <CrossIcon />
                        </button>
                        <div
                            :class="{
                                suggestions: true,
                                visible: isLocationSearchActive && locationSuggestions.length > 0,
                            }"
                            data-testid="search-block-location-suggestions"
                        >
                            <ul>
                                <li
                                    v-for="locationSuggestion in locationSuggestions"
                                    :key="locationSuggestion.formattedAddress"
                                    @mousedown="(event) => onLocationSuggestionClick(event, locationSuggestion)"
                                >
                                    <HomeIcon v-if="hasProperty(locationSuggestion, 'remoteAllowed')" height="17px" width="17px" />
                                    {{ locationSuggestion.formattedAddress }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="links">
                    <VJoyButton variant="main" type="submit" data-testid="search-block-btn-submit">{{ searchLinkText }}</VJoyButton>
                    <div v-if="aiSearchLinkText" class="ai-search-cta">
                        <span v-if="betweenCtasText" class="between-ctas">{{ betweenCtasText }}</span>
                        <VJoyButton
                            :href="aiSearchLink"
                            icon="sparkle"
                            icon-position="right"
                            variant="secondary-ai"
                            type="button"
                            data-testid="search-block-btn-search-ai"
                        >
                            {{ aiSearchLinkText }}
                        </VJoyButton>
                    </div>
                    <div v-if="displaySubmitProjectLink && submitProjectLink && isUnloggedOrClient()" class="submit-project">
                        <span class="cta-separator" />
                        <a :href="submitProjectLinkWithParams">{{ submitProjectLinkText }}</a>
                    </div>
                </div>
            </form>
        </div>
        <div v-if="reassuranceItems" class="reassurances">
            <div v-for="(item, i) in reassuranceItems" :key="`reassurance-${i}`" class="reassurance">
                <ReassuranceIcon :icon="item.reassuranceIcon ?? undefined" />
                {{ item.reassuranceText }}
            </div>
            <!-- TrustBox widget -->
            <div
                v-if="showTrustBoxWidget()"
                ref="trustpilotRef"
                class="trustpilot-widget"
                :data-locale="trustpilotDataLocale"
                :data-template-id="trustpilotDataTemplateId"
                :data-businessunit-id="trustpilotDataBusinessunitId"
                data-theme="light"
            >
                <ServerSideNavigationPrismicLink :field="trustpilotLink">Trustpilot</ServerSideNavigationPrismicLink>
            </div>
            <!-- End TrustBox widget -->
        </div>
    </section>
</template>

<script setup lang="ts">
    import {ref, onMounted, computed} from 'vue';
    import {VJoyButton} from '@maltjoy/core-vue';
    import {useLocale, usePrismicTypeGuards, usePrismicFieldHelpers, useFeatureFlag} from '#imports';
    import type {KeyTextField, LinkField, SelectField} from '@prismicio/types';
    import ReassuranceIcon from './ReassuranceIcon.vue';
    import ServerSideNavigationPrismicLink from './ServerSideNavigationPrismicLink.vue';
    import CrossIcon from '~/assets/svg/cross.svg';
    import HomeIcon from '~/assets/svg/home.svg';
    import {getDefaultCitiesSuggestionsForLocale} from '@malt/location-autocomplete/get-default-cities-suggestions';
    import useSearchHelpers from '~/composables/useSearchHelpers';
    import type {LocationSuggestion} from '~/types/custom';
    import useErrorHelpers from '~/composables/useErrorHelpers';
    import {useUserState} from '~/composables/useUserState';
    import {useUserInfoHelpers} from '~/composables/useUserInfoHelpers';
    import useTypeGuards from '~/composables/useTypeGuards';

    const SEARCH_QUERY_MAX_LENGTH = 128;

    const {fetchFeatureFlags} = useFeatureFlag();
    const displaySubmitProjectLink = (await fetchFeatureFlags(['project-proposal-brief-no-more-service-level-needed']))[
        'project-proposal-brief-no-more-service-level-needed'
    ];

    const {isCompany} = useUserInfoHelpers();

    const {user} = useUserState();
    const userData = computed(() => user?.value?.data);

    const {locale} = useLocale();
    const [lang, countryCode] = locale.value.split('-') ?? ['fr', 'FR'];

    type Props = {
        categoryLabel: KeyTextField;
        locationLabel: KeyTextField;
        searchLinkText: KeyTextField;
        searchLink: KeyTextField;
        submitProjectLinkText: KeyTextField;
        submitProjectLink: KeyTextField;
        aiSearchLinkText?: KeyTextField;
        aiSearchLink?: KeyTextField;
        reassuranceItems?: {
            reassuranceIcon: SelectField;
            reassuranceText: KeyTextField;
        }[];
        betweenCtasText: KeyTextField;
        missingCategoryErrorText: KeyTextField;
        trustpilotDataLocale?: KeyTextField;
        trustpilotDataTemplateId?: KeyTextField;
        trustpilotDataBusinessunitId?: KeyTextField;
        trustpilotLink?: LinkField;
        heapPrefix: string;
        remoteInCountryLabel: KeyTextField;
        briefOrigin?: string;
        hideTrustpilot?: boolean;
    };

    const props = withDefaults(defineProps<Props>(), {
        submitProjectLinkText: '',
        submitProjectLink: '',
        aiSearchLinkText: '',
        aiSearchLink: '',
        betweenCtasText: '',
        missingCategoryErrorText: '',
        trustpilotDataLocale: '',
        trustpilotDataTemplateId: '',
        trustpilotDataBusinessunitId: '',
        trustpilotLink: () => ({
            link_type: 'Any',
        }),
        heapPrefix: '',
        remoteInCountryLabel: '',
        reassuranceItems: undefined,
        briefOrigin: '',
        hideTrustpilot: false,
    });
    const {hasProperty, isFilled} = usePrismicTypeGuards();
    const {isHTMLInputElement, isLocationSuggestionResponse} = useTypeGuards();
    const {getLocationAutocomplete, getQueryAutocomplete, getSearchURL} = useSearchHelpers();
    const defaultLocationSuggestions: LocationSuggestion[] = [];
    const {isLink} = usePrismicFieldHelpers();

    if (props.remoteInCountryLabel && props.remoteInCountryLabel !== '') {
        defaultLocationSuggestions.push({
            formattedAddress: props.remoteInCountryLabel,
            remoteAllowed: true,
        });
    }

    const localeSuggestions: LocationSuggestion[] | undefined = getDefaultCitiesSuggestionsForLocale(locale.value);

    if (isLocationSuggestionResponse(localeSuggestions)) {
        defaultLocationSuggestions.push(...localeSuggestions);
    }

    const query = ref('');
    const locationInput = ref('');
    const location = ref<LocationSuggestion | null>(null);
    const submitProjectLinkWithParams = ref<string>(computeSubmitProjectLinkWithParams());
    const categoryIsInError = ref(false);
    const querySuggestions = ref<string[]>([]);
    const isQuerySearchActive = ref(false);
    const locationSuggestions = ref<LocationSuggestion[]>(defaultLocationSuggestions);
    const isLocationSearchActive = ref(false);
    const trustpilotRef = ref<HTMLDivElement>();

    function initTrustpilot() {
        if (trustpilotRef.value) {
            // @ts-expect-error ts doesn't know about Trustpilot
            window.Trustpilot.loadFromElement(trustpilotRef.value);
        }
    }
    onMounted(() => {
        if (
            props.trustpilotDataLocale !== '' &&
            props.trustpilotDataTemplateId !== '' &&
            props.trustpilotDataBusinessunitId !== '' &&
            isLink('Trustpilot', props.trustpilotLink as LinkField)
        ) {
            // @ts-expect-error ts doesn't know about Trustpilot
            if (window.Trustpilot) {
                initTrustpilot();
            } else {
                const script = document.createElement('script');
                script.src = 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
                document.head.appendChild(script);
                script.addEventListener('load', () => {
                    initTrustpilot();
                });
            }
        }
        const {throwIfItemsCountIsOutOfBound, throwIfPrimaryPropertiesAreInvalid, throwIfMissingItemsProperties, getPreviewErrorHandler} =
            useErrorHelpers();
        const errorHandler = getPreviewErrorHandler();
        if (errorHandler.enabled) {
            if (props.reassuranceItems) {
                throwIfItemsCountIsOutOfBound(errorHandler.toast, props.reassuranceItems.length, 'reassurance item', 0, 3);
            }
            throwIfPrimaryPropertiesAreInvalid(
                errorHandler.toast,
                props,
                [
                    {name: 'searchLinkText', type: 'rawText'},
                    {name: 'categoryLabel', type: 'rawText'},
                    {name: 'locationLabel', type: 'rawText'},
                    {name: 'searchLink', type: 'rawText'},
                    {name: 'missingCategoryErrorText', type: 'rawText'},
                ],
                'search',
            );
            if (props.reassuranceItems) {
                throwIfMissingItemsProperties(
                    errorHandler.toast,
                    props.reassuranceItems,
                    [
                        {name: 'reassuranceIcon', type: 'rawText'},
                        {name: 'reassuranceText', type: 'rawText'},
                    ],
                    'reassurance item',
                );
            }
        }
    });
    function onSubmit(e: Event) {
        e.preventDefault();

        if (!isFilled(query.value)) {
            categoryIsInError.value = true;
            return;
        }
        window.location.href = getSearchURL({
            baseURLLink: props.searchLink,
            query: query.value,
            location: location.value,
            sourceComponent: props.briefOrigin,
        });
    }
    async function onQueryInputChange(event: Event) {
        if (!isHTMLInputElement(event.target)) {
            return;
        }
        query.value = event.target.value ?? '';

        if (event.target.value.length >= 2) {
            querySuggestions.value = await getQueryAutocomplete(event.target.value);
            isQuerySearchActive.value = true;
        } else {
            querySuggestions.value = [];
        }
    }

    function onQuerySuggestionClick(event: Event, querySuggestion: string) {
        event.preventDefault();
        query.value = querySuggestion;

        setTimeout(() => {
            isQuerySearchActive.value = false;
        }, 100);
    }

    function onQueryBlur() {
        isQuerySearchActive.value = false;
    }
    function clearQuery() {
        query.value = '';
        querySuggestions.value = [];
    }
    async function onLocationInputChange(event: Event) {
        if (!isHTMLInputElement(event.target)) {
            return;
        }

        locationInput.value = event.target.value ?? '';

        if (event.target.value.length >= 2) {
            locationSuggestions.value = await getLocationAutocomplete(event.target.value, countryCode, lang);
        } else {
            locationSuggestions.value = defaultLocationSuggestions;
        }

        if (event.target.value.length === 0) {
            location.value = null;
        }
        isLocationSearchActive.value = true;
    }
    function onLocationSuggestionClick(event: Event, locationSuggestion: LocationSuggestion) {
        event.preventDefault();
        location.value = locationSuggestion;
        locationInput.value = locationSuggestion.formattedAddress;
        if (props.submitProjectLink) {
            submitProjectLinkWithParams.value = getSearchURL({
                baseURLLink: props.submitProjectLink,
                query: query.value,
                location: location.value,
                sourceComponent: props.briefOrigin,
            });
        }
        setTimeout(() => {
            isLocationSearchActive.value = false;
        }, 200);
    }
    function onLocationBlur() {
        locationInput.value = location.value?.formattedAddress ?? '';
        if (!location.value) {
            locationSuggestions.value = defaultLocationSuggestions;
        }
        isLocationSearchActive.value = false;
    }
    function clearLocation() {
        location.value = null;
        locationInput.value = '';
        locationSuggestions.value = defaultLocationSuggestions;
    }

    function showTrustBoxWidget(): boolean {
        return (
            !props.hideTrustpilot &&
            props.trustpilotDataLocale !== '' &&
            props.trustpilotDataTemplateId !== '' &&
            props.trustpilotDataBusinessunitId !== '' &&
            isLink('Trustpilot', props.trustpilotLink)
        );
    }

    function isUnloggedOrClient() {
        return !userData.value?.isLoggedIn || isCompany(userData.value?.info);
    }

    function computeSubmitProjectLinkWithParams() {
        if (props.submitProjectLink) {
            if (props.briefOrigin) {
                const url = props.submitProjectLink;
                if (!url.includes('brief_origin')) {
                    return `${url}${url.includes('?') ? '&' : '?'}brief_origin=${props.briefOrigin}`;
                }
            }

            return props.submitProjectLink;
        }
        return '';
    }
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;
    $max-input-width: none;
    $min-input-width: 200px;
    .freelance-search {
        @include flex-column-align-center;
        width: 100%;
        font-family: $font-family;
        @media (max-width: $mobile-breakpoint) {
            @include card-without-transition;
            padding: 19px 17px 29px 17px;
            align-items: flex-start;
            background-color: white;
            @media (max-width: $mobile-breakpoint) {
                box-shadow: 0px 4px 12px rgba(24, 24, 24, 0.16);
            }
        }

        .search-card {
            @include flex-row-center;
            @include card-without-transition;
            background-color: white;
            gap: 16px;
            flex-wrap: wrap;
            padding: 32px;
            width: 100%;
            @media (max-width: $mobile-breakpoint) {
                flex-direction: column;
                padding: 0;
                box-shadow: none;
            }

            .search-form {
                @include flex-row-justify-center;
                align-items: baseline;
                flex-wrap: wrap;
                gap: 20px;
                width: 100%;
                @media (max-width: $mobile-breakpoint) {
                    flex-direction: column;
                    gap: 32px;
                }
            }

            .inputs {
                display: flex;
                gap: 20px;
                width: 100%;
                flex: 1 1 0;
                @media (max-width: $mobile-breakpoint) {
                    flex-direction: column;
                    gap: 16px;
                }
                .suggestions {
                    position: absolute;
                    z-index: 10;
                    top: 52px;
                    width: 100%;
                    background-color: white;
                    list-style: none;
                    border-radius: var(--joy-core-radius-4);
                    border: solid 2px var(--joy-color-secondary-30);
                    overflow: hidden;
                    display: none;
                    color: var(--joy-color-neutral-50);

                    &.visible {
                        display: block;
                    }
                    ul {
                        max-height: 185px;
                        overflow: auto;
                        li {
                            padding: 10px 15px;
                            font-size: var(--joy-font-size-primary-400);
                            line-height: 17px;
                            &:hover {
                                background-color: var(--joy-color-secondary-10);
                            }
                            display: flex;
                            align-items: baseline;
                            gap: 8px;
                            svg {
                                margin-top: -3px;
                            }
                        }
                        @include nicer-scrollbar;
                    }
                }
                .clear-button {
                    position: absolute;
                    right: var(--joy-core-spacing-4);
                    top: var(--joy-core-spacing-4);
                    cursor: pointer;
                    background: transparent;
                    display: none;
                    border: none;
                    svg {
                        g,
                        path {
                            stroke: var(--joy-color-secondary-30);
                        }
                    }
                    &.visible {
                        display: block;
                    }
                }

                .category {
                    display: flex;
                    flex-direction: column;
                    width: 50%;
                    max-width: $max-input-width;
                    position: relative;
                    @media (max-width: $mobile-breakpoint) {
                        max-width: none;
                        width: 100%;
                    }

                    .category-input {
                        width: 100%;
                        background-image: url('~/assets/svg/search-gray.svg');
                        &:focus {
                            background-image: url('~/assets/svg/search.svg');
                        }
                        @media (max-width: $mobile-breakpoint) {
                            max-width: none;
                            min-width: auto;
                        }
                    }

                    .category-error {
                        color: var(--joy-color-primary-30);
                        margin-top: 10px;
                    }
                }
                .location {
                    width: 50%;
                    max-width: $max-input-width;
                    position: relative;
                    @media (max-width: $mobile-breakpoint) {
                        max-width: none;
                        width: 100%;
                    }

                    .location-input {
                        width: 100%;
                        background-image: url('~/assets/svg/location-gray.svg');
                        &:focus {
                            background-image: url('~/assets/svg/location.svg');
                        }
                        @media (max-width: $mobile-breakpoint) {
                            max-width: none;
                            min-width: auto;
                        }
                    }
                }
            }

            .links {
                display: flex;
                align-items: center;
                max-height: 48px;
                gap: 16px;

                @media (max-width: $mobile-breakpoint) {
                    @include flex-column-align-center;
                    width: 100%;
                    gap: 30px;
                    max-height: none;
                }
            }

            .joy-button {
                width: 100%;
                @include mq.screen_md(min) {
                    width: auto;
                }
            }
            .submit-project {
                display: flex;
                align-items: center;
                gap: 16px;
                a {
                    @include link;
                }
            }

            .ai-search-cta {
                width: 100%;

                @include mq.screen_md(min) {
                    width: auto;
                }

                .between-ctas {
                    @include mq.screen_sm() {
                        display: none;
                    }
                }
            }

            input {
                @include input;
                font-weight: var(--joy-font-weight-bold);
                line-height: var(--joy-line-height-large);
                &::placeholder {
                    font-weight: $font-weight-regular;
                }

                &[type='text'] {
                    max-width: $max-input-width;
                    min-width: $min-input-width;
                    width: 100%;
                    height: 48px;
                    padding: var(--joy-core-spacing-3) var(--joy-core-spacing-7) var(--joy-core-spacing-3) var(--joy-core-spacing-10);
                    background-repeat: no-repeat;
                    background-size: 21px auto;
                    background-position: 15px center;
                    color: var(--joy-color-neutral-50);
                }
            }

            .between-ctas {
                margin-right: 16px;
                color: var(--joy-color-neutral-50);
                font-size: var(--joy-font-size-primary-400);
            }
        }
        .reassurances {
            @include flex-row-center;
            gap: 44px;
            margin-top: 58px;
            color: var(--joy-color-secondary-30);
            font-size: var(--joy-font-size-primary-300);
            width: 100%;
            @media (max-width: $mobile-breakpoint) {
                flex-direction: column;
                align-items: flex-start;
                gap: 24px;
                margin-top: 50px;
                height: unset;
            }

            .reassurance {
                @include flex-row-align-center;
                gap: 16px;
                height: 28px;
            }
        }
        .trustpilot-widget {
            height: 22px;
            margin: 0 auto;
            a {
                @include link;
            }
            iframe {
                max-width: 260px;
                max-height: 22px;
            }
        }
        .cta-separator {
            display: none;
            @include mq.screen_md(min) {
                display: block;
                height: 48px;
                width: 1px;
                background: var(--joy-color-neutral-30);
            }
        }
    }
</style>
