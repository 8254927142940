<template>
    <div class="reinsurance">
        <div v-for="(item, i) in items" :key="i" class="reinsurance__item">
            <ReassuranceIcon :icon="item.reassuranceIcon ?? undefined" />
            {{ item.reassuranceText }}
        </div>
        <div
            v-if="hasTrustpilot"
            ref="trustpilotRef"
            class="reinsurance__trustpilot"
            :data-locale="trustpilotDataLocale"
            :data-template-id="trustpilotDataTemplateId"
            :data-businessunit-id="trustpilotDataBusinessunitId"
            data-theme="light"
        >
            <ServerSideNavigationPrismicLink :field="trustpilotLink">Trustpilot</ServerSideNavigationPrismicLink>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {computed, onMounted, ref} from 'vue';
    import {usePrismicFieldHelpers} from '#imports';
    import ReassuranceIcon from './ReassuranceIcon.vue';
    import ServerSideNavigationPrismicLink from '~/components/ServerSideNavigationPrismicLink.vue';

    const {isLink} = usePrismicFieldHelpers();

    const trustpilotRef = ref<HTMLDivElement>();

    const props = defineProps<{
        items: any[];
        trustpilotDataLocale: string;
        trustpilotDataTemplateId: string;
        trustpilotDataBusinessunitId: string;
        trustpilotLink: any;
        hideTrustpilot: boolean;
    }>();

    const hasTrustpilot = computed(
        () =>
            !props.hideTrustpilot &&
            props.trustpilotDataLocale !== '' &&
            props.trustpilotDataTemplateId !== '' &&
            props.trustpilotDataBusinessunitId !== '' &&
            isLink('Trustpilot', props.trustpilotLink),
    );

    function initTrustpilot() {
        if (trustpilotRef.value) {
            // @ts-expect-error ts doesn't know about Trustpilot
            window.Trustpilot.loadFromElement(trustpilotRef.value);
        }
    }

    onMounted(() => {
        if (hasTrustpilot.value) {
            // @ts-expect-error ts doesn't know about Trustpilot
            if (window.Trustpilot) {
                initTrustpilot();
            } else {
                const script = document.createElement('script');
                script.src = 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
                document.head.appendChild(script);
                script.addEventListener('load', () => {
                    initTrustpilot();
                });
            }
        }
    });
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .reinsurance {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        gap: var(--joy-core-spacing-6);
        height: unset;
        width: 100%;
        font-size: var(--joy-font-size-primary-300);
        user-select: none;

        @include mq.screen_sm(min) {
            flex-direction: row;
            align-items: center;
            gap: var(--joy-core-spacing-10);
        }

        &__item {
            display: flex;
            align-items: center;
            gap: 16px;
            height: 28px;
            color: var(--joy-color-neutral-40);

            svg {
                filter: grayscale(100%);
            }
        }

        &__trustpilot {
            height: 22px;
            margin: var(--joy-core-spacing-4) auto 0;

            @include mq.screen_sm(min) {
                margin: 0 0 0 auto;
                text-align: right;
            }

            iframe {
                max-width: 260px;
                max-height: 22px;
            }
        }
    }
</style>
