<template>
    <div class="diaporama">
        <PrismicImage
            v-for="(image, index) in images"
            :key="`image-${index}`"
            :image="image"
            :sizes="sizes"
            class="image"
            :class="{hidden: index !== currentIndex}"
            loading="eager"
        />
    </div>
</template>

<script setup lang="ts">
    import {onMounted, ref} from 'vue';
    import type {ImageField} from '@prismicio/types';
    import PrismicImage from '~/components/PrismicImage.vue';

    interface Props {
        images: ImageField[];
        interval?: number;
        sizes?: string;
    }

    const props = withDefaults(defineProps<Props>(), {
        interval: 5000,
        sizes: undefined,
    });

    const currentIndex = ref(0);

    onMounted(() => {
        setInterval(() => {
            currentIndex.value = (currentIndex.value + 1) % props.images.length;
        }, props.interval);
    });
</script>

<style lang="scss" scoped>
    .diaporama {
        position: relative;
        .image {
            position: static;
            opacity: 1;
            transition: opacity var(--joy-transition-duration-default) var(--joy-transition-timing-function);
        }
        .hidden {
            position: absolute;
            opacity: 0;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
</style>
