<template>
    <section class="slice slice-product-content">
        <div class="slice-content text-left">
            <div
                ref="innerRef"
                class="slice-product-content__inner"
                :class="{
                    animate: isAnimated,
                    'slice-product-content__inner--reverse': slice.primary.reverse,
                }"
            >
                <div class="slice-product-content__content">
                    <div v-if="slice.primary.tag" class="slice-product-content__tag">
                        <span class="anim-stagger">{{ slice.primary.tag }}</span>
                    </div>
                    <div class="slice-product-content__header">
                        <PrismicRichText :field="slice.primary.title" class="slice-product-content__title anim-stagger" />
                    </div>
                    <div class="slice-product-content__description">
                        <PrismicRichText :field="slice.primary.description" class="anim-stagger" />
                    </div>
                    <div class="slice-product-content__cta">
                        <ProductLink :href="ctaLink" class="anim-stagger" :theme="slice.primary.theme">{{ slice.primary.ctaLabel }}</ProductLink>
                    </div>
                </div>
                <div v-if="hasMedia" class="slice-product-content__media" :class="{animate: isAnimated}">
                    <PrismicImage
                        v-if="hasImage && !hasVideo"
                        :field="slice.primary.image"
                        class="slice-product-content__media__element anim-stagger"
                    />
                    <video v-if="hasVideo" ref="videoRef" class="slice-product-content__media__element anim-stagger" autoplay playsinline muted loop>
                        <source :src="videoMp4.url" type="video/mp4" />
                        <source v-if="videoWebM.url" :src="videoWebM.url" type="video/webm" />
                    </video>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {ref, onMounted} from 'vue';
    import {getSliceComponentProps, PrismicRichText, PrismicImage} from '@prismicio/vue';
    import type {ProductContentSlice} from '@prismicio-types';
    import ProductLink from '~/components/product/ProductLink.vue';
    import {useGsap} from '@/composables/useGsap';
    import type {FilledLinkToMediaField} from '@prismicio/types';

    const {ScrollTrigger} = useGsap();

    const props = defineProps(getSliceComponentProps<ProductContentSlice>(['slice', 'index', 'slices', 'context']));

    const innerRef = ref<HTMLElement>();

    const ctaLink = ref(props.slice.primary.ctaLink as string);
    const isAnimated = ref(false);

    const videoMp4 = ref(props.slice.primary.videoMp4 as FilledLinkToMediaField);
    const videoWebM = ref(props.slice.primary.videoWebM as FilledLinkToMediaField);

    const hasImage = ref(!!props.slice.primary.image.url);
    const hasVideo = ref(!!videoMp4.value.url);
    const hasMedia = ref(hasImage.value || hasVideo.value);

    const videoRef = ref<HTMLVideoElement | null>(null);

    onMounted(() => {
        const staggerDelay = 0.15;
        const staggers = [...(innerRef.value?.querySelectorAll('.anim-stagger') || [])];
        staggers?.forEach((el: Element, index: number) => {
            (el as HTMLElement).style.transitionDelay = `${index * staggerDelay}s`;
        });

        ScrollTrigger.create({
            trigger: innerRef.value,
            start: 'top bottom',
            onEnter: () => (isAnimated.value = true),
            onLeaveBack: () => (isAnimated.value = false),
        });

        // ScrollTrigger.create({
        //     trigger: innerRef.value,
        //     start: 'top bottom',
        //     onEnter: () => videoRef.value?.play(),
        //     onLeave: () => videoRef.value?.pause(),
        //     onEnterBack: () => videoRef.value?.play(),
        //     onLeaveBack: () => videoRef.value?.pause(),
        // });
    });
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    $content-stagger-delay: 0.5s;

    .slice-product-content {
        .slice-content {
            @include mq.screen_lg(min) {
                padding-top: 120px;
                padding-bottom: 120px;
            }
        }
        &__inner {
            position: relative;
            text-align: left;
            display: flex;
            flex-direction: column-reverse;
            gap: var(--joy-core-spacing-8);

            @include mq.screen_sm(min) {
                flex-direction: row;
                align-items: center;
                gap: 100px;

                &--reverse {
                    flex-direction: row-reverse;
                }
            }
        }
        &__text {
            flex: 1;
        }
        &__media {
            flex: none;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            max-width: 400px;
            margin: 0 auto;
            padding: 0 4px 4px;

            @include mq.screen_sm(min) {
                max-width: none;
                width: 50%;
            }

            &__element {
                border-radius: 30px;
                box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
                width: 100%;
                border-radius: var(--joy-core-radius-4);
            }
        }

        &__tag {
            overflow: hidden;
            margin-bottom: 19px;

            span {
                display: inline-block;
                background-color: var(--joy-color-neutral-20);
                border-radius: var(--joy-core-radius-2);
                padding: 6px var(--joy-core-spacing-3);
                font-size: var(--joy-font-size-primary-200);
                font-weight: var(--joy-font-weight-bold);
                letter-spacing: -0.2px;
                color: var(--joy-color-neutral-40);
                font-family: var(--joy-font-family);
                text-transform: uppercase;
                user-select: none;
            }
        }
        &__header {
            overflow: hidden;
        }
        &__title {
            transform: translate3d(0, 100%, 0);
            padding-bottom: var(--joy-core-spacing-4);

            h2 {
                letter-spacing: -0.4px;
                line-height: 1;
                font-family: var(--joy-font-family-title);
                font-size: var(--joy-font-size-secondary-200);
                color: var(--joy-color-text-title);

                @include mq.screen_sm(min) {
                    font-size: var(--joy-font-size-secondary-400);
                }
                @include mq.screen_lg(min) {
                    font-size: 45px;
                }
            }
        }
        &__description {
            font-size: 16px;
            overflow: hidden;

            @include mq.screen_sm(min) {
                font-size: 17px;
                line-height: 25px;
            }
            p:not(:first-child) {
                margin-top: var(--joy-core-spacing-5);
            }
        }
        &__cta {
            margin-top: 27px;
            overflow: hidden;
        }
        &__media__element {
            transition-delay: 0s !important;
            transition-duration: 1s !important;
        }

        .anim-stagger {
            transform: translate3d(0, 100%, 0);
            will-change: transform;
            backface-visibility: hidden;
            transition: none;
        }

        .animate {
            .anim-stagger {
                transition: transform var(--ai-transition-duration) var(--ai-transition-timing-function);
                transform: translate3d(0, 0, 0);
            }
        }
    }
</style>
