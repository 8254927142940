<template>
    <section :id="slice.primary.anchorName || undefined" ref="section" class="slice stepper-slice">
        <div class="slice-content">
            <PrismicRichText :field="slice.primary.title" class="slice-title" />
            <div class="slice-body stepper">
                <div v-for="(item, i) in slice.items" :key="i" class="step" :class="{'step--active': mounted}">
                    <div
                        v-if="i > 0"
                        class="step__line"
                        :style="{transitionDuration: `${transitionDuration}s`, transitionDelay: `${i * transitionDuration}s`}"
                    />
                    <div class="step__content">
                        <div class="step__circle" />
                        <div class="step__number">{{ i + 1 }}</div>
                        <div class="step__title">{{ item.title }}</div>
                        <PrismicRichText :field="item.text" class="step__text" />
                    </div>
                </div>
            </div>
            <div v-if="ctaUrl || ctaLink.url" class="slice-footer">
                <VJoyButton :href="ctaUrl || ctaLink.url" variant="primary" data-source-component="stepper">
                    {{ ctaLabel }}
                </VJoyButton>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {getSliceComponentProps, PrismicRichText} from '@prismicio/vue';
    import {VJoyButton} from '@maltjoy/core-vue';
    import type {StepperSlice} from '@prismicio-types';
    import {ref, onMounted} from 'vue';
    import type {FilledLinkToWebField} from '@prismicio/types';
    import {useComponentTracking} from '#imports';

    const mounted = ref(false);
    const transitionDuration = 0.5;
    onMounted(() => {
        mounted.value = true;
    });
    const props = defineProps(getSliceComponentProps<StepperSlice>(['slice', 'index', 'slices', 'context']));

    const section = ref(null);
    const ctaLink = ref(props.slice.primary.ctaLink as FilledLinkToWebField);
    const ctaUrl = ref(props.slice.primary.ctaUrl);
    const ctaLabel = ref(props.slice.primary.ctaLabel);

    useComponentTracking(section);
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    $step-line-y: calc(var(--joy-core-spacing-8) + var(--joy-core-spacing-3) - 1px);
    $step-circle-transition: all 1s cubic-bezier(0, 0, 0.2, 1);

    .stepper {
        position: relative;
        overflow: hidden;
        @include mq.screen_md(min) {
            display: flex;
        }
    }
    .step {
        position: relative;
        max-width: 450px;
        margin: 0 auto;

        @include mq.screen_md(min) {
            flex: none;
            text-align: left;
            width: 33.33%;

            &__content {
                margin: 0 var(--joy-core-spacing-4);
            }
            &:first-child &__content {
                margin-left: 0;
            }
            &:last-child &__content {
                margin-right: 0;
            }
        }

        &__number {
            margin: 0 auto;
            color: var(--joy-color-secondary-30);
            font-family: var(--joy-font-family-title);
            font-size: var(--joy-font-size-secondary-600);
            text-align: center;
        }
        &__title {
            font-size: var(--joy-font-size-primary-800);
            font-weight: var(--joy-font-weight-bold);
        }
        &__text {
            margin-top: var(--joy-core-spacing-4);
            color: var(--joy-color-neutral-40);
        }

        &__circle {
            opacity: 0;
            width: var(--joy-core-spacing-6);
            height: var(--joy-core-spacing-6);
            border-radius: var(--joy-core-radius-6);
            background-color: var(--joy-color-secondary-30);
            transition: $step-circle-transition;
            margin: var(--joy-core-spacing-12) auto var(--joy-core-spacing-3);

            @include mq.screen_md(min) {
                margin: var(--joy-core-spacing-8) auto;
            }
        }
        &--active &__circle {
            transform: scale(1);
            opacity: 1;
        }

        &__line {
            position: absolute;
            z-index: -1;
            background-color: var(--joy-color-tertiary-70);
            transform-origin: 0 0;
            transition: transform 1s linear;
            left: 50%;
            top: calc(-1 * var(--joy-core-spacing-11));
            height: var(--joy-core-spacing-11);
            width: 1px;

            @include mq.screen_md(min) {
                width: auto;
                height: 1px;
                top: $step-line-y;
                left: -50%;
                right: 50%;
                transform: scaleX(0);
            }
        }
        &--active &__line {
            transform: scaleX(1);
        }
    }
</style>
