<template>
    <div class="arrows">
        <Arrow direction="left" :on-click="slidePrev" :aria-label="prevArrowLabel || 'Previous testimonial'" data-testid="arrow-left" />
        <Arrow direction="right" :on-click="slideNext" :aria-label="nextArrowLabel || 'Next testimonial'" data-testid="arrow-right" />
    </div>
</template>

<script setup lang="ts">
    import {useSwiper} from 'swiper/vue';
    // @ts-ignore
    import type {Swiper} from 'swiper';
    import Arrow from '~/components/Arrow.vue';

    type Props = {
        prevArrowLabel?: string;
        nextArrowLabel?: string;
        controlled?: boolean;
        swiper?: Swiper;
    };
    const props = defineProps<Props>();
    const parentSwiper = props.controlled ? undefined : useSwiper();
    function slideNext() {
        parentSwiper?.value?.slideNext();
        props.swiper?.slideNext();
    }

    function slidePrev() {
        parentSwiper?.value?.slidePrev();
        props.swiper?.slidePrev();
    }
</script>

<style>
    .arrows {
        display: flex;
        justify-content: center;
        margin-top: 16px;
    }
</style>
