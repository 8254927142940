<template>
    <NuxtImg
        v-if="image && image.url"
        provider="prismic"
        :src="optimizedUrl"
        :loading="loading ?? 'lazy'"
        :alt="image.alt"
        :sizes="sizes"
        :height="height || image.dimensions.height"
        :width="width || image.dimensions.width"
        densities="x1 x2"
    />
</template>

<script setup lang="ts">
    import {computed} from 'vue';
    import {NuxtImg} from '#components';

    interface Props {
        loading?: 'eager' | 'lazy';
        sizes?: string;
        image?: Record<string, any>;
        height?: string;
        width?: string;
    }

    const props = defineProps<Props>();

    const optimizedUrl = computed(() => {
        const image = props.image;
        if (!image) {
            return '';
        }
        const {url} = image;
        try {
            const optimizedUrl = new URL(url);
            optimizedUrl.searchParams.set('auto', 'enhanced,format');
            optimizedUrl.searchParams.set('w', props.width || image.dimensions.width);
            optimizedUrl.searchParams.set('h', props.height || image.dimensions.height);
            return optimizedUrl.toString();
        } catch (e) {
            return url;
        }
    });
</script>
