<template>
    <section v-if="!(breakpoints?.isMobile && slice.primary.hideInMobile)" class="slice slice-reinsurance" aria-label="partners">
        <div class="slice-content">
            <PrismicRichText v-if="slice.primary.title.length" :field="slice.primary.title" class="slice-title" />
            <div class="slice-reinsurance__logos">
                <PrismicImage
                    v-for="(item, i) in slice.items"
                    :key="`slice-item-${i}`"
                    :image="item.brandLogo"
                    sizes="mobile:100px wide:200px"
                    :class="{bigger: item.isBigger}"
                />
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {getSliceComponentProps, PrismicRichText} from '@prismicio/vue';
    import {inject, onMounted} from 'vue';
    import PrismicImage from '~/components/PrismicImage.vue';
    import useErrorHelpers from '~/composables/useErrorHelpers';
    import {MEDIA_BREAKPOINTS} from '~~/src/app/keys';
    import type {ReinsuranceSliceSlice} from '@prismicio-types';

    const breakpoints = inject(MEDIA_BREAKPOINTS);
    const props = defineProps(getSliceComponentProps<ReinsuranceSliceSlice>());
    onMounted(() => {
        const {getPreviewErrorHandler, throwIfItemsCountIsOutOfBound} = useErrorHelpers();
        const errorHandler = getPreviewErrorHandler();
        if (errorHandler.enabled) {
            const itemsCount = props.slice.items.length;
            throwIfItemsCountIsOutOfBound(errorHandler.toast, itemsCount, 'brand logo', 5, 10);
        }
    });
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .slice-reinsurance {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        margin: auto;
        @media (max-width: $mobile-breakpoint) {
            margin: 0 $margin-section-mobile 0 $margin-section-mobile;
        }

        &__logos {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            row-gap: var(--joy-core-spacing-5);
            column-gap: var(--joy-core-spacing-5);
            filter: grayscale(100%);
            margin-top: var(--joy-core-spacing-6);

            @include mq.screen_md(min) {
                row-gap: var(--joy-core-spacing-8);
                column-gap: var(--joy-core-spacing-12);
                margin-top: var(--joy-core-spacing-10);
            }

            img {
                max-width: 70px;
                width: auto;
                object-fit: contain;
                height: 24px;

                &.bigger {
                    height: 32px;
                }

                @include mq.screen_sm(min) {
                    max-width: 100px;
                    height: 40px;
                    &.bigger {
                        height: 56px;
                    }
                }
            }
        }
    }

    // @media (max-width: $mobile-breakpoint) {
    //     max-width: 330px;
    //     gap: 35px;
    //     .logo {
    //         img {
    //             max-width: 60px;
    //             max-height: 150px;
    //         }
    //     }
    // }
</style>
