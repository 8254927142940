// Code generated by Slice Machine. DO NOT EDIT.
// @ts-nocheck

import {defineSliceZoneComponents} from '@prismicio/vue';

import AddOn from './AddOn/index.vue';
import BenefitsSlice from './BenefitsSlice/index.vue';
import Charts from './Charts/index.vue';
import Columns from './Columns/index.vue';
import Comparison from './Comparison/index.vue';
import ComparisonSection from './ComparisonSection/index.vue';
import ConsultantProfiles from './ConsultantProfiles/index.vue';
import ContentSlice from './ContentSlice/index.vue';
import Criterias from './Criterias/index.vue';
import DemoPopup from './DemoPopup/index.vue';
import Features from './Features/index.vue';
import HeroSlice from './HeroSlice/index.vue';
import HeroStrategy from './HeroStrategy/index.vue';
import HeroWithShapes from './HeroWithShapes/index.vue';
import Highlight from './Highlight/index.vue';
import InvitationReferencing from './InvitationReferencing/index.vue';
import InvitationVerbatim from './InvitationVerbatim/index.vue';
import JobCategories from './JobCategories/index.vue';
import JobPageConfig from './JobPageConfig/index.vue';
import JobsFamilySlice from './JobsFamilySlice/index.vue';
import KeyFigures from './KeyFigures/index.vue';
import PersonaContentSlice from './PersonaContentSlice/index.vue';
import PictureGallery from './PictureGallery/index.vue';
import Plans from './Plans/index.vue';
import Pricing from './Pricing/index.vue';
import ProductContent from './ProductContent/index.vue';
import ProductDemo from './ProductDemo/index.vue';
import ProductFeaturedText from './ProductFeaturedText/index.vue';
import ProductHero from './ProductHero/index.vue';
import ProductVideo from './ProductVideo/index.vue';
import Profiles from './Profiles/index.vue';
import ProofPoints from './ProofPoints/index.vue';
import Quotation from './Quotation/index.vue';
import ReinsuranceSlice from './ReinsuranceSlice/index.vue';
import ResourcesBloc from './ResourcesBloc/index.vue';
import RichContent from './RichContent/index.vue';
import SearchSlice from './SearchSlice/index.vue';
import Skills from './Skills/index.vue';
import SpacerSlice from './SpacerSlice/index.vue';
import Stepper from './Stepper/index.vue';
import TagDescription from './TagDescription/index.vue';
import TestimonialsSlice from './TestimonialsSlice/index.vue';
import Trustpilot from './Trustpilot/index.vue';
import VideoSlice from './VideoSlice/index.vue';

export const components = defineSliceZoneComponents({
    add_on: AddOn,
    benefits_slice: BenefitsSlice,
    charts: Charts,
    columns: Columns,
    comparison: Comparison,
    comparison_section: ComparisonSection,
    consultant_profiles: ConsultantProfiles,
    content_slice: ContentSlice,
    criterias: Criterias,
    demo_popup: DemoPopup,
    features: Features,
    hero_slice: HeroSlice,
    hero_strategy: HeroStrategy,
    hero_with_shapes: HeroWithShapes,
    highlight: Highlight,
    invitation_referencing: InvitationReferencing,
    invitation_verbatim: InvitationVerbatim,
    job_categories: JobCategories,
    job_page_config: JobPageConfig,
    jobs_family_slice: JobsFamilySlice,
    key_figures: KeyFigures,
    persona_content_slice: PersonaContentSlice,
    picture_gallery: PictureGallery,
    plans: Plans,
    pricing: Pricing,
    product_content: ProductContent,
    product_demo: ProductDemo,
    product_featured_text: ProductFeaturedText,
    product_hero: ProductHero,
    product_video: ProductVideo,
    profiles: Profiles,
    proof_points: ProofPoints,
    quotation: Quotation,
    reinsurance_slice: ReinsuranceSlice,
    resources_bloc: ResourcesBloc,
    rich_content: RichContent,
    search_slice: SearchSlice,
    skills: Skills,
    spacer_slice: SpacerSlice,
    stepper: Stepper,
    tag_description: TagDescription,
    testimonials_slice: TestimonialsSlice,
    trustpilot: Trustpilot,
    video_slice: VideoSlice,
});
