import type {JobsFamilySliceSliceDefaultItem} from '@prismicio-types';
import useTypeGuards from '~/composables/useTypeGuards';

const {hasProperty} = useTypeGuards();

type Job = JobsFamilySliceSliceDefaultItem;
export type JobsByJobFamily = {
    unknownFamily: Job[];
    family1: Job[];
    family2: Job[];
    family3: Job[];
    family4: Job[];
    family5: Job[];
};

export function getJobsByJobFamily(jobs: Job[]): JobsByJobFamily {
    return jobs.reduce(
        (jobsByJobFamily, job) => {
            const jobFamily = job.jobFamily;
            if (
                jobFamily === null ||
                (jobFamily !== 'family1' && jobFamily !== 'family2' && jobFamily !== 'family3' && jobFamily !== 'family4' && jobFamily !== 'family5')
            ) {
                jobsByJobFamily.unknownFamily.push(job);
                return jobsByJobFamily;
            }
            jobsByJobFamily[jobFamily].push(job);

            return jobsByJobFamily;
        },
        {
            unknownFamily: <Job[]>[],
            family1: <Job[]>[],
            family2: <Job[]>[],
            family3: <Job[]>[],
            family4: <Job[]>[],
            family5: <Job[]>[],
        },
    );
}

export function isFamilyEmpty(familyLabel: string | null, jobs: Job[]) {
    return familyLabel !== null && jobs.length === 0;
}

export function getJobFamilyNamesNotEmpty(primary: unknown) {
    return ['jobFamily1', 'jobFamily2', 'jobFamily3', 'jobFamily4', 'jobFamily5'].reduce((acc, family) => {
        if (hasProperty(primary, family)) {
            const familyName = primary[family];
            if (typeof familyName === 'string') {
                acc.push({name: familyName});
            }
        }
        return acc;
    }, <{name: string}[]>[]);
}
