<template>
    <section v-if="!(breakpoints?.isMobile && slice.primary.hideInMobile)" ref="section" class="slice slice-content-section" aria-label="content">
        <div class="slice-content content-wrapper">
            <PrismicRichText
                v-if="slice.primary.title"
                :field="slice.primary.title"
                class="slice-title"
                :class="{'column-variation': slice.variation === 'column'}"
            />
            <div
                class="slice-body text-left content"
                :class="{
                    'display-media-on-the-left': slice.variation === 'displayImageOnTheLeft' || slice.variation === 'displayVideoOnTheLeft',
                    'column-variation': slice.variation === 'column',
                }"
            >
                <div
                    v-if="slice.variation !== 'column'"
                    :class="{
                        'content-description': true,
                        wider: slice.primary.mediaRatio,
                        center:
                            ((slice.variation === 'default' || slice.variation === 'displayImageOnTheLeft') &&
                                !hasObjectField(slice.primary.illustration)) ||
                            (isSliceColumn(slice) && !hasObjectField(slice.primary.video)),
                    }"
                >
                    <PrismicRichText v-if="slice.primary.contentTitle" :field="slice.primary.contentTitle" />
                    <PrismicRichText v-if="slice.primary.description" :field="slice.primary.description" />
                    <template v-if="isFilledKeyText(slice.primary.linkText) && isFilledKeyText(slice.primary.link)">
                        <NuxtLink v-if="slice.primary.mediaRatio" :to="slice.primary.link" class="link" data-source-component="content">
                            {{ slice.primary.linkText }}
                        </NuxtLink>
                        <VJoyButton v-else variant="primary" :href="slice.primary.link" data-source-component="content">
                            {{ slice.primary.linkText }}
                        </VJoyButton>
                    </template>
                </div>

                <PrismicEmbed
                    v-if="slice.variation === 'video' || slice.variation === 'displayVideoOnTheLeft'"
                    :class="{video: true, compressed: slice.primary.mediaRatio}"
                    :field="slice.primary.video"
                />
                <div v-else-if="slice.variation === 'default' || slice.variation === 'displayImageOnTheLeft'" class="image-wrapper">
                    <PrismicImage
                        :image="slice.primary.illustration"
                        :class="{image: true, compressed: slice.primary.mediaRatio}"
                        sizes="312px small:943px mobile:648px tablet:768px"
                    />
                </div>
                <div v-else-if="slice.variation === 'column'">
                    <PrismicRichText :field="slice.primary.description" class="description" />
                    <div class="columns">
                        <div v-for="(item, i) in slice.items" :key="`slice-item-${i}`" class="column">
                            <PrismicImage
                                :image="item.columnImage"
                                sizes="mobile:100px wide:140px"
                                :height="breakpoints?.isMobile ? '100px' : '140px'"
                                :width="breakpoints?.isMobile ? '100px' : '140px'"
                            />
                            <div class="column-content">
                                <PrismicRichText :field="item.columnTitle" />
                                <PrismicRichText :field="item.columnDescription" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {getSliceComponentProps, PrismicRichText, PrismicEmbed} from '@prismicio/vue';
    import {VJoyButton} from '@maltjoy/core-vue';
    import {inject, ref, onMounted} from 'vue';
    import type {SharedSlice} from '@prismicio/types';
    import PrismicImage from '~/components/PrismicImage.vue';
    import ServerSideNavigationPrismicLink from '~/components/ServerSideNavigationPrismicLink.vue';
    import {usePrismicTypeGuards, usePrismicFieldHelpers, useComponentTracking} from '#imports';
    import useErrorHelpers from '~/composables/useErrorHelpers';
    import {MEDIA_BREAKPOINTS} from '~/app/keys';
    import type {ContentSliceSlice, ContentSliceSliceDisplayVideoOnTheLeft, ContentSliceSliceVideo} from '@prismicio-types';
    // eslint-disable-next-line import/no-named-as-default

    const props = defineProps({
        ...getSliceComponentProps<ContentSliceSlice>(),
    });

    const {isFilledKeyText, isFilledWebLink} = usePrismicTypeGuards();
    const {throwIfPrimaryPropertiesAreInvalid, throwIfItemsCountIsOutOfBound, getPreviewErrorHandler} = useErrorHelpers();
    const {hasObjectField} = usePrismicFieldHelpers();

    const errorHandler = getPreviewErrorHandler();
    const breakpoints = inject(MEDIA_BREAKPOINTS);
    const section = ref(null);

    function isSliceColumn(
        slice: ContentSliceSlice,
    ): slice is SharedSlice<'content_slice', ContentSliceSliceDisplayVideoOnTheLeft | ContentSliceSliceVideo> {
        return slice.variation === 'video' || slice.variation === 'displayVideoOnTheLeft';
    }

    function validate() {
        if (!errorHandler.enabled) {
            return;
        }

        const variationName = props.slice.variation;
        if (variationName === 'video' || variationName === 'displayVideoOnTheLeft') {
            throwIfPrimaryPropertiesAreInvalid(
                errorHandler.toast,
                props.slice.primary,
                [
                    {name: 'video', type: 'video'},
                    {name: 'contentTitle', type: 'field'},
                ],
                'video content',
            );
        } else if (variationName === 'column') {
            throwIfPrimaryPropertiesAreInvalid(errorHandler.toast, props.slice.primary, [{name: 'title', type: 'field'}], 'column content');
            throwIfItemsCountIsOutOfBound(errorHandler.toast, props.slice.items.length, 'columns', 2, 4);
        } else {
            throwIfPrimaryPropertiesAreInvalid(
                errorHandler.toast,
                props.slice.primary,
                [
                    {name: 'illustration', type: 'image'},
                    {name: 'description', type: 'field'},
                    {name: 'contentTitle', type: 'field'},
                ],
                'image content',
            );
        }
    }

    onMounted(() => {
        validate();
    });

    useComponentTracking(section);
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .slice-content-section {
        .slice-title {
            margin-bottom: 48px;
            @include mq.screen_sm(min) {
                margin-bottom: 90px;
            }
        }

        .image-wrapper {
            width: 60%;
            height: auto;
            display: flex;
            align-items: center;
            position: relative;
            z-index: 0;
            .image {
                border-radius: var(--joy-core-radius-4);
                width: 100%;
                flex: 1;
                height: auto;
                z-index: 1;
            }
            @media (max-width: $mobile-breakpoint) {
                width: 100%;
            }
            &.compressed {
                width: 50%;
                @media (max-width: $mobile-breakpoint) {
                    width: 100%;
                }
            }
        }

        .video {
            //fix aspect ratio 16:9 because auto does not work properly on iframe
            width: 60%;
            padding-top: 33.75%;
            position: relative;
            iframe {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                bottom: 0;
                border-radius: var(--joy-core-radius-4);
            }
            @media (max-width: $mobile-breakpoint) {
                width: 100%;
                padding-top: 56.25%;
            }
            &.compressed {
                width: 50%;
                padding-top: 40%;
                @media (max-width: $mobile-breakpoint) {
                    width: 100%;
                    padding-top: 80%;
                }
            }
        }

        .content {
            width: 100%;
            @include flex-row-align-center;
            justify-content: space-between;
            gap: var(--joy-core-spacing-9);

            @media (max-width: $mobile-breakpoint) {
                flex-direction: column-reverse;
                gap: 24px;
            }

            &.display-media-on-the-left {
                flex-direction: row-reverse;
                @media (max-width: $mobile-breakpoint) {
                    flex-direction: column-reverse;
                }
            }
        }

        .content-description {
            width: 40%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 32px;
            @media (max-width: $mobile-breakpoint) {
                width: 100%;
                gap: 24px;
            }
            &.wider {
                width: 50%;
                @media (max-width: $mobile-breakpoint) {
                    width: 100%;
                }
            }
            &.center {
                width: 100%;
                text-align: center;
                @include flex-column-center;
                @include content-width;
            }
            h3 {
                font-size: var(--joy-font-size-secondary-400);
                font-family: $title-font-family;
                font-weight: var(--joy-font-weight-normal);
                color: var(--joy-color-neutral-60);
                @media (max-width: $mobile-breakpoint) {
                    font-size: var(--joy-font-size-primary-800);
                }
            }

            p,
            ul,
            ol {
                @include description-base;
                margin-bottom: var(--joy-core-spacing-4);
                @media (max-width: $mobile-breakpoint) {
                    margin-bottom: var(--joy-core-spacing-3);
                    font-size: var(--joy-font-size-primary-400);
                }
            }
            .joy-button {
                width: 100%;
                @include mq.screen_sm(min) {
                    width: auto;
                }
            }
            .link {
                @include link;
            }
        }

        .column-variation {
            gap: 0;
            @include flex-column-justify-center;
            justify-content: center;

            h2 {
                margin-bottom: 24px;
            }
            .description {
                text-align: center;
                font-size: var(--joy-font-size-primary-600);
                margin-bottom: var(--joy-core-spacing-12);
            }

            .columns {
                @include flex-row-justify-center;
                align-items: flex-start;
                gap: 65px;
                @media (max-width: $mobile-breakpoint) {
                    @include flex-column-align-center;
                }
                img {
                    margin-bottom: var(--joy-core-spacing-9);
                    @media (max-width: $mobile-breakpoint) {
                        margin-bottom: 24px;
                    }
                }

                .column {
                    flex: 1 1 0;
                    @include flex-column-center;

                    .column-content {
                        @include flex-column-align-center;
                        font-family: $font-family;
                        font-weight: $font-weight-regular;
                        font-size: var(--joy-font-size-primary-400);
                        line-height: var(--joy-line-height-large);
                        color: var(--joy-color-neutral-50);
                        text-align: center;

                        h3,
                        h4 {
                            font-family: $title-font-family;
                            font-weight: var(--joy-font-weight-normal);
                            font-size: var(--joy-font-size-secondary-400);
                            margin-bottom: 12px;
                            color: var(--joy-color-neutral-60);
                            @media (max-width: $mobile-breakpoint) {
                                font-size: var(--joy-font-size-primary-800);
                                line-height: var(--joy-line-height-small);
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
