<template>
    <section v-if="!(breakpoints?.isMobile && slice.primary.hideInMobile)" ref="section" class="ressources-bloc-section" aria-label="resources">
        <PrismicRichText :field="slice.primary.title" class="title" />
        <!-- repeating v-if is a fix to a rendering issue with the swiper component. -->
        <template v-if="breakpoints?.isMobile">
            <NuxtErrorBoundary>
                <Swiper
                    v-if="breakpoints.isMobile"
                    :preload-images="false"
                    :slides-per-view="1"
                    :loop="slice.items.length > 1"
                    :space-between="20"
                    :module="modules"
                >
                    <SwiperSlide v-for="(item, i) in slice.items" :key="`slice-item-${i}`">
                        <div class="card">
                            <div class="top">
                                <PrismicImage :image="item.thumbnail" class="thumbnail" sizes="small:309px mobile:874 wide:294" />
                                <div v-if="slice.variation === 'default'" class="tags">
                                    {{ getItemTags(item) }}
                                </div>

                                <PrismicRichText :field="item.title" class="cart-title" />
                                <PrismicRichText :field="item.subtitle" class="cart-subtitle" />
                            </div>
                            <ServerSideNavigationPrismicLink
                                v-if="isFilledKeyText(item.loggedInLinkText) && isFilledKeyText(item.loggedInLink) && userData && userData.isLoggedIn"
                                :field="item.loggedInLink"
                                class="link-button"
                                data-source-component="resources-bloc"
                            >
                                {{ item.loggedInLinkText }}
                            </ServerSideNavigationPrismicLink>
                            <ServerSideNavigationPrismicLink
                                v-else-if="isFilledKeyText(item.linkText) && isFilledKeyText(item.link)"
                                :field="item.link"
                                class="link-button"
                                data-source-component="resources-bloc"
                            >
                                {{ item.linkText }}
                            </ServerSideNavigationPrismicLink>
                            <div v-if="isDefaultItem(item)" class="info">
                                <span class="date">
                                    {{
                                        item.date &&
                                        new Date(item.date).toLocaleDateString(undefined, {
                                            day: 'numeric',
                                            month: 'short',
                                            year: 'numeric',
                                        })
                                    }}
                                </span>
                                <span>{{ item.duration }}</span>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SliderControls
                        :prev-arrow-label="slice.primary.prevArrowLabel ?? 'prev'"
                        :next-arrow-label="slice.primary.nextArrowLabel ?? 'next'"
                    />
                </Swiper>
            </NuxtErrorBoundary>
        </template>
        <div
            v-else
            :class="{
                cards: true,
                'two-columns': slice.items.length === 2,
            }"
        >
            <div v-for="(item, i) in slice.items" :key="`slice-item-${i}`" class="card">
                <div class="top">
                    <PrismicImage
                        :image="item.thumbnail"
                        class="thumbnail"
                        :sizes="slice.items.length > 2 ? 'small:309px mobile:874 wide:294' : 'small:309px mobile:874 wide:492'"
                    />
                    <div v-if="isDefaultItem(item)" class="tags">
                        {{ item.tags }}
                    </div>

                    <PrismicRichText :field="item.title" class="cart-title" />
                    <PrismicRichText :field="item.subtitle" class="cart-subtitle" />
                </div>
                <ServerSideNavigationPrismicLink
                    v-if="isFilledKeyText(item.loggedInLinkText) && isFilledKeyText(item.loggedInLink) && userData && userData.isLoggedIn"
                    :field="item.loggedInLink"
                    class="link-button"
                    data-source-component="resources-bloc"
                >
                    {{ item.loggedInLinkText }}
                </ServerSideNavigationPrismicLink>
                <ServerSideNavigationPrismicLink
                    v-else-if="isFilledKeyText(item.linkText) && isFilledKeyText(item.link)"
                    :field="item.link"
                    class="link-button"
                    data-source-component="resources-bloc"
                >
                    {{ item.linkText }}
                </ServerSideNavigationPrismicLink>
                <div v-if="isDefaultItem(item)" class="info">
                    <span class="date">
                        {{
                            item.date &&
                            new Date(item.date).toLocaleDateString(undefined, {
                                day: 'numeric',
                                month: 'short',
                                year: 'numeric',
                            })
                        }}
                    </span>
                    <span>{{ item.duration }}</span>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {getSliceComponentProps, PrismicRichText} from '@prismicio/vue';
    import {Swiper, SwiperSlide} from 'swiper/vue';
    // @ts-ignore
    import {Lazy, A11y} from 'swiper';
    import {inject, onMounted, computed, ref} from 'vue';
    import ServerSideNavigationPrismicLink from '~/components/ServerSideNavigationPrismicLink.vue';
    import type {ResourcesBlocSlice, ResourcesBlocSliceDefaultItem, ResourcesBlocSliceStandardItem, Simplify} from '@prismicio-types';
    import 'swiper/css';
    import {MEDIA_BREAKPOINTS} from '~/app/keys';
    import PrismicImage from '~/components/PrismicImage.vue';
    import useErrorHelpers from '~/composables/useErrorHelpers';
    import {usePrismicTypeGuards, useComponentTracking} from '#imports';
    import {useUserState} from '~/composables/useUserState';
    import SliderControls from '~/components/SliderControls.vue';

    const {user} = useUserState();
    const userData = computed(() => user?.value?.data);
    const breakpoints = inject(MEDIA_BREAKPOINTS);
    const props = defineProps(getSliceComponentProps<ResourcesBlocSlice>());
    const {isFilledKeyText} = usePrismicTypeGuards();
    const modules = [Lazy, A11y];
    const section = ref(null);

    function getItemTags(item: Simplify<ResourcesBlocSliceDefaultItem> | Simplify<ResourcesBlocSliceStandardItem>) {
        return (item as Simplify<ResourcesBlocSliceDefaultItem>).tags;
    }
    function isDefaultItem(item: any): item is Simplify<ResourcesBlocSliceDefaultItem> {
        return item.date || item.duration || item.tags;
    }
    onMounted(() => {
        const {throwIfItemsCountIsOutOfBound, throwIfMissingItemsProperties, getPreviewErrorHandler} = useErrorHelpers();
        const errorHandler = getPreviewErrorHandler();

        if (errorHandler.enabled) {
            const itemsCount = props.slice.items.length;
            throwIfItemsCountIsOutOfBound(errorHandler.toast, itemsCount, 'cards', 2, 9);
            throwIfMissingItemsProperties(
                errorHandler.toast,
                props.slice.items,
                [
                    {name: 'thumbnail', type: 'image'},
                    {name: 'link', type: 'rawText'},
                    {name: 'linkText', type: 'rawText'},
                    {name: 'title', type: 'field'},
                    {name: 'subtitle', type: 'field'},
                ],
                'cards',
            );
            if (props.slice.variation === 'default') {
                throwIfMissingItemsProperties(
                    errorHandler.toast,
                    props.slice.items,
                    [
                        {name: 'tags', type: 'rawText'},
                        {name: 'date', type: 'date'},
                        {name: 'duration', type: 'rawText'},
                    ],
                    'cards',
                );
            }
        }
    });

    useComponentTracking(section);
</script>

<style lang="scss">
    .ressources-bloc-section {
        background-color: $color-white;
        color: var(--joy-color-neutral-60);
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: $font-family;
        @include content-width;
        max-width: 100vw;
        .title h2 {
            @include secondary-title;
            margin-bottom: 60px;
            position: relative;
            text-align: center;
            em {
                font-style: normal;
                background-color: var(--joy-color-quaternary-50);
                border-radius: 50px;
                padding: 0px 10px;
                margin: 0 -10px;
            }
            @media (max-width: $mobile-breakpoint) {
                margin-bottom: 22px;
            }
        }

        .cards {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: var(--joy-core-spacing-9);
            row-gap: var(--joy-core-spacing-9);
            max-width: $container-max-width;

            &.two-columns {
                grid-template-columns: 1fr 1fr;
                .thumbnail {
                    width: 536px;
                    height: auto;
                    max-width: 100%;
                }
            }
        }
        .swiper {
            width: 100%;
            .swiper-slide {
                width: 100%;
                box-sizing: border-box;
                padding: 20px;
            }
        }

        .card {
            @include card;
            box-sizing: border-box;
            width: 100%;
            padding: 32px;
            @include flex-column-start;
            text-align: left;
            justify-items: space-between;
            @media (max-width: $mobile-breakpoint) {
                width: unset;
            }
            .top {
                flex-grow: 1;
                width: 100%;
            }

            .thumbnail {
                width: 100%;
                height: auto;
                margin-bottom: 32px;
                border-radius: var(--joy-core-radius-4);
                aspect-ratio: 16 / 9;
                object-fit: cover;
            }

            .tags {
                color: var(--joy-color-secondary-30);
                text-transform: uppercase;
                font-size: var(--joy-font-size-primary-200);
                font-weight: var(--joy-font-weight-bold);
                margin-bottom: 16px;
                @media (max-width: $mobile-breakpoint) {
                    margin-bottom: var(--joy-core-spacing-3);
                }
            }

            .cart-title {
                margin-bottom: 16px;
                h3 {
                    @include tertiary-title;
                    font-size: var(--joy-font-size-primary-600);
                    font-weight: var(--joy-font-weight-bold);
                }
                @media (max-width: $mobile-breakpoint) {
                    margin-bottom: var(--joy-core-spacing-3);
                }
            }

            .cart-subtitle {
                margin-bottom: 24px;
                line-height: $line-height-lg;
                @media (max-width: $mobile-breakpoint) {
                    margin-bottom: 32px;
                }
            }

            .link-button {
                @include link;
            }

            .info {
                color: var(--joy-color-neutral-50);
                font-size: var(--joy-font-size-primary-300);
                margin-top: 32px;

                .date {
                    padding-right: var(--joy-core-spacing-3);
                    margin-right: var(--joy-core-spacing-3);
                    border-right: 1px solid var(--joy-color-neutral-30);
                }
            }
        }
    }
</style>
