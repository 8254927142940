<template>
    <section
        v-if="!(breakpoints?.isMobile && slice.primary.hideInMobile)"
        class="freelance-search-slice"
        :aria-label="slice.primary.accessibilityLabel || 'Freelance Search'"
    >
        <PrismicRichText :field="slice.primary.title" class="title" />
        <div class="search" :class="{'search--v2': cmsSearchPanelV2}">
            <SearchPanel
                v-if="cmsSearchPanelV2"
                :search-tab="slice.primary.searchLinkText"
                :search-field-category="slice.primary.categoryLabel"
                :search-field-location="slice.primary.locationLabel"
                :search-submit="slice.primary.searchLinkText"
                :search-submit-link="slice.primary.searchLink"
                :ai-search-tab="slice.primary.aiSearchLinkText"
                :ai-search-tab-link="slice.primary.aiSearchLink"
                :project-tab="slice.primary.submitProjectLinkText"
                :project-tab-link="slice.primary.submitProjectLink"
                :reassurance-items="slice.items"
                brief-origin="home_unlogged"
            />
            <FreelanceSearch
                v-else
                :category-label="slice.primary.categoryLabel"
                :location-label="slice.primary.locationLabel"
                :search-link-text="slice.primary.searchLinkText"
                :search-link="slice.primary.searchLink"
                :submit-project-link-text="slice.primary.submitProjectLinkText"
                :submit-project-link="slice.primary.submitProjectLink"
                :ai-search-link-text="slice.primary.aiSearchLinkText"
                :ai-search-link="slice.primary.aiSearchLink"
                :reassurance-items="slice.items"
                :between-ctas-text="slice.primary.betweenCTAsText"
                :missing-category-error-text="slice.primary.missingCategoryErrorText"
                :trustpilot-data-locale="slice.primary.trustpilotDataLocale"
                :trustpilot-data-template-id="slice.primary.trustpilotDataTemplateId"
                :trustpilot-data-businessunit-id="slice.primary.trustpilotDataBusinessunitId"
                :trustpilot-link="slice.primary.trustpilotLink"
                :hide-trustpilot="slice.primary.hideTrustpilot"
                :remote-in-country-label="slice.primary.remoteInCountryLabel"
                heap-prefix="search-slice"
                brief-origin="cms_search_slice"
            />
        </div>
    </section>
</template>

<script setup lang="ts">
    import {getSliceComponentProps, PrismicRichText} from '@prismicio/vue';
    import {onMounted, inject} from 'vue';
    import FreelanceSearch from '~/components/FreelanceSearch.vue';
    import SearchPanel from '@search/components/SearchPanel.vue';
    import {useFeatureFlagsStore} from '~/stores/feature-flags.store';
    import type {SearchSliceSlice} from '@prismicio-types';
    import {MEDIA_BREAKPOINTS} from '~/app/keys';
    import useErrorHelpers from '~/composables/useErrorHelpers';

    const {features} = useFeatureFlagsStore();
    const cmsSearchPanelV2 = features['cms-search-panel-v2'];

    const breakpoints = inject(MEDIA_BREAKPOINTS);

    const props = defineProps(getSliceComponentProps<SearchSliceSlice>());

    onMounted(() => {
        const {getPreviewErrorHandler, throwIfPrimaryPropertiesAreInvalid} = useErrorHelpers();
        const errorHandler = getPreviewErrorHandler();
        if (errorHandler.enabled) {
            throwIfPrimaryPropertiesAreInvalid(
                errorHandler.toast,
                props.slice.primary,
                [
                    {name: 'title', type: 'field'},
                    {name: 'accessibilityLabel', type: 'rawText'},
                ],
                'search',
            );
        }
    });
</script>

<style lang="scss">
    .freelance-search-slice {
        margin: 0 $margin-section-normal 0 $margin-section-normal;
        @media (max-width: $mobile-breakpoint) {
            margin: 0 $margin-section-mobile 0 $margin-section-mobile;
        }
        @include flex-column-align-center;

        .title h2 {
            @include secondary-title;
            text-align: center;
        }

        .search {
            margin: 48px 70px 0 70px;
            @media (max-width: $mobile-breakpoint) {
                margin: 48px 0 0 0;
                width: 100%;
            }
            &--v2 {
                width: 100%;
                max-width: 1280px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
</style>
