import {useLogger, usePreviewHelper, usePrismicTypeGuards} from '#imports';

const {hasProperty, isArray, isFilled} = usePrismicTypeGuards();
const {isPreview} = usePreviewHelper();

const defaultToaster = {
    toast(message: string) {
        const logger = useLogger();
        logger.warn(`[Quality check]: ${message}`);
    },
};

function throwIfItemsCountIsOutOfBound(
    errorHandler: (message: string) => void,
    itemCount: number,
    itemName: string,
    minimum: number,
    maximum: number,
) {
    if (itemCount < minimum || itemCount > maximum) {
        errorHandler(getItemsCountErrorMessage(itemName, minimum, maximum, itemCount));
    }
}

function getItemsCountErrorMessage(itemName: string, minimum: number, maximum: number, itemCount: number) {
    return `The number of ${itemName} should be between ${minimum} and ${maximum}, it is currently ${itemCount}`;
}
export type Property = {
    name: string;
    type: 'image' | 'field' | 'rawText' | 'link' | 'video' | 'date';
};

function throwIfPrimaryPropertiesAreInvalid(
    errorHandler: (message: string) => void,
    primaryFields: Record<string, unknown>,
    mandatoryProperties: Property[],
    componentName: string,
) {
    mandatoryProperties.forEach((property) => {
        const value = primaryFields[property.name];

        const isPrismicImageValid = property.type === 'image' && value !== undefined && JSON.stringify(value) !== '{}';

        const isPrismicVideoValid = property.type === 'video' && value !== undefined && JSON.stringify(value) !== '{}';

        const isRawTextValid = property.type === 'rawText' && isFilled(value);

        if (isPrismicImageValid) {
            return;
        }

        if (isPrismicVideoValid) {
            return;
        }

        if (isRawTextValid) {
            return;
        }

        // @debt: should handle internal link too
        if (property.type === 'link' && hasProperty(value, 'url')) {
            return;
        }

        if (isArray(value) && value?.length !== 0) {
            return;
        }
        errorHandler(`The ${componentName} slice should have: ${property.name}`);
    });
}

function getPreviewErrorHandler(toaster?: {toast: (s: string) => void}) {
    const enabled = isPreview();
    return {
        enabled,
        toast(message: string) {
            enabled && (toaster ?? defaultToaster).toast(message);
        },
    };
}

function throwIfMissingItemsProperties(
    errorHandler: (message: string) => void,
    items: Record<string, unknown>[],
    mandatoryProperties: Property[],
    componentName: string,
) {
    const definedProperties = items.reduce((itemsCount, item) => itemsCount + definedPropertiesCount(mandatoryProperties, item), 0);
    const totalExpectedDefinedProperties = items.length * mandatoryProperties.length;

    if (definedProperties !== totalExpectedDefinedProperties) {
        errorHandler(`Each ${componentName} slice should have: ${mandatoryProperties.map((prop) => prop.name).join(', ')}.`);
    }
}

function definedPropertiesCount(mandatoryProperties: Property[], item: any) {
    return mandatoryProperties.reduce((definedPropertiesInItem, property) => {
        const missingProperty = item[property.name] === undefined;
        const value = item[property.name];

        if (property.type === 'image' || property.type === 'video' || property.type === 'link') {
            if (missingProperty || JSON.stringify(value) === '{}') {
                return definedPropertiesInItem;
            }
            return definedPropertiesInItem + 1;
        }

        if (property.type === 'rawText' || property.type === 'date') {
            if (value === null || missingProperty) {
                return definedPropertiesInItem;
            }
            return definedPropertiesInItem + 1;
        }

        if (missingProperty || value.length === 0 || value[0].text === '') {
            return definedPropertiesInItem;
        }

        return definedPropertiesInItem + 1;
    }, 0);
}

export default function useErrorHelpers() {
    return {
        throwIfPrimaryPropertiesAreInvalid,
        throwIfItemsCountIsOutOfBound,
        getPreviewErrorHandler,
        throwIfMissingItemsProperties,
    };
}
