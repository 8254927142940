import type {UserInfo} from '~/types/custom';

const isUserInfo = (userInfo: UserInfo | undefined | null): userInfo is UserInfo => {
    return !!userInfo;
};

const isCompany = (userInfo?: UserInfo | null) => {
    // if we do not have identity, we will consider that the user is a freelance
    return userInfo?.identities?.find((identity) => identity?.selected)?.identityType === 'CLIENT';
};

export const useUserInfoHelpers = () => {
    return {
        isUserInfo,
        isCompany,
    };
};
