<template>
    <section class="slice slice-product-featured-text">
        <div class="slice-content">
            <AnimTextWave ref="text1Ref" class="slice-product-featured-text__line1" :text="slice.primary.text + ''" />
            <AnimTextWave ref="text2Ref" class="slice-product-featured-text__line2" :text="slice.primary.text2 + ''" />

            <div ref="ctaRef" class="slice-product-featured-text__cta">
                <VJoyButton v-if="slice.primary.ctaLink" :href="slice.primary.ctaLink" variant="main-ai" class="ai-button-sparkle">
                    {{ slice.primary.ctaLabel }}
                    <MaltImg src="/cms-front/ai-sparkle.svg?vh=284103" alt="AI Search" width="18" />
                </VJoyButton>
                <VJoyButton v-if="slice.primary.cta2Link" :href="slice.primary.cta2Link" variant="main-ai">
                    {{ slice.primary.cta2Label }}
                </VJoyButton>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {onMounted, ref} from 'vue';
    import {getSliceComponentProps} from '@prismicio/vue';
    import type {ProductFeaturedTextSlice} from '@prismicio-types';
    import AnimTextWave from '~/components/anim/TextWave.vue';
    import {useGsap} from '@/composables/useGsap';
    import {VJoyButton} from '@maltjoy/core-vue';
    import {MaltImg} from '#components';

    const {ScrollTrigger} = useGsap();
    const text1Ref = ref();
    const text2Ref = ref();
    const ctaRef = ref<HTMLElement>();

    defineProps(getSliceComponentProps<ProductFeaturedTextSlice>(['slice', 'index', 'slices', 'context']));

    onMounted(() => {
        ScrollTrigger.create({
            trigger: text1Ref.value.$el,
            start: 'top bottom',
            onEnter: () => setTimeout(text1Ref.value.animate, 300),
            onLeaveBack: text1Ref.value.reset,
        });
        ScrollTrigger.create({
            trigger: text2Ref.value.$el,
            start: 'top bottom',
            onEnter: () => setTimeout(text2Ref.value.animate, 500),
            onLeaveBack: text2Ref.value.reset,
        });
        ScrollTrigger.create({
            trigger: ctaRef.value,
            start: 'top bottom',
            onEnter: () => ctaRef.value?.classList.add('animate'),
            onLeaveBack: () => ctaRef.value?.classList.remove('animate'),
        });
    });
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    $ref-width: 1759;

    $text-font-size: calc((110 / $ref-width) * 100vw);

    .slice-product-featured-text {
        overflow: hidden;

        &__line1,
        &__line2 {
            font-family: var(--joy-font-family-title);
            font-size: clamp(36px, $text-font-size, 120px);
            color: var(--joy-color-text-title);
        }

        &__line1 {
            transform: translateX(-5%);
        }

        &__line2 {
            transform: translateX(10%);
        }

        &__cta {
            overflow: hidden;
            margin-top: var(--joy-core-spacing-8);
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            column-gap: var(--joy-core-spacing-8);
            row-gap: var(--joy-core-spacing-6);

            @include mq.screen_lg(min) {
                margin-top: var(--joy-core-spacing-12);
                .joy-button {
                    flex: none;
                }
            }

            &:not(.animate) {
                .joy-button {
                    transform: translate3d(0, 100%, 0);
                    transition: none !important;
                }
            }

            &.animate {
                .joy-button {
                    transform: translate3d(0, 0, 0);
                    transition: transform var(--ai-transition-duration) var(--ai-transition-timing-function) 0.2s;
                    &:nth-child(2) {
                        transition-delay: 0.4s;
                    }
                }
            }
        }

        .ai-button-sparkle {
            background: none;
            background-color: transparent;
            color: var(--joy-color-ai-primary-30);
            border: 2px solid var(--joy-color-ai-primary-30);
            transition: background-color var(--joy-transition-duration) var(--joy-transition-timing-function);

            @include mq.screen_md(min) {
                width: auto;
            }
            &:hover {
                background: none;
                background-color: var(--joy-color-ai-primary-10);
                border: 2px solid var(--joy-color-ai-primary-30);
                color: var(--joy-color-ai-primary-30);
            }
            span {
                height: 16px;
                padding-right: 16px;
            }
            img {
                position: absolute;
                margin-left: 8px;
            }
        }
    }
</style>
