<template>
    <div
        :class="{
            'slice hero-with-shapes': true,
            'shapes-on-the-right': backgroundShapes === 'shapesOnTheRight',
            'shapes-on-the-top-left': backgroundShapes === 'shapesOnTheTopLeft',
            'shapes-on-the-bottom-left': backgroundShapes === 'shapesOnTheBottomLeft',
            'shapes-super-malter': backgroundShapes === 'superMalter',
        }"
    >
        <div v-if="backgroundShapes === 'superMalter'" class="super-malter-picto" />
        <div class="content">
            <PrismicRichText :field="title" class="title" />
            <VJoyButton v-if="isFilledKeyText(ctaOptionalLinkText) && isFilledKeyText(ctaOptionalLink)" :href="ctaOptionalLink" variant="white">
                {{ ctaOptionalLinkText }}
            </VJoyButton>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {PrismicRichText} from '@prismicio/vue';
    import {VJoyButton} from '@maltjoy/core-vue';
    import {onMounted} from 'vue';
    import type {RTNode} from '@prismicio/types';
    import {usePrismicTypeGuards} from '#imports';
    import useErrorHelpers from '~/composables/useErrorHelpers';

    interface Props {
        title: [] | [RTNode, ...RTNode[]];
        ctaOptionalLinkText?: string | null;
        ctaOptionalLink?: string | null;
        backgroundShapes: string | null;
    }

    const props = withDefaults(defineProps<Props>(), {
        ctaOptionalLinkText: null,
        ctaOptionalLink: '',
    });

    const {isFilledKeyText} = usePrismicTypeGuards();
    onMounted(() => {
        const {getPreviewErrorHandler, throwIfPrimaryPropertiesAreInvalid} = useErrorHelpers();
        const errorHandler = getPreviewErrorHandler();
        if (errorHandler.enabled) {
            throwIfPrimaryPropertiesAreInvalid(
                errorHandler.toast,
                props,
                [
                    {name: 'title', type: 'field'},
                    {name: 'backgroundShapes', type: 'rawText'},
                ],
                'hero search with shapes',
            );
        }
    });
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .hero-with-shapes {
        background-color: var(--joy-color-secondary-50);
        background-image: url('~/assets/svg/hero-shapes-on-the-right.svg');
        background-repeat: no-repeat;
        background-position: center bottom -1px;
        background-size: 101vw auto;
        display: flex;
        flex-direction: column;
        @media (max-width: $mobile-breakpoint) {
            background-image: url('~/assets/svg/hero-shapes-on-the-right-mobile.svg');
        }

        &.shapes-on-the-right {
            min-height: 350px;
            @include mq.screen_lg(min) {
                min-height: 500px;
            }
        }
        &.shapes-on-the-top-left {
            background-image: url('~/assets/svg/hero-shapes-on-the-top-left.svg');
            background-color: white;
            background-size: 101vw 130%;
            @media (max-width: $mobile-breakpoint) {
                background-size: 101vw 100%;
                background-image: url('~/assets/svg/hero-shapes-on-the-top-left-mobile.svg');
            }
        }
        &.shapes-on-the-bottom-left {
            background-image: url('~/assets/svg/hero-shapes-on-the-bottom-left.svg');
            @media (max-width: $mobile-breakpoint) {
                background-image: url('~/assets/svg/hero-shapes-on-the-bottom-left-mobile.svg');
            }
        }
        &.shapes-super-malter {
            background-color: var(--joy-color-primary-50);
            background-image: url('~/assets/svg/hero-shapes-super-malter-mobile.svg');
            @include mq.screen_md(min) {
                background-image: url('~/assets/svg/hero-shapes-super-malter.svg');
            }
            .title {
                max-width: 100%;
                @include mq.screen_sm(min) {
                    max-width: 75%;
                }
                @include mq.screen_md(min) {
                    max-width: 50%;
                }
                em {
                    position: relative;
                    font-style: normal;
                    &:before {
                        content: '';
                        background-color: var(--joy-color-brand-secondary-30);
                        position: absolute;
                        top: 50%;
                        left: 0;
                        width: 100%;
                        height: 8px;
                        margin-top: -2px;
                        transform: rotate(-5deg);
                    }
                }
            }
        }

        .joy-button {
            margin-top: var(--joy-core-spacing-8);
            width: 100%;
            @include mq.screen_sm(min) {
                margin-top: var(--joy-core-spacing-10);
                width: auto;
            }
        }

        .title {
            max-width: 50%;
            @media (max-width: $mobile-breakpoint) {
                max-width: 75%;
            }
            h1 {
                @include primary-title;
                margin-top: 120px;
                text-align: center;
                color: white;
                font-size: 60px;

                @media (max-width: $mobile-breakpoint) {
                    margin-top: 30px;
                }
            }
        }

        .content {
            margin-bottom: 138px;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media (max-width: $mobile-breakpoint) {
                margin: 0 $margin-section-mobile 160px $margin-section-mobile;
            }
        }
    }

    .super-malter-picto {
        display: none;

        @include mq.screen_md(min) {
            position: absolute;
            width: 169px;
            height: 348px;
            top: 50%;
            margin-top: -179px;
            background-repeat: no-repeat;
            display: block;
            background-image: url(~/assets/svg/super-malter-picto.svg);
        }
    }
</style>
