<template>
    <div ref="el" class="card-hand" :class="{'card-hand--open': isIntersecting}">
        <div class="card-hand-inner" :class="{'ms-theme': msTheme}">
            <div>
                <slot />
            </div>
            <svg width="0" height="0">
                <defs>
                    <clipPath id="card-hand-svg">
                        <path :d="svgPath" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {useIntersectionObserver} from '#malt/nuxt-utils-module';
    import {ref} from 'vue';

    defineProps<{msTheme?: boolean}>();

    const el = ref(null);
    const {isIntersecting} = useIntersectionObserver(el);
    const svgPath =
        'M109.425 273.739C204.181 273.739 218.4 202.333 218.4 146.68V127.619C218.383 71.9663 204.164 0.55957 109.425 0.55957C14.6685 0.55957 0.449972 71.9663 0.449972 127.619L0.449951 146.68C0.449951 202.333 14.6685 273.739 109.425 273.739Z';
</script>

<style lang="scss">
    $card-transition: all 1.5s ease;
    $card-width: 219px;
    $card-height: 274px;
    // $card-width: 233.5px;
    // $card-height: 279.5px;

    .card-hand-inner {
        position: relative;
        width: $card-width;
        height: $card-height;

        transition: $card-transition;
        transform: translate3d(0, 0, 0);

        > div,
        &:before,
        &:after {
            clip-path: url(#card-hand-svg);
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            transition: $card-transition;
            transform-origin: center;
            transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0);
            will-change: transform;
        }
        img {
            backface-visibility: hidden;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
        &:after {
            background-color: var(--joy-color-primary-50);
        }
        &:before {
            background-color: var(--joy-color-primary-30);
        }
        &.ms-theme {
            &:after {
                background-color: var(--joy-color-secondary-30);
            }
            &:before {
                background-color: var(--joy-color-tertiary-70);
            }
        }
    }
    .card-hand {
        display: inline-block;
        padding: 0 36px;
    }
    .card-hand--open {
        .card-hand-inner {
            transform: translate3d(37px, 0, 0);
            &:after {
                transform: translate3d(-31px, -1px, 0) rotate3d(0, 0, 1, -13.5deg);
            }
            &:before {
                transform: translate3d(-57px, 0, 0) rotate3d(0, 0, 1, -30deg);
            }
        }
    }
</style>
