<template>
    <section ref="sectionRef" class="slice slice-product-video">
        <div class="slice-content">
            <div ref="wrapperRef" class="slice-product-video__wrapper slide-in">
                <div ref="innerRef">
                    <MktVideoPlayer ref="videoPlayer" :src="videoUrl" />
                    <div v-if="!videoUnveiled" ref="coverRef" class="slice-product-video__cover" @click="startVideo()">
                        <PrismicImage v-if="slice.primary.coverImage" class="slice-product-video__cover__image" :field="slice.primary.coverImage" />
                        <div class="slice-product-video__cover__overlay" />
                        <div class="relative">
                            <svg class="slice-product-video__cover__icon" width="175" height="175" viewBox="0 0 175 175" fill="none">
                                <circle cx="87.3568" cy="87.0785" r="81.3402" stroke="white" stroke-width="11.4766" />
                                <path d="M127.285 86.9762L66.8278 121.881L66.8278 52.0712L127.285 86.9762Z" fill="white" />
                            </svg>
                            <PrismicRichText :field="slice.primary.coverText" class="slice-product-video__cover__title" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {onMounted, ref} from 'vue';
    import {useGsap} from '@/composables/useGsap';
    import {MktVideoPlayer} from '#components';
    import {getSliceComponentProps, PrismicRichText, PrismicImage} from '@prismicio/vue';
    import type {ProductVideoSlice} from '@prismicio-types';

    const props = defineProps(getSliceComponentProps<ProductVideoSlice>(['slice', 'index', 'slices', 'context']));

    const videoUrl = ref(props.slice.primary.videoUrl.html?.match(/src="([^"]*)"/)?.[1] as string);
    videoUrl.value += '&rel=0';
    const videoUnveiled = ref(false);

    const sectionRef = ref<HTMLElement>();
    const wrapperRef = ref<HTMLElement>();
    const innerRef = ref<HTMLElement>();
    const coverRef = ref<HTMLElement>();

    const videoPlayer = ref();

    const {gsap, ScrollTrigger} = useGsap();

    function open() {
        if (coverRef.value) {
            coverRef.value.style.opacity = '0';
            coverRef.value.addEventListener('transitionend', () => {
                videoUnveiled.value = true;
            });
        }
    }

    function startVideo() {
        open();
        play();
    }

    function show() {
        wrapperRef.value?.classList.add('animate');
    }

    function hide() {
        wrapperRef.value?.classList.remove('animate');
        pause();
    }

    function play() {
        videoPlayer.value?.play();
    }

    function pause() {
        videoPlayer.value?.pause();
    }

    onMounted(() => {
        if (!innerRef.value) return;

        ScrollTrigger.create({
            trigger: wrapperRef.value,
            start: 'top bottom',
            end: 'bottom top',
            scrub: true,
            onEnter: show,
            onLeave: pause,
            onLeaveBack: hide,
        });
    });
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .slice-product-video {
        &__wrapper {
            position: relative;

            .animate & > * {
                transition-duration: 2s;
            }
        }
        &__cover {
            transition: opacity var(--joy-transition-duration-long) var(--joy-transition-timing-function);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex !important;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 20px;
            overflow: hidden;

            &__image,
            &__overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            &__image {
                object-fit: cover;
            }

            &__overlay {
                opacity: 0.6;
                background: var(--joy-color-ai-gradient-30);
            }

            &__icon {
                width: 48px;
                height: auto;
                margin-bottom: var(--joy-core-spacing-2);

                @include mq.screen_xs(min) {
                    width: 75px;
                }
                @include mq.screen_sm(min) {
                    width: 128px;
                    margin-bottom: var(--joy-core-spacing-4);
                }
                @include mq.screen_md(min) {
                    width: 175px;
                    margin-bottom: var(--joy-core-spacing-6);
                }
            }

            &__title {
                font-family: var(--joy-font-family-title);
                font-size: var(--joy-font-size-primary-600);
                line-height: 1;

                p {
                    color: #fff;
                }

                @include mq.screen_xs(min) {
                    font-size: var(--joy-font-size-secondary-200);
                }
                @include mq.screen_sm(min) {
                    font-size: var(--joy-font-size-secondary-400);
                }
                @include mq.screen_md(min) {
                    font-size: var(--joy-font-size-secondary-600);
                }
            }
        }
    }
</style>
