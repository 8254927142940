<template>
    <section
        :id="slice.primary.anchorName || undefined"
        class="slice slice-keyfigures"
        :class="{'slice-keyfigures--dark': slice.primary.tealBackground}"
        aria-label="key-figures"
    >
        <div class="slice-content">
            <div class="slice-keyfigures__frame">
                <div v-if="slice.primary.title" class="slice-keyfigures__header">
                    <PrismicRichText :field="slice.primary.title" class="slice-title" />
                </div>
                <div class="slice-keyfigures__items">
                    <FigureItem
                        v-for="(item, i) in slice.items"
                        :key="i"
                        :item="item"
                        :thousands-separator="thousandsSeparator"
                        :decimal-separator="decimalSeparator"
                        class="slice-keyfigures__item"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {getSliceComponentProps, PrismicRichText} from '@prismicio/vue';
    import type {KeyFiguresSlice} from '@prismicio-types';
    import FigureItem from './FigureItem.vue';

    const props = defineProps(getSliceComponentProps<KeyFiguresSlice>(['slice', 'index', 'slices', 'context']));
    const separators = {
        none: '',
        space: ' ',
        comma: ',',
        point: '.',
        quote: "'",
    };
    const thousandsSeparator = separators[props.slice.primary.thousandsSeparator || 'none'];
    const decimalSeparator = separators[props.slice.primary.decimalSeparator || 'point'];
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .slice-keyfigures {
        &__frame {
            @include mq.screen_sm(min) {
                display: flex;
                justify-content: center;
                gap: var(--joy-core-spacing-6);
            }
        }
        &__header {
            text-align: center;
            flex: none;
            margin-bottom: var(--joy-core-spacing-6);
            @include mq.screen_sm(min) {
                text-align: left;
                width: 334px;
                margin-bottom: 0;
            }
        }
        &__items {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: var(--joy-core-spacing-2);
            column-gap: var(--joy-core-spacing-6);
            row-gap: var(--joy-core-spacing-6);

            @include mq.screen_lg(min) {
                grid-template-columns: repeat(3, minmax(0, 1fr));
                column-gap: var(--joy-core-spacing-6);
                row-gap: var(--joy-core-spacing-9);
            }
        }
        .slice-title {
            @include mq.screen_lg(min) {
                text-align: left;
            }
        }
        // with background
        &--dark {
            .slice-keyfigures__frame {
                position: relative;
                padding: var(--joy-core-spacing-6) var(--joy-core-spacing-4);
                @include mq.screen_md(min) {
                    padding: var(--joy-core-spacing-12);
                }
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                    border-radius: var(--joy-core-radius-4);
                    border-radius: 18px;
                    background-color: var(--joy-color-secondary-30);
                }
                .slice-title h2,
                .figure-item,
                .figure-item__figure {
                    color: #fff;
                }
            }
        }
    }
</style>
