<template>
    <div class="figure-item">
        <div ref="counterElement" class="figure-item__figure" :class="{'anim__fade-up': isVisible}">
            <span>{{ item.prefix }}</span>
            <div>{{ counter }}</div>
            <span>{{ item.suffix }}</span>
        </div>
        <div v-if="item.caption" class="figure-item__caption" :class="{'anim__fade-up': isVisible}">{{ item.caption }}</div>
    </div>
</template>

<script setup lang="ts">
    import {ref} from 'vue';
    import {useIntersectionObserver} from '#malt/nuxt-utils-module';
    import {useCounterAnimation} from '~/composables/useCounterAnimation';
    import type {KeyFiguresSliceDefaultItem} from '@prismicio-types';

    const isVisible = ref(false);
    const props = defineProps<{
        item: KeyFiguresSliceDefaultItem;
        thousandsSeparator?: string | undefined;
        decimalSeparator?: string | undefined;
    }>();
    // counter animation
    const counterElement = ref(null);
    const targetValue = props.item.figure as number;
    const {counter, startAnimation} = useCounterAnimation(0, targetValue, {
        thousandsSeparator: props.thousandsSeparator,
        decimalSeparator: props.decimalSeparator,
    });

    // interection observer
    function onIntersection([{isIntersecting}]: IntersectionObserverEntry[]) {
        if (isIntersecting) {
            isVisible.value = true;
            startAnimation();
            stopObserving();
        }
    }
    const {stopObserving} = useIntersectionObserver(counterElement, onIntersection, {options: {threshold: 1}});
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .figure-item {
        text-align: center;
        &__figure {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: $title-font-family;
            letter-spacing: -0.2px;
            color: var(--joy-color-secondary-30);
            white-space: nowrap;
            line-height: 1;
            opacity: 0;
            font-size: var(--joy-font-size-secondary-200);

            @include mq.screen_md(min) {
                font-size: 40px; // joy compliancy
            }

            span {
                flex: none;
                width: var(--joy-core-spacing-6);
                position: relative;
            }
            &:first-child {
                text-align: right;
            }
        }
        &__caption {
            margin-top: var(--joy-core-spacing-1);
            font-family: $title-font-family;
            font-size: var(--joy-font-size-primary-400);
            line-height: 120%; // joy compliancy
            opacity: 0;
            animation-delay: 0.25s;
        }
    }

    @keyframes fade-up {
        0% {
            opacity: 0;
            transform: translate3d(0, 0.5rem, 0);
        }
        100% {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    .anim__fade-up {
        animation-duration: 0.3s;
        animation-timing-function: ease-out;
        animation-name: fade-up;
        animation-fill-mode: forwards;
    }
</style>
