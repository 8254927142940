<template>
    <div v-if="breakpoints?.isMobile" :style="{height: `${slice.primary.mobileSpacerHeight}px`}"></div>
    <div v-else :style="{height: `${slice.primary.spacerHeight}px`}"></div>
</template>

<script setup lang="ts">
    import {inject} from 'vue';
    import {getSliceComponentProps} from '@prismicio/vue';
    import {MEDIA_BREAKPOINTS} from '~/app/keys';
    import type {SpacerSliceSlice} from '@prismicio-types';

    const breakpoints = inject(MEDIA_BREAKPOINTS);

    defineProps(getSliceComponentProps<SpacerSliceSlice>());
</script>
