<template>
    <section v-if="slice.primary" :id="slice.primary.anchorName || undefined" ref="section" class="slice" aria-label="consultant-profiles">
        <div class="slice-content">
            <PrismicRichText v-if="slice.primary.title" :field="slice.primary.title" class="slice-title" />
            <PrismicRichText v-if="slice.primary.subtitle" :field="slice.primary.subtitle" class="slice-subtitle" />
            <PrismicRichText v-if="slice.primary.description" :field="slice.primary.description" class="slice-description" />
            <div class="slice-body">
                <NuxtErrorBoundary v-if="slice.variation === 'default'">
                    <Swiper class="swiper-grid" :breakpoints="swiperBreakpoints">
                        <SwiperSlide v-for="(consultant, i) in slice.items" :key="i">
                            <ConsultantProfileCard :consultant="consultant" />
                        </SwiperSlide>
                    </Swiper>
                </NuxtErrorBoundary>
                <div v-else-if="slice.variation === 'thumbnails'" class="thumbnail-grid">
                    <ConsultantProfileThumbnail v-for="(consultant, i) in slice.items" :key="i" :consultant="consultant" />
                </div>
            </div>
            <div v-if="slice.primary.ctaUrl" class="slice-footer">
                <VJoyButton :href="slice.primary.ctaUrl" data-source-component="consultant-profiles">{{ slice.primary.ctaLabel }}</VJoyButton>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {ref} from 'vue';
    import {VJoyButton} from '@maltjoy/core-vue';
    import {Swiper, SwiperSlide} from 'swiper/vue';
    import ConsultantProfileCard from './ConsultantProfileCard.vue';
    import ConsultantProfileThumbnail from './ConsultantProfileThumbnail.vue';
    import {getSliceComponentProps, PrismicRichText} from '@prismicio/vue';
    import type {ConsultantProfilesSlice} from '@prismicio-types';
    import {useComponentTracking} from '#imports';

    defineProps(getSliceComponentProps<ConsultantProfilesSlice>(['slice', 'index', 'slices', 'context']));

    const section = ref(null);

    const swiperBreakpoints = {
        0: {slidesPerView: 1},
        768: {slidesPerView: 2},
        1024: {slidesPerView: 3},
    };

    useComponentTracking(section);
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .swiper-grid {
        .swiper-slide {
            width: auto;
        }
        @include mq.screen_lg(min) {
            pointer-events: none;
            .swiper-wrapper {
                flex-wrap: wrap;
                justify-content: center;
            }
        }
    }

    .thumbnail-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto;

        @include mq.screen_md(min) {
            padding: 0 var(--joy-core-spacing-8);
        }

        > div {
            width: 100%;
            @include mq.screen_xs(min) {
                width: 50%;
            }
            @include mq.screen_sm(min) {
                width: 33.33%;
            }
            @include mq.screen_md(min) {
                width: 25%;
            }
        }
    }
</style>
