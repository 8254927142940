
/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * Content management API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {FetchOptions} from 'ofetch';
import type {
  FreelancerProfile,
} from '../models';
import {
    FreelancerProfileFromJSON,
    FreelancerProfileToJSON,
} from '../models';

export interface SearchProfilesRequest {
    profileIdOrSeoUrl: Array<string>;
    hiddenProfiles?: boolean;
}

/**
 * 
 */
export class ProfileSearchApi extends runtime.BaseAPI {

    /**
     * Search profiles by ids or seoUrl for Prismic slices in Landing Pages
     */
    async searchProfilesRaw(requestParameters: SearchProfilesRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<Array<FreelancerProfile>>> {
        if (requestParameters['profileIdOrSeoUrl'] == null) {
            throw new runtime.RequiredError(
                'profileIdOrSeoUrl',
                'Required parameter "profileIdOrSeoUrl" was null or undefined when calling searchProfiles().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['profileIdOrSeoUrl'] != null) {
            queryParameters['profileIdOrSeoUrl'] = requestParameters['profileIdOrSeoUrl'];
        }

        if (requestParameters['hiddenProfiles'] != null) {
            queryParameters['hiddenProfiles'] = requestParameters['hiddenProfiles'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request<Array<FreelancerProfile>>({
            path: `/cms/api/search/profiles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        response._data=response._data?.map(FreelancerProfileFromJSON);
        return response;
    }

    /**
     * Search profiles by ids or seoUrl for Prismic slices in Landing Pages
     */
    async searchProfiles(requestParameters: SearchProfilesRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<Array<FreelancerProfile>> {
        const response = await this.searchProfilesRaw(requestParameters, initOverrides);
        return response._data;
    }

}
